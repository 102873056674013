import { filterOptions, sortOptions } from '@/utils/tableHelper/options';
import { DateFilter, NumberFilter, StringFilter } from '@/utils/tableHelper/filter';

export const tableColumnDate = (item, filter, open, setOpen) => {
  return {
    ...tableColumnNone(item),
    ...sortOptions,
    ...filterOptions(setOpen, open, filter),
    filterDropdown: DateFilter,
  };
};
export const tableColumnNumber = (item, filter, open, setOpen, entity) => {
  return {
    ...tableColumnNone(item),
    ...sortOptions,
    ...filterOptions(setOpen, open, filter),
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, confirm }) => {
      return NumberFilter({
        setSelectedKeys,
        selectedKeys,
        clearFilters,
        confirm,
        entity,
        open,
        item,
      });
    },
  };
};
export const tableColumnString = (
  item,
  filter,
  open,
  setOpen,
  entity,
  otherModelName,
  isEnum = false,
  fixedOptions = [],
) => {
  return {
    ...tableColumnNone(item),
    ...sortOptions,
    ...filterOptions(setOpen, open, filter),
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, confirm }) => {
      return StringFilter({
        setSelectedKeys,
        selectedKeys,
        clearFilters,
        confirm,
        entity,
        item,
        open,
        setOpen,
        otherModelName,
        isEnum,
        fixedOptions,
      });
    },
  };
};
export const tableColumnNone = (item) => {
  return {
    title: item.title,
    dataIndex: item.dataIndex,
    render: item.render,
  };
};
