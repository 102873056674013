import { useState, useEffect } from 'react';
import enTranslations from '../../dictionaries/en.json';
import frTranslations from '../../dictionaries/fr.json';
import { useSelector } from 'react-redux';
import { selectUser } from '@/redux/auth/selectors';
import { isEmpty } from 'lodash';

type LanguageOptions = { value: string; label: string; flag: string };
export enum LanguageEnum {
  FR = 'fr',
  EN = 'en',
}
export const DEFAULT_LANGUAGE = LanguageEnum.FR;
export const languageOptions: LanguageOptions[] = [
  { value: LanguageEnum.FR, label: 'Français (Fr)', flag: 'FR' },
  { value: LanguageEnum.EN, label: 'English (En)', flag: 'GB' },
];

const useTranslations = (basePath = '') => {
  const [translations, setTranslations] = useState({});
  const profile = useSelector(selectUser);
  const currentLanguage = profile?.language ?? localStorage.getItem('Language') ?? DEFAULT_LANGUAGE;

  const loadTranslations = () => {
    if (currentLanguage === LanguageEnum.EN) {
      setTranslations(enTranslations);
    } else if (currentLanguage === LanguageEnum.FR) {
      setTranslations(frTranslations);
    } else {
      // eslint-disable-next-line no-console
      console.error(`Unsupported language: ${currentLanguage}`);
    }
  };

  useEffect(() => {
    loadTranslations();
  }, [currentLanguage]);

  const setLanguage = (newLocaleLanguage) => {
    localStorage.setItem('Language', newLocaleLanguage);
    window.location.reload();
  };

  const t = (key, overrideBasePath = false, params = {}) => {
    let base = '';
    if (!overrideBasePath) {
      base = basePath ? `${basePath}.` : '';
    }
    const keys = `${base}${key}`.split('.');
    let result = translations;

    for (const k of keys) {
      if (result && result[k] !== undefined) {
        result = result[k];
      } else {
        return key;
      }
    }

    if (!isEmpty(params)) {
      for (const param in params) {
        //@ts-ignore
        result = result.replace(`{${param}}`, params[param]);
      }
    }

    return result;
  };

  return { t, setLanguage, currentLanguage };
};

export const translate = (key, params = {}) => {
  const storedLanguage = localStorage.getItem('Language') ?? DEFAULT_LANGUAGE;
  let result;

  if (storedLanguage === LanguageEnum.EN) {
    result = enTranslations;
  } else if (storedLanguage === LanguageEnum.FR) {
    result = frTranslations;
  } else {
    // eslint-disable-next-line no-console
    console.error(`Unsupported language: ${storedLanguage}`);
  }

  const keys = key.split('.');

  for (const k of keys) {
    if (result && result[k] !== undefined) {
      result = result[k];
    } else {
      return key;
    }
  }

  if (!isEmpty(params)) {
    for (const param in params) {
      result = result.replace(`{${param}}`, params[param]);
    }
  }

  return result;
};

export default useTranslations;
