import { Select } from 'antd';
import ReactCountryFlag from 'react-country-flag';
import useTranslations, { languageOptions } from '@/hooks/useTranslation';
import { useEffect } from 'react';

const { Option } = Select;
type LanguageSelectorProps = {
  className: string;
  defaultBehaviour: boolean;
  handleSelectFromOutisde?: (value: string) => void;
};

const LanguageSelector = ({
  className,
  defaultBehaviour = true,
  handleSelectFromOutisde,
}: LanguageSelectorProps) => {
  const { setLanguage, currentLanguage } = useTranslations();
  const params = new URLSearchParams(window.location.search);
  const lang = params.get('lang');

  const handleSelect = (value: string) => {
    if (!defaultBehaviour) {
      handleSelectFromOutisde(value);
      return;
    }
    setLanguage(value);
  };

  useEffect(() => {
    if (lang && lang !== currentLanguage) {
      setLanguage(lang);
    }
  }, [lang]);
  return (
    <div className={`language-selector ${className}`}>
      <Select
        defaultValue={currentLanguage}
        onSelect={handleSelect}
        dropdownClassName="language-dropdown"
      >
        {languageOptions.map((lang) => (
          <Option value={lang.value} key={lang.value}>
            <ReactCountryFlag countryCode={lang.flag} svg />
            <span style={{ color: 'black', marginLeft: '4px' }}>{lang.label}</span>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default LanguageSelector;
