import { Button, Form, Image, Upload } from 'antd';
import { useState } from 'react';
import useTranslations from '@/hooks/useTranslation';

export const UpdateLogo = ({
  image,
  defaultImage,
  setImage,
  form,
  title,
  description,
  name,
  profile = false,
}) => {
  const [fileList, setFileList] = useState([image]);
  const { t } = useTranslations();

  const handleImageChange = (info) => {
    setImage(info.file.originFileObj);
    form.setFieldValue(name, info.file.originFileObj);
  };

  const handleRemoveImage = () => {
    setImage(defaultImage);
    form.setFieldValue(name, defaultImage);
    setFileList([]);
  };

  return (
    <div className="max-form-width update">
      <div className="flex-center">
        <Form.Item name={name}>
          <Image
            preview={false}
            height={80}
            key={image?.name}
            className={`avatar-border ${profile ? 'profile-img' : 'company-img'}`}
            src={typeof image === 'object' ? URL.createObjectURL(image) : image}
          />
        </Form.Item>
        <div className={'mrg-l-32 max-w-210'}>
          <div className="text">{title}</div>
          <div className="text fnt-wgt-400">{description}</div>
        </div>
      </div>
      <div className="flex btns-padding">
        <Upload accept=".jpg,.jpeg,.png" onChange={handleImageChange}>
          <Button type="primary">{t('uploadLogo.upload_button')}</Button>
        </Upload>
        {typeof image === 'object' && fileList.length !== 0 ? (
          <Button danger onClick={handleRemoveImage}>
            {t('uploadLogo.remove_button')}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
