import React, { lazy, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import PageLoader from '@/components/PageLoader';
import {
  inventoryRoutesConfig,
  purchaseRoutesConfig,
  routesConfig,
  salesRoutesConfig,
  settingsRoutesConfig,
} from './RoutesConfig';
import { useDispatch, useSelector } from 'react-redux';
import { current } from '@/redux/auth/action/auth';
import { selectCompany, selectUser } from '@/redux/auth/selectors';
import Settings from '@pages/Settings';

const Logout = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ '@/pages/Logout'));
const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/NotFound'));

export default function AppRouter() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(selectUser);
  const company = useSelector(selectCompany);

  useEffect(() => {
    if (!profile || !profile.isGoogleAuth) {
      dispatch(current());
    }
  }, [dispatch, history]);

  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          {routesConfig.map((routeItem) => {
            return (
              <PrivateRoute
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact || true}
                component={lazy(
                  () =>
                    import(/* webpackChunkName: "[request]" */ `@/pages/${routeItem.component}`),
                )}
              />
            );
          })}
          {inventoryRoutesConfig.map((routeItem) => {
            return (
              <PrivateRoute
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact || true}
                component={lazy(
                  () =>
                    import(
                      /* webpackChunkName: "[request]" */ `@/pages/inventory/${routeItem.component}`
                    ),
                )}
              />
            );
          })}
          {salesRoutesConfig.map((routeItem) => {
            return (
              <PrivateRoute
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact || true}
                component={lazy(
                  () =>
                    import(
                      /* webpackChunkName: "[request]" */ `@/pages/sales/${routeItem.component}`
                    ),
                )}
              />
            );
          })}
          {purchaseRoutesConfig.map((routeItem) => {
            return (
              <PrivateRoute
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact || true}
                component={lazy(
                  () =>
                    import(
                      /* webpackChunkName: "[request]" */ `@/pages/purchase/${routeItem.component}`
                    ),
                )}
              />
            );
          })}
          <Route path="/settings">
            <Settings>
              <Switch>
                {settingsRoutesConfig.map((route) => (
                  <Route
                    key={route.path}
                    path={`/settings/${route.path}`}
                    exact
                    render={() => <PrivateRoute>{route.element}</PrivateRoute>}
                  />
                ))}
              </Switch>
            </Settings>
          </Route>
          <PublicRoute path="/login" render={() => <Redirect to="/" />} exact />
          <Route component={Logout} path="/logout" exact />
          <Route path="*" component={NotFound} render={() => <Redirect to="/notfound" />} />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
