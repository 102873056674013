export const endpoints = {
  // inventory
  item: {
    service: 'inventory',
    entity: 'item',
  },
  category: {
    service: 'inventory',
    entity: 'category',
  },
  adjustment: {
    service: 'inventory',
    entity: 'adjustment',
  },
  setCategory: {
    service: 'inventory',
    entity: 'category',
    subEntity: 'setCategory',
  },
  item_group: {
    service: 'inventory',
    entity: 'category',
  },
  transfer_order: {
    service: 'inventory',
    entity: 'transferOrder',
  },
  warehouse: {
    service: 'inventory',
    entity: 'store',
  },
  defaultWarehouse: {
    service: 'inventory',
    entity: 'store/default/read',
  },
  'inventory Adjustment Reason': {
    service: 'user_management_service',
    entity: 'inventoryAdjustmentReason',
  },
  supplier: {
    service: 'inventory',
    entity: 'supplier',
  },
  purchase: {
    service: 'inventory',
    entity: 'purchase',
  },
  purchasePayment: {
    service: 'inventory',
    entity: 'purchase/payment',
  },
  tax: {
    service: 'inventory',
    entity: 'tax',
  },
  admin: {
    service: 'user_management_service',
    entity: 'admin',
  },
  company: {
    service: 'user_management_service',
    entity: 'company',
  },

  //orders_service
  parent: {
    service: 'orders_service',
    entity: 'parent',
  },
  document: {
    service: 'orders_service',
    entity: 'document',
  },
  quote: {
    service: 'orders_service',
    entity: 'quote',
  },
  order: {
    service: 'orders_service',
    entity: 'order',
  },
  delivery_note: {
    service: 'orders_service',
    entity: 'deliveryNote',
  },
  exit_voucher: {
    service: 'orders_service',
    entity: 'exitVoucher',
  },
  invoice: {
    service: 'orders_service',
    entity: 'invoice',
  },
  'Sales Returns': {
    service: 'orders_service',
    entity: 'saleReturn',
  },
  client: {
    service: 'orders_service',
    entity: 'client',
  },
  notPaidInvoices: {
    service: 'orders_service',
    entity: 'client/unpaid/invoices',
  },
  notifyClient: {
    service: 'orders_service',
    entity: 'notify-client',
  },
  payment: {
    service: 'orders_service',
    entity: 'invoice/payment',
  },
  'Sales Return Reason': {
    service: 'user_management_service',
    entity: 'saleReturnReason',
  },
  // user management
  todo: {
    service: 'user_management_service',
    entity: 'todo',
  },
  manufacturer: {
    service: 'user_management_service',
    entity: 'manufacturer',
  },
  brand: {
    service: 'user_management_service',
    entity: 'brand',
  },
  unit: {
    service: 'user_management_service',
    entity: 'unit',
  },
  color: {
    service: 'user_management_service',
    entity: 'color',
  },
  login: {
    service: 'user_management_service',
    entity: 'login?timestamp=',
  },
  current: {
    service: 'user_management_service',
    entity: 'current',
  },
  logout: {
    service: 'user_management_service',
    entity: 'logout',
  },
  signup: {
    service: 'user_management_service',
    entity: 'admin/create',
  },
  checkEmail: {
    service: 'user_management_service',
    entity: 'admin/check-email',
  },
  resendActivation: {
    service: 'user_management_service',
    entity: 'admin/account/resend-activation',
  },
  forgotPassword: {
    service: 'user_management_service',
    entity: 'admin/account/forgot-password',
  },
  verifyResetPasswordKey: {
    service: 'user_management_service',
    entity: 'admin/account/verify-password-key',
  },
  resetPassword: {
    service: 'user_management_service',
    entity: 'admin/account/reset-password',
  },
  activateAccount: {
    service: 'user_management_service',
    entity: 'admin/account/activation',
  },
  adminPosition: {
    service: 'user_management_service',
    entity: 'admin/static/position',
  },
  getCompanyIndustry: {
    service: 'user_management_service',
    entity: 'admin/static/industry',
  },
  getStockType: {
    service: 'user_management_service',
    entity: 'admin/static/stock',
  },
  role: {
    service: 'user_management_service',
    entity: 'role',
  },
  employee: {
    service: 'user_management_service',
    entity: 'employee',
  },
  companySettings: {
    service: 'user_management_service',
    entity: 'company/settings',
  },
  bugReport: {
    service: 'user_management_service',
    entity: 'bugReport',
  },
};
