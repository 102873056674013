import { Layout, PageHeader } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSiderContext } from '@/context/rightSider';
import { useSelector } from 'react-redux';
import { selectSaleLoading } from '@/redux/sales/selectors';
import Loading from '../Loading';

const { Sider } = Layout;

export default function SideForm() {
  const isLoading = useSelector(selectSaleLoading);
  const { state, siderContextAction } = useSiderContext();

  const { title, open, Avatar, Body, status } = state;

  return (
    <Sider
      collapsible
      hidden={!open}
      width={'min(1000px,65%)'}
      collapsedWidth={'max(30%,300px)'}
      className="sidePanel"
      style={{ right: open ? 0 : '-min(800px, 65%)' }}
      reverseArrow={true}
    >
      <PageHeader
        className={'header pad20-20'}
        title={title}
        onBack={siderContextAction.sider.hide}
        extra={status}
        tags={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              minWidth: '200%',
              justifyContent: 'space-between',
            }}
          >
            <Avatar />
          </div>
        }
        backIcon={<CloseOutlined />}
        ghost={true}
      />
      <Loading isLoading={isLoading}>
        <Body />
      </Loading>
    </Sider>
  );
}
