import React, { useState } from 'react';
import { Select } from 'antd';
import CurrencyFlag from 'react-currency-flags';
import { DEFAULT_CURRENCY } from '@/components/PriceDisplay';
import { useSelector } from 'react-redux';
import { selectCompany } from '@/redux/auth/selectors';
import { getAllCurrencies, getCountriesUsingTheCurrency } from '@/utils/currencies';
const { Option } = Select;

const CurrencySelect = ({ updateCurrency }: { updateCurrency: (currency: string) => void }) => {
  const { currency } = useSelector(selectCompany);
  const [selectedCurrency, setSelectedCurrency] = useState(currency || DEFAULT_CURRENCY);
  const currencies = getAllCurrencies();

  const handleSelect = (currency: string) => {
    updateCurrency(currency);
    setSelectedCurrency(currency);
  };

  return (
    <Select
      value={selectedCurrency}
      onChange={handleSelect}
      showSearch
      style={{ maxWidth: 120 }}
      filterOption={(input, option) => {
        const currencyCode = option.value;
        const countries = getCountriesUsingTheCurrency(currencyCode as string);
        return (
          currencyCode.toString().toLowerCase().includes(input.toString().toLowerCase()) ||
          countries.some((country: string) => country.toLowerCase().includes(input.toLowerCase()))
        );
      }}
    >
      {currencies?.map((currencyCode: string) => {
        return (
          <Option value={currencyCode} key={currencyCode}>
            <CurrencyFlag currency={currencyCode} size="sm" />
            {` ${currencyCode}`}
          </Option>
        );
      })}
    </Select>
  );
};

export default CurrencySelect;
