import { request } from '@/request';
import React from 'react';
import { useMutation } from 'react-query';

type NotifyClientData = {
  entity: string;
  id: string;
  from: string;
  to: string;
  subject: string;
  html: string;
  cc?: string[];
};

const useNotifyClient = () => {
  const notifyClient = (data: NotifyClientData) => {
    const { entity, id, ...payload } = data;
    return request.notifyClient({
      id,
      entity,
      payload,
    });
  };

  return useMutation({
    mutationKey: [],
    mutationFn: notifyClient,
  });
};

export default useNotifyClient;
