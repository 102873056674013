import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const usePathname = (): string => {
  const location = useLocation();
  const [result, setResult] = useState('');
  const pathname = location.pathname;

  useEffect(() => {
    setResult(pathname.split('/')[2] === 'bug' ? 'bug' : pathname.split('/')[1]);
  }, [pathname]);

  return result;
};

export default usePathname;
