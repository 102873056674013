import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqueId from '../../utils/uinqueId';
import { erp } from '../../redux/erp/actions';
import { Button, Layout, PageHeader } from 'antd';
import { useErpContext } from '../../context/erp';
import { selectConfirmItem, selectReadItem } from '@/redux/erp/selectors';
import { editRole } from '@/utils/role';
import { selectRole } from '@/redux/auth/selectors';
import { ArrowLeftOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { getNestedProperty } from '@/utils/helpers';
import { capitalize } from 'lodash';
import useTranslations from '@/hooks/useTranslation';
import { SALES_MODULES_WITH_DOWNLOADABLE_PDF } from '@/types/constants';
import { generatePDF } from '@/redux/sales/actions';
import Loading from '../Loading';

export default function Read<DataStructure>({ config, ReadItem, markAsPaid }) {
  const { t } = useTranslations('common');
  const dispatch = useDispatch();
  const { erpContextAction } = useErpContext();
  const { readPanel, updatePanel } = erpContextAction;
  const { isSuccess } = useSelector(selectConfirmItem);
  const { result, isLoading } = useSelector(selectReadItem);
  const [currentResult, setCurrentResult] = useState(result);
  const { entity } = config;
  const isSalesEntity = SALES_MODULES_WITH_DOWNLOADABLE_PDF.includes(entity);
  const { Content } = Layout;
  const role = useSelector(selectRole);
  function Confirm() {
    const id = currentResult._id;
    dispatch(erp.confirm({ entity, id: id }));
    updatePanel.close();
  }

  const resetStateAndClose = () => {
    dispatch(erp.resetCurrentPayload());
    updatePanel.close();
  };

  useEffect(() => {
    setCurrentResult(result);
  }, [result, isSuccess]);

  return currentResult ? (
    <>
      <PageHeader
        title={
          capitalize(config.entity) +
          ' : ' +
          (currentResult?.name ??
            (currentResult?.parent?.counter ? currentResult?.parent?.counter : ''))
        }
        backIcon={<ArrowLeftOutlined />}
        onBack={resetStateAndClose}
        ghost={false}
        style={{
          boxShadow: '0 0px 4px rgba(0, 0, 0, 0.1)',
        }}
        extra={[
          <Button
            style={{ borderRadius: ' 5px', fontSize: '14px' }}
            key={`${uniqueId()}`}
            onClick={() =>
              isSalesEntity
                ? dispatch(generatePDF(currentResult._id, entity))
                : resetStateAndClose()
            }
          >
            {isSalesEntity ? (
              <>
                <DownloadOutlined />
                <span className="ml-2">{t('generate_pdf')}</span>
              </>
            ) : (
              t('back')
            )}
          </Button>,
          editRole(role, config.entity) &&
          (!config?.isDeleted || !getNestedProperty(currentResult, config?.isDeleted)) ? (
            <Button
              key={`${uniqueId()}`}
              onClick={updatePanel.open}
              style={{
                backgroundColor: ' #326CFE',
                borderRadius: ' 5px',
                color: 'white',
                fontSize: '14px',
              }}
            >
              {t('edit')} {config.ENTITY_NAME.toLowerCase()}
            </Button>
          ) : null,
          config.markAsPaid && (
            <Button
              key={`${uniqueId()}`}
              onClick={markAsPaid}
              style={{
                borderRadius: ' 5px',
                fontSize: '14px',
              }}
            >
              {t('markAsPaid')}
            </Button>
          ),
          config?.draft &&
          currentResult.isDraft &&
          editRole(role, config.entity) &&
          (!config?.isDeleted || !getNestedProperty(currentResult, config?.isDeleted)) ? (
            <Button icon={<CheckCircleOutlined />} onClick={Confirm}>
              {t('confirm')}
            </Button>
          ) : null,
        ]}
      />
      <Loading isLoading={isLoading}>
        <Content
          className={'inside-content'}
          style={config.small ? { height: 'calc(100vh - 360px)' } : {}}
        >
          {ReadItem && <ReadItem content={currentResult} />}
        </Content>
      </Loading>
    </>
  ) : null;
}
