import { Image } from 'antd';
import supplyzLoader from '../../style/images/supplyzLoader.gif';

const PageLoader = () => {
  return (
    <div className="centerAbsolute">
      {/* <Spin indicator={Img} /> */}
      <Image src={supplyzLoader} alt="supplyzpro" />
    </div>
  );
};
export default PageLoader;
