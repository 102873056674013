import { Button, Dropdown, Menu } from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { erp } from '@/redux/erp/actions';
import { selectConfirmItem, selectItemById } from '@/redux/erp/selectors';
import { useErpContext } from '@/context/erp';
import uniqueId from '@/utils/uinqueId';
import { createRole, deleteRole, editRole } from '@/utils/role';
import { selectRole } from '@/redux/auth/selectors';
import { useCallback, useEffect, useState } from 'react';
import { getNestedProperty } from '@/utils/helpers';
import { SALES_MODULES_WITH_DOWNLOADABLE_PDF } from '@/types/constants';
import useTranslations from '@/hooks/useTranslation';
import { generatePDF } from '@/redux/sales/actions';
import NotifyClient from '../NotifyClient/NotifyClient';

export default function DataTableDropMenu({ row, entity, isDisabled, config }) {
  const { t } = useTranslations('common');
  const role = useSelector(selectRole);
  const dispatch = useDispatch();
  const { showDropdown = true } = config;
  const { erpContextAction } = useErpContext();
  const item = useSelector(selectItemById(row._id));
  const { isLoading, isSuccess } = useSelector(selectConfirmItem);
  const { updatePanel, modal } = erpContextAction;
  const noEdit = config.noEdit ?? false;
  const { isSuccess: confirmSuccess } = useSelector(selectConfirmItem);
  const isSalesEntity = SALES_MODULES_WITH_DOWNLOADABLE_PDF.includes(entity);
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const isInExcludedCategory = ['transfer_order', 'adjustment'].includes(entity);

  const onIsNotifyModalOpenChange = useCallback(
    (state) => {
      setIsNotifyModalOpen(state);
    },
    [setIsNotifyModalOpen],
  );

  function Edit() {
    dispatch(erp.read({ entity, id: item._id }));
    updatePanel.open();
  }

  function Delete() {
    dispatch(erp.currentAction({ actionType: 'delete', data: item }));
    modal.open();
  }

  function Confirm() {
    dispatch(erp.confirm({ entity, id: item._id }));
  }

  useEffect(() => {
    if ((!isLoading && isSuccess) || confirmSuccess) {
      dispatch(erp.list({ entity }));
    }
  }, [isLoading, confirmSuccess]);

  const itemMenu = (
    <Menu style={{ fontSize: 14 }}>
      {createRole(role, entity) &&
        row.isDraft === true &&
        (!config?.isDeleted || !getNestedProperty(row, config?.isDeleted)) && (
          <Menu.Item key="Confirm" onClick={Confirm}>
            <CheckOutlined />
            <span className="ml-2">{t('confirm')}</span>
          </Menu.Item>
        )}
      <Menu.Item
        key="Generate pdf"
        onClick={() => {
          dispatch(generatePDF(item._id, entity));
        }}
      >
        <DownloadOutlined />
        <span className="ml-2">{t('generate_pdf')}</span>
      </Menu.Item>
      <Menu.Item
        key="Notify client"
        onClick={() => {
          setIsNotifyModalOpen(true);
        }}
      >
        <MailOutlined />
        <span className="ml-2">{t('salesTracking.notify_client.title', true)}</span>
      </Menu.Item>
      {(deleteRole(role, entity) || (config.entity == 'role' && role?.settings)) &&
        (config.delete ?? true) && (
          <Menu.Item key="Delete" disabled={isDisabled(item)} onClick={Delete}>
            <DeleteOutlined />
            <span className="ml-2">{t('delete')}</span>
          </Menu.Item>
        )}
    </Menu>
  );

  const dropDown = (
    <Dropdown overlay={itemMenu} trigger={['click']} placement="bottom">
      <EllipsisOutlined className="flex-all-center" style={{ marginTop: 14 }} />
    </Dropdown>
  );

  return (
    <div style={{ display: 'flex' }}>
      {showDropdown ? (
        <>
          {isInExcludedCategory && (
            <>
              {(editRole(role, entity) ||
                (config.entity === 'role' &&
                  role?.settings &&
                  (!config?.isDeleted || !getNestedProperty(row, config?.isDeleted)))) &&
                !noEdit && (
                  <Button
                    key={`${uniqueId()}`}
                    disabled={isDisabled(item)}
                    onClick={Edit}
                    style={{ fontSize: 16 }}
                    type="text"
                  >
                    <EditOutlined />
                  </Button>
                )}

              {(deleteRole(role, entity) || (config.entity === 'role' && role?.settings)) &&
                (config.delete ?? true) &&
                !isSalesEntity && (
                  <Button
                    key={`${uniqueId()}`}
                    disabled={isDisabled(item)}
                    onClick={Delete}
                    style={{ fontSize: 16 }}
                    type="text"
                  >
                    <DeleteOutlined />
                  </Button>
                )}
            </>
          )}
          {createRole(role, entity) &&
            !isSalesEntity &&
            row.isDraft === true &&
            (!config?.isDeleted || !getNestedProperty(row, config?.isDeleted)) && (
              <Button key={`${uniqueId()}`} onClick={Confirm} type="text">
                <CheckOutlined />
              </Button>
            )}
          {isSalesEntity && dropDown}
        </>
      ) : deleteRole(role, entity) ? (
        <Button type={'text'} key={`${uniqueId()}`} onClick={Delete}>
          <DeleteOutlined />
        </Button>
      ) : null}
      {isNotifyModalOpen && (
        <NotifyClient
          setIsModalOpen={onIsNotifyModalOpenChange}
          entity={{
            value: entity,
            label: t(`salesTracking.salesIds.${entity}`, true),
          }}
          item={item}
        />
      )}
    </div>
  );
}
