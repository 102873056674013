import { DEFAULT_LANGUAGE } from '@/hooks/useTranslation'; // TODO change the DEFAULT_LANGUAGE to this file and change all the imports

export const LOGGED_IN_SERVER_ERRORS = ['max_number_of_users_reached'];

export const SALES_MODULES_WITH_DOWNLOADABLE_PDF = [
  'quote',
  'order',
  'delivery_note',
  'exit_voucher',
  'invoice',
];

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const DEFAULT_HEADERS = {
  headers: {
    'Accept-Language': localStorage.getItem('Language') || DEFAULT_LANGUAGE,
  },
};

export const DEFAULT_STALE_CACHE_TIME = 20 * 60 * 1000;
