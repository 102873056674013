export function areAllElementsChecked(row) {
  return Object?.keys(row)
    .filter((key) => key !== 'name')
    .every((key) => row[key] === true);
}

export const FULL_ACCESS = {
  read: true,
  create: true,
  edit: true,
  delete: true,
};

export const NO_ACCESS = {
  read: false,
  create: false,
  delete: false,
  edit: false,
};

const DEFAULT_ACCESS_DATA = {
  bi: false,
  settings: false,
  inventory: [
    { name: 'item', ...NO_ACCESS },
    {
      name: 'inventory_adjustment',
      ...NO_ACCESS,
    },
    {
      name: 'category',
      ...NO_ACCESS,
    },
    {
      name: 'transfer_order',
      ...NO_ACCESS,
    },
    { name: 'store', ...NO_ACCESS },
  ],
  sale: [
    { name: 'quote', ...NO_ACCESS },
    { name: 'order', ...NO_ACCESS },
    {
      name: 'delivery_note',
      ...NO_ACCESS,
    },
    {
      name: 'exit_voucher',
      ...NO_ACCESS,
    },
    {
      name: 'invoice',
      ...NO_ACCESS,
    },
    { name: 'client', ...NO_ACCESS },
    {
      name: 'sale_return',
      ...NO_ACCESS,
    },
  ],
  purchase: [
    {
      name: 'supplier',
      ...NO_ACCESS,
    },
    { name: 'vendor', ...NO_ACCESS },
  ],
};

export default DEFAULT_ACCESS_DATA;
