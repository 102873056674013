import React, { useState } from 'react';
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import useTranslations from '@/hooks/useTranslation';
import { InputNumber, Modal, Popconfirm } from 'antd';
import { profileSetting } from '@/redux/auth/action/profile';
import { useDispatch } from 'react-redux';

const VatSettings = ({ vatOptions }: { vatOptions: number[] }) => {
  const { t } = useTranslations('settingsPage.taxes.vat');
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newOption, setNewOption] = useState(1);
  const [newOptionErr, setNewOptionErr] = useState(false);

  const updateVat = (valueToDelete?: number) => {
    if (vatOptions.includes(newOption) && !valueToDelete) {
      setNewOptionErr(true);
      return;
    }

    let newVatOptions = [];
    if (valueToDelete) {
      newVatOptions = vatOptions.filter((option) => (option as number) !== valueToDelete);
    } else {
      newVatOptions = [...vatOptions, newOption];
      setNewOption(1);
    }

    dispatch(profileSetting.updateCompany({ vatOptions: newVatOptions }));
  };

  return (
    <div className="max-form-width vat_tax">
      <h4>{t('vat_options')}</h4>
      <div className="options">
        {(vatOptions || [])
          ?.sort((a, b) => a - b)
          ?.map((opt, index) => (
            <div key={index} className="card">
              {opt !== 0 ? opt?.toString().padStart(2, '0') : 0}%
              <Popconfirm
                title={t('delete')}
                okText={t('common.confirm', true)}
                onConfirm={() => updateVat(opt as number)}
                cancelText={t('common.cancel', true)}
              >
                <CloseCircleOutlined className="trash_icon" />
              </Popconfirm>
            </div>
          ))}
        <div className="card ml-4 cursor-pointer" onClick={() => setIsModalVisible(true)}>
          <PlusCircleOutlined className="plus_icon" />
        </div>
      </div>
      <Modal
        title={t('add_new')}
        width={350}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => updateVat()}
        okText={t('common.confirm', true)}
        cancelText={t('common.cancel', true)}
      >
        <div>
          <div>
            <span>{t('option_value')}</span>
            <InputNumber
              className={`ml-4 ${newOptionErr && 'input_err'}`}
              min={0}
              max={100}
              value={newOption}
              onChange={(val) => {
                setNewOption(val), setNewOptionErr(false);
              }}
            />
            <span className="ml-2">%</span>
          </div>
          {newOptionErr && <span className="vat_err_msg w-full">{t('option_already_exists')}</span>}
        </div>
      </Modal>
    </div>
  );
};

export default VatSettings;
