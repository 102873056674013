import { translate } from '@/hooks/useTranslation';
import DefaultPDF from '@/style/images/default-pdf.png';
import GreyPDF from '@/style/images/grey-pdf.png';

export enum PDFTemplates {
  DEFAULT = 'DEFAULT',
  GREY_PDF = 'GREY_PDF',
}

export const DEFAULT_PDF_TEMPLATE = PDFTemplates.DEFAULT;

export type PDFChoice = {
  label: string;
  value: PDFTemplates;
  image: string;
};

export const PDF_CHOICES: PDFChoice[] = [
  {
    label: translate('Default'),
    value: PDFTemplates.DEFAULT,
    image: DefaultPDF,
  },
  {
    label: translate('Grey PDF'),
    value: PDFTemplates.GREY_PDF,
    image: GreyPDF,
  },
];
