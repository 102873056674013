import { notification } from 'antd';
import history from '@/utils/history';
import codeMessage from './codeMessage';
import { translate } from '@/hooks/useTranslation';
import { LOGGED_IN_SERVER_ERRORS } from '@/types/constants';

const errorHandler = (error) => {
  const { response } = error;

  if (response && response.status) {
    const message = response?.data && response?.data?.message;

    const errorText = message || codeMessage[response.status];
    notification.config({
      duration: 10,
    });
    if (response.status === 401) {
      window.location.href = '/';
    }
    if (response.status !== 404) {
      notification.error({
        message: `${translate('errorHandler.status')}`,
        description:
          localStorage.getItem('isLoggedIn') == 'true' &&
          !LOGGED_IN_SERVER_ERRORS.includes(errorText)
            ? translate('errorHandler.msg')
            : translate(`serverErrors.${errorText}`),
      });
    }

    if (
      (response.data && response.data.jwtExpired) ||
      (response.data.message === 'subscriptionNotPaid' &&
        localStorage.getItem('isLoggedIn') === 'true')
    ) {
      history.push('/logout');
    }
    return response.data;
  } else {
    notification.config({
      duration: 5,
    });
    notification.error({
      message: `${translate('errorHandler.status')}`,
      description:
        localStorage.getItem('isLoggedIn') == 'true'
          ? translate('errorHandler.msg')
          : translate(`serverErrors.${errorText}`) || translate('errorHandler.msg'),
    });
    return {
      success: false,
      result: null,
      message: 'Cannot connect to the server, Check your internet network',
    };
  }
};

export default errorHandler;
