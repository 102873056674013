import { DEFAULT_HEADERS } from '@/types/constants';
import { endpoints } from '@/request/api';
import axios from 'axios';
import { useQuery } from 'react-query';
import { QueryKeys } from '@/types';
import { isEmpty } from 'lodash';

const fetchClients = async (id?: string) => {
  const response = await axios.get(
    `${endpoints.client.service}/${endpoints.client.entity}/read/${id || ''}`,
    DEFAULT_HEADERS,
  );
  return response.data;
};

const useGetClients = (id?: string, onlyById: boolean = false) => {
  const shouldFetch = !onlyById || (onlyById && !isEmpty(id));

  const { data, error, isLoading } = useQuery([QueryKeys.Client, id], () => fetchClients(id), {
    enabled: shouldFetch,
  });

  return {
    data: data?.result,
    error,
    isLoading,
  };
};

export default useGetClients;
