import * as actionTypes from './types';

type showProps = {
  title: String;
  Body;
};

const contextActions = (dispatch) => {
  return {
    popup: {
      show: (props: showProps) => {
        dispatch({ type: actionTypes.SHOW_POPUP, ...props });
      },

      hide: () => {
        dispatch({ type: actionTypes.HIDE_POPUP });
      },
    },
  };
};

export default contextActions;
