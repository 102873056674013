import { useEffect } from 'react';
import { Button, Form, FormInstance, Layout, PageHeader } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useErpContext } from '../../context/erp';
import { erp } from '../../redux/erp/actions';
import Loading from '../Loading';
import uniqueId from '../../utils/uinqueId';
import { selectCreatedItem } from '../../redux/erp/selectors';
import useTranslations from '@/hooks/useTranslation';
import { ArrowLeftOutlined, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Content } = Layout;

type CreateProps = {
  config: any;
  CreateForm: any;
  itemFromForm: (fieldsValue) => {};
  changePrice: boolean;
};

export default function Create<DataStructure>({
  config,
  CreateForm,
  itemFromForm,
  changePrice = false,
}: CreateProps) {
  let { entity, CREATE_ENTITY } = config;
  const { erpContextAction } = useErpContext();
  const { createPanel } = erpContextAction;
  const dispatch = useDispatch();
  const { isLoading, isSuccess } = useSelector(selectCreatedItem);
  const [form] = Form.useForm();
  const { t } = useTranslations();

  const cancelCreation = () => {
    form.resetFields();
    createPanel.close();
  };
  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      dispatch(erp.resetAction({ actionType: 'create' }));
      createPanel.close();
      dispatch(erp.list({ entity }));
    }
  }, [isSuccess]);

  const onSubmit = (fieldsValue) => {
    if (fieldsValue) {
      const item = itemFromForm(fieldsValue);
      dispatch(erp.create({ entity, jsonData: item, changePrice }));
    }
  };

  const onDraft = () => {
    // createPanel.close()
    form.setFieldValue('isDraft', true);
    form.submit();
  };

  if (isLoading && entity === 'role')
    return (
      <Loading isLoading={true}>
        <></>
      </Loading>
    );

  return (
    <>
      <PageHeader
        className={'header'}
        title={CREATE_ENTITY}
        ghost={true}
        backIcon={<ArrowLeftOutlined />}
        onBack={cancelCreation}
        style={{
          boxShadow: '0 0px 6px rgba(0, 0, 0, 0.1)',
        }}
      />
      <Loading isLoading={isLoading}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Content
            className={'inside-content'}
            style={
              config.small
                ? { height: 'calc(100vh - 375px)', marginBottom: 30, paddingTop: 15 }
                : { height: 'calc(100vh - 230px)', marginBottom: 25, paddingTop: 15 }
            }
          >
            <CreateForm form={form} config={config} />
            <Form.Item name={'isDraft'} />
          </Content>
          <Content className={'form-buttons'}>
            <SaveButton form={form} key={`${uniqueId()}`} name={config.submitButton} />
            {config?.draft && (
              <Button key={`${uniqueId()}`} onClick={onDraft} style={{ marginRight: '15px' }}>
                {t('common.saveDraft')}
              </Button>
            )}
            <Button key={`${uniqueId()}`} onClick={cancelCreation} icon={<CloseCircleOutlined />}>
              {t('erp.add.cancel')}
            </Button>
          </Content>
        </Form>
      </Loading>
    </>
  );
}

type SaveProps = {
  form: FormInstance;
  name: string;
};

function SaveButton({ form, name }: SaveProps) {
  const handelClick = async () => {
    form
      .validateFields()
      .then(form.submit)
      .catch((e) => form.scrollToField(e.errorFields?.[0].name?.[0] || 'name'));
  };

  return (
    <>
      <Button
        style={{ background: '#326CFE', marginRight: '15px' }}
        onClick={handelClick}
        type="primary"
        icon={<PlusOutlined />}
      >
        {name}
      </Button>
    </>
  );
}
