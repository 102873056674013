import { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, Input, Layout, List, Modal, Space } from 'antd';
import { LockOutlined, PlusCircleOutlined } from '@ant-design/icons';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import DataTableDropMenu from '@components/ErpPanel/DataTableDropMenu';
import {
  tableColumnDate,
  tableColumnNone,
  tableColumnNumber,
  tableColumnString,
} from '@/utils/tableHelper';
import useTranslations, { translate } from '@/hooks/useTranslation';
import { TableFilterOptions } from './tableFilterOptions.enum';
import { isEmpty } from 'lodash';

const { Content } = Layout;
const DRAFT_STATUS_OPTIONS = [
  {
    name: translate('common.draft'),
    value: 'Draft',
  },
  {
    name: translate('common.confirmed'),
    value: 'Confirmed',
  },
];

const useItemDropDownColumn = (config, isDisabled, actualEntity) => {
  const { ELEMENTS, DEFAULT, showDropdown = true } = config;
  const dataTableColumns = actualEntity ? config.dataTableColumns : config.dataTableColumns2;
  const entity = actualEntity ? config.entity : config.anotherEntity;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [search, setSearch] = useState('');
  const defaultIndexes = DEFAULT?.map((defaultField) => {
    return dataTableColumns.findIndex((column) => column.title === defaultField);
  });

  const [choice, setChoice] = useState(
    localStorage.getItem(entity) ? JSON.parse(localStorage.getItem(entity ?? [])) : defaultIndexes,
  );
  const [localChoice, setLocalChoice] = useState(choice);
  const [category, setCategory] = useState(null);
  const { t } = useTranslations();
  const path = 'erp.';

  useEffect(() => {
    localStorage.setItem(entity, JSON.stringify(choice ?? []));
  }, [choice]);

  const column = {
    width: 150,
    fixed: 'right',
    title: (
      <Space>
        {showDropdown ? (
          <div>
            <Button
              type="text"
              style={{ color: 'gray' }}
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              <PlusCircleOutlined />
            </Button>
            <Modal
              visible={isModalVisible}
              onOk={() => {
                setChoice(localChoice);
                setIsModalVisible(false);
              }}
              onCancel={() => {
                setLocalChoice(choice);
                setIsModalVisible(false);
              }}
            >
              <Alert
                style={{ marginTop: '20px' }}
                message={t(path + 'modalAlert')}
                type="info"
                showIcon
              />

              <Input
                style={{ marginTop: '20px', borderRadius: '8px', height: '40px' }}
                placeholder="Search"
                className="Table-search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                prefix={<SearchOutlined />}
              />
              <Checkbox.Group
                value={localChoice}
                onChange={(checkedValue) => {
                  if (
                    checkedValue.length + ELEMENTS.length < 9 &&
                    checkedValue.length + ELEMENTS.length > 0
                  ) {
                    setLocalChoice(checkedValue);
                  }
                }}
                style={{ marginTop: '15px', width: '100%' }}
              >
                <List
                  dataSource={dataTableColumns.filter((element) => {
                    return element.title.toLowerCase().includes(search.toLowerCase());
                  })}
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                  renderItem={(item, index) => {
                    return ELEMENTS.includes(item.title) ? (
                      <Content
                        style={{
                          padding: 10,
                          margin: '8px 0px 0px 0px',
                          background: '#F4F8FB',
                          borderRadius: 10,
                          height: '50px',
                          width: '100%',
                        }}
                      >
                        <LockOutlined /> <span className="px-8">{item.title}</span>
                      </Content>
                    ) : (
                      <Checkbox
                        style={{
                          height: '50px',
                          width: '100%',
                          padding: 10,
                          margin: '8px 0px 0px 0px',
                          background: '#F4F8FB',
                          borderRadius: '10px',
                        }}
                        disabled={
                          (localChoice && localChoice.length ? localChoice.length : 0) +
                            (ELEMENTS.length ?? 0) >=
                            8 && !localChoice.includes(index)
                        }
                        value={index}
                      >
                        {item.title}
                      </Checkbox>
                    );
                  }}
                />
              </Checkbox.Group>
            </Modal>
          </div>
        ) : null}
      </Space>
    ),
    render: (row) => {
      if (row?.name === 'OWNER' && entity === 'role') return null;
      else
        return (
          <Space>
            {showDropdown ? (
              <>
                {entity === 'item_group' ? (
                  <Button
                    onClick={() => {
                      setCategory(row._id);
                    }}
                    type={'link'}
                  >
                    Show items
                  </Button>
                ) : (
                  <DataTableDropMenu
                    config={config}
                    isDisabled={isDisabled}
                    row={row}
                    entity={entity}
                  />
                )}
              </>
            ) : (
              <DataTableDropMenu
                row={row}
                entity={entity}
                isDisabled={isDisabled}
                config={config}
              />
            )}
          </Space>
        );
    },
  };

  return { column, choice, category, setCategory };
};

export const useTableColumns = (config, isDisabled, actualEntity = true) => {
  const [open, setOpen] = useState({});
  const [filters, setFilters] = useState({});

  const { column, choice, category, setCategory } = useItemDropDownColumn(
    config,
    isDisabled,
    actualEntity,
  );

  let dataTableColumns = actualEntity ? config.dataTableColumns : config.dataTableColumns2;
  const ELEMENTS = actualEntity ? config.ELEMENTS : config.ELEMENTS2;

  let tableColumns = [
    ...dataTableColumns.filter((value, index) => {
      return choice?.includes(index) || (ELEMENTS?.includes(value.title) ?? {});
    }),
    column,
  ];

  const columnList = tableColumns.map((item, index) => {
    const itemFilter = filters[item.dataIndex];
    const itemOpen = open[item.dataIndex];
    const itemSetOpen = (open) => {
      setOpen({ ...open, [item.dataIndex]: open });
    };

    switch (item.type) {
      case TableFilterOptions.DATE:
        return tableColumnDate(item, itemFilter, itemOpen, itemSetOpen);
      case TableFilterOptions.NUMBER:
        return tableColumnNumber(item, itemFilter, itemOpen, itemSetOpen, config.entity);
      case TableFilterOptions.STRING:
        return tableColumnString(item, itemFilter, itemOpen, itemSetOpen, config.entity);
      case TableFilterOptions.DRAFT_STATUS:
        return tableColumnString(
          item,
          itemFilter,
          itemOpen,
          itemSetOpen,
          config.entity,
          null,
          true,
          DRAFT_STATUS_OPTIONS,
        );
      case TableFilterOptions.ENUM:
        return tableColumnString(
          item,
          itemFilter,
          itemOpen,
          itemSetOpen,
          config.entity,
          null,
          true,
          item?.options,
        );
      case TableFilterOptions.IN_OTHER_DOC:
        return !isEmpty(item?.otherModelName)
          ? tableColumnString(
              item,
              itemFilter,
              itemOpen,
              itemSetOpen,
              config.entity,
              item.otherModelName,
            )
          : tableColumnNone(item);
      default:
        return tableColumnNone(item);
    }
  });
  return { columns: columnList, isDisabled, setFilters, filters, category, setCategory };
};
