import * as actionTypes from './types';
import { OPEN_PURCHASE_MODAL } from './types';

const INITIAL_STATE = {
  current: {
    result: null,
  },
  list: {
    result: {
      items: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: false,
        total: 1,
      },
    },
    isLoading: false,
    isSuccess: false,
  },
  listCategories: {
    result: {
      items: [],
    },
    isLoading: false,
    isSuccess: false,
  },
  listUsers: {
    result: {
      items: [],
    },
    isLoading: false,
    isSuccess: false,
  },
  readUser: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  createUser: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  updateUser: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  deleteUser: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  create: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  update: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  delete: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  deleteMany: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  read: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  confirm: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },

  recordPayment: {
    result: null,
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  search: {
    result: [],
    current: null,
    isLoading: false,
    isSuccess: false,
  },
  listItemByCategory: {
    result: [],
    current: {},
    isLoading: false,
    isSuccess: false,
  },
  listRoles: {
    result: {
      items: [],
    },
    isLoading: false,
    isSuccess: false,
  },
  itemsList: {
    result: [],
    isLoading: false,
    isSuccess: false,
  },
  changedItems: {
    result: [],
    isLoading: false,
    isSuccess: false,
  },
  updatePrice: {
    result: null,
    isLoading: false,
    isSuccess: false,
  },
  openModal: false,
  payment: {
    result: {
      items: [],
    },
    isLoading: false,
    isSuccess: false,
  },
};

const erpReducer = (state = INITIAL_STATE, action) => {
  const { payload, keyState } = action;
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return INITIAL_STATE;
    case actionTypes.RESET_CURRENT_PAYLOAD:
      return { ...state, read: { result: {} } };
    case actionTypes.CURRENT_ITEM:
      return {
        ...state,
        current: {
          result: payload,
        },
      };
    case actionTypes.REQUEST_LOADING:
      return {
        ...state,
        [keyState]: {
          ...state[keyState],
          isLoading: true,
        },
      };
    case actionTypes.REQUEST_FAILED:
      return {
        ...state,
        [keyState]: {
          ...state[keyState],
          isLoading: false,
          isSuccess: false,
        },
      };
    case actionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        [keyState]: {
          result: payload,
          isLoading: false,
          isSuccess: true,
        },
      };
    case actionTypes.CURRENT_ACTION:
      return {
        ...state,
        [keyState]: {
          ...state[keyState],
          current: payload,
        },
      };
    case actionTypes.RESET_ACTION:
      return {
        ...state,
        [keyState]: {
          ...INITIAL_STATE[keyState],
        },
      };
    case actionTypes.ITEM_LIST_SUCCESS:
      return {
        ...state,
        changedItems: {
          result: payload,
          isLoading: false,
          isSuccess: true,
        },
      };
    case actionTypes.CONFIRM_SUCCESS:
      const newData = state.list.result.items.map((elt) => {
        if (elt._id === payload._id) {
          return payload;
        } else {
          return elt;
        }
      });
      return {
        ...state,
        list: {
          result: { pagination: state.list.result.pagination, items: newData },
          isLoading: false,
          isSuccess: true,
        },
      };

    case actionTypes.OPEN_PURCHASE_MODAL:
      return { ...state, openModal: true };
    case actionTypes.CLOSE_PURCHASE_MODAL:
      return { ...state, openModal: false };
    case actionTypes.CHANGE_PRICE_SUCCESS:
      return {
        ...state,
        changedItems: {
          isLoading: false,
          isSuccess: true,
        },
      };
    default:
      return state;
  }
};

export default erpReducer;
