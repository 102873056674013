import { Section } from '@/modules/SettingsModule/Section';
import { Divider, Form } from 'antd';
import { UpdateLogo } from '@/modules/SettingsModule/tabs/Profile/updateLogo';
import { useEffect, useState } from 'react';
import { PersonalForm } from '@/modules/SettingsModule/tabs/Profile/personalForm';
import UnknownProfile from '@/style/images/profile.jpeg';
import { Buttons } from '@/modules/SettingsModule/buttons';
import { LanguageForm } from '@/modules/SettingsModule/tabs/Profile/languageForm';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '@/redux/auth/selectors';
import { profileSetting } from '@/redux/auth/action/profile';
import useTranslations from '@/hooks/useTranslation';
import { isEmpty } from 'lodash';
import Loading from '@/components/Loading';

export default function Profile() {
  const [form] = Form.useForm();
  const profile = useSelector(selectUser);
  const dispatch = useDispatch();
  const { t } = useTranslations();

  const [profileData, setProfileData] = useState({ profile });

  const [profileImage, setProfileImages] = useState();
  const areCommonFieldsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    const newFields = keys1.filter(
      (key) => JSON.stringify(obj1[key]) !== '' && !keys2.includes(key),
    );
    if (newFields.length > 0) return false;
    const commonKeys = keys1.filter((key) => keys2.includes(key));
    for (const key of commonKeys) {
      if (key !== 'image' && JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    form.setFieldsValue(profileData);
    if (!isEmpty(profileData?.profile?.image)) setProfileImages(profileData?.profile?.image);
  }, [profileData]);

  const updateProfile = (values) => {
    form.setFieldsValue(values.profile);
    dispatch(profileSetting.updateProfile(values.profile, profile));
  };

  const completeProfile = (values) => {
    form.setFieldsValue(values);
    dispatch(profileSetting.completeProfile(values));
  };

  const updateProfileImage = () => {
    if (profileImage !== profile.image) {
      dispatch(profileSetting.updateImageProfile(profileImage));
    }
  };

  const handleSubmit = (values) => {
    if (profile.isProfileCompleted) {
      if (!areCommonFieldsEqual(values.profile, profile)) {
        updateProfile(values);
      }
    } else {
      completeProfile(values);
    }
    updateProfileImage();
  };

  useEffect(() => {
    if (isEmpty(profileData.profile)) setProfileData({ profile });
  }, [profile]);

  const handleCancel = () => {
    form.setFieldsValue(profileData);
    setProfileImages(profileData?.profile?.image);
  };

  return (
    <Loading isLoading={isEmpty(profileData?.profile)}>
      <Form
        className={'page'}
        form={form}
        name="settingForm"
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <div className={'main'}>
          <Section
            title={t('settingsPage.profile_image.title')}
            description={t('settingsPage.profile_image.subtitle')}
            Body={() => (
              <UpdateLogo
                name={['profile', 'image']}
                defaultImage={profile?.image}
                form={form}
                profile={true}
                image={profileImage || UnknownProfile}
                setImage={setProfileImages}
                title={t('settingsPage.profile_image.upload_title')}
                description={t('settingsPage.profile_image.upload_subtitle')}
              />
            )}
          />
          <Divider />
          <Section
            title={t('settingsPage.personal_information.title')}
            description={t('settingsPage.personal_information.subtitle')}
            Body={() => <PersonalForm form={form} />}
          />
          <Divider />
          <Section
            title={t('settingsPage.language.title')}
            description={t('settingsPage.language.subtitle')}
            Body={() => <LanguageForm />}
          />
        </div>
        <Buttons onCancel={handleCancel} />
      </Form>
    </Loading>
  );
}
