import Router from '@/router';
import { Layout } from 'antd';
import Navigation from '@/app/Navigation';
import { useSelector } from 'react-redux';
import { selectAuth } from '@/redux/auth/selectors';
import HeaderContent from '@/app/HeaderContent';
import SiderContextLayout from '@/layout/SiderContextLayout';
import SideForm from '@components/SideForm';
import PageLoader from '@/components/PageLoader';
import { Spin } from 'antd';
import PopupContextLayout from '@/layout/PopupContextLayout';
import PopupForm from '@components/PopupForm';
import ErrorFallback from '@/error';
import { ErrorBoundary } from 'react-error-boundary';

Spin.setDefaultIndicator(<PageLoader />);

function App() {
  const { isLoggedIn } = useSelector(selectAuth);
  if (!isLoggedIn) return <Router />;
  else {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <PopupContextLayout>
          <SiderContextLayout>
            <Layout hasSider>
              <Layout hasSider>
                <Navigation />
                <Layout>
                  <HeaderContent />
                  <div style={{ marginLeft: 200, marginTop: 75 }}>
                    <Router isLoggedIn={true} />
                  </div>
                </Layout>
              </Layout>
              <SideForm />
              <PopupForm />
            </Layout>
          </SiderContextLayout>
        </PopupContextLayout>
      </ErrorBoundary>
    );
  }
}

export default App;
