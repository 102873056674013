import * as actionTypes from './types';

type showProps = {
  title: String;
  Avatar;
  Body;
  Footer;
  status;
  HeaderDate;
};
const contextActions = (dispatch) => {
  return {
    sider: {
      show: (props: showProps) => {
        dispatch({ type: actionTypes.SHOW_MODAL, ...props });
      },
      hide: () => {
        dispatch({ type: actionTypes.HIDE_MODAL });
      },
    },
    form: {
      set: (data) => {
        dispatch({ type: actionTypes.UPDATE_FORM, payload: data });
      },
    },
  };
};

export default contextActions;
