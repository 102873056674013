import { notification } from 'antd';
import codeMessage from './codeMessage';
import { translate } from '@/hooks/useTranslation';

const successHandler = (
  response,
  options = {
    notifyOnSuccess: false,
    notifyOnFailed: true,
    validateSuccess: true,
  },
) => {
  const { data } = response;

  if (data && (!options.validateSuccess || data.success === true)) {
    if (options.notifyOnSuccess) {
      notification.config({
        duration: 5,
      });
      notification.success({
        message: translate('successHandler.status'),
        description: translate('successHandler.msg'),
      });
    }
  } else {
    const message = response.data && data.message;
    const errorText = message || codeMessage[response.status];
    if (options.notifyOnFailed) {
      notification.config({
        duration: 5,
      });
      notification.error({
        message: `${translate('errorHandler')}`,
        description:
          localStorage.getItem('isLoggedIn') == 'true'
            ? translate('errorHandler.msg')
            : translate(`serverErrors.${errorText}`),
      });
    }
  }
};

export default successHandler;
