import { config } from '@/app/Navigation/config';

const getRole = (role, name) => {
  const item = config[name];
  if (item) {
    if (item.category === 'none') {
      return role?.[item.name];
    }
    return role?.[item.category]?.[item.name];
  }
  return { read: true, create: true, edit: true, delete: true };
};
export const readRole = (role, name) => {
  return getRole(role, name)?.read;
};

export const createRole = (role, name) => {
  return getRole(role, name)?.create;
};

export const editRole = (role, name) => {
  return getRole(role, name)?.edit;
};

export const deleteRole = (role, name) => {
  return getRole(role, name)?.delete;
};

export const assessItemAccessPrivileges = (obj, key) => {
  if (typeof obj[key] === 'boolean') {
    return obj[key];
  } else if (typeof obj[key] === 'object') {
    for (let subKey in obj[key]) {
      if (assessItemAccessPrivileges(obj[key], subKey)) {
        return true;
      }
    }
  }
  return false;
};
