import { Button, Form, Input, Select, Upload } from 'antd';
import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import useTranslations from '@/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { selectUser } from '@/redux/auth/selectors';
import { request } from '@/request';

export default function BugForm() {
  const { t } = useTranslations();
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const path = 'settingsPage.report_bug.';
  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const formData = new FormData();
        for (const [key, value] of Object.entries(values)) {
          formData.append(key, value);
        }
        values.assetsLinks?.forEach((file) => {
          formData.append('assetsLinks', file.originFileObj);
        });

        const response = await request.post({
          entity: 'bugReport',
          data: formData,
        });
        setLoading(false);

        if (response.success) form.resetFields();
      })
      .catch(() => {});
  };
  return (
    <Form form={form}>
      <Form.Item label={t(path + 'primary_contact')}>
        <div style={{ gap: 10 }} className="flex w-full">
          <Form.Item
            noStyle={true}
            name="salutation"
            rules={[
              {
                required: true,
                message: t('inputs.firm.city.required'),
              },
            ]}
          >
            <Select
              placeholder={t(path + 'salutation.field')}
              options={[
                {
                  value: 'mr',
                  label: t(path + 'salutation.options.mr'),
                },
                {
                  value: 'mrs',
                  label: t(path + 'salutation.options.mrs'),
                },
                {
                  value: 'ms',
                  label: t(path + 'salutation.options.ms'),
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            noStyle={true}
            initialValue={user.surname}
            name="firstName"
            rules={[
              {
                required: true,
                message: t('inputs.firm.city.required'),
              },
            ]}
          >
            <Input placeholder={t(path + 'first_name')} />
          </Form.Item>
          <Form.Item
            noStyle={true}
            initialValue={user.name}
            name="lastName"
            rules={[
              {
                required: true,
                message: t('inputs.firm.city.required'),
              },
            ]}
          >
            <Input placeholder={t(path + 'last_name')} />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        initialValue={user.email}
        name="email"
        label={t(path + 'email')}
        rules={[
          {
            required: true,
            message: t('inputs.personal.email.required'),
          },
          {
            type: 'email',
            message: t('inputs.personal.email.invalid'),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label={t(path + 'bug_description')}
        rules={[
          {
            required: true,
            message: t('inputs.firm.city.required'),
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="priority_level"
        label={t(path + 'priority-level.field')}
        rules={[
          {
            required: true,
            message: t('inputs.firm.city.required'),
          },
        ]}
      >
        <Select
          placeholder={t(path + 'priority-level.field')}
          className={'width-35'}
          options={[
            {
              value: 'LOW',
              label: t(path + 'priority-level.options.low'),
            },
            {
              value: 'MEDIUM',
              label: t(path + 'priority-level.options.medium'),
            },
            {
              value: 'HIGH',
              label: t(path + 'priority-level.options.high'),
            },
            {
              value: 'URGENT',
              label: t(path + 'priority-level.options.urgent'),
            },
          ]}
          allowClear
        />
      </Form.Item>
      <Form.Item className={'mrg-b-0'} name="assetsLinks" label={null}>
        <div className="attachments">
          <div className="label">
            {t(path + 'attachments')}:
            <p className="label-description">{t(path + 'attachments_subtitle')}</p>
          </div>
          <Upload
            maxCount={10}
            beforeUpload={() => false}
            onChange={({ fileList }) => {
              form.setFieldsValue({ assetsLinks: [...fileList] });
            }}
          >
            <Button icon={<UploadOutlined />}>{t(path + 'upload_button')}</Button>
          </Upload>
        </div>
      </Form.Item>
      <div className="report-last-section">
        <Button type="primary" loading={loading} size="middle" onClick={onFinish}>
          {t(path + 'cta')}
        </Button>
      </div>
    </Form>
  );
}
