import * as actionTypes from '../types';
import { request } from '@/request';
import { current } from '@/redux/auth/action/auth';
import history from '@/utils/history';

export const profileSetting = {
  updatePassword: (jsonData) => async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_REQUEST,
      keyState: 'update',
      payload: null,
    });

    let data = await request.update({
      entity: 'admin',
      id: 'password',
      jsonData,
    });

    if (data.success === true) {
      dispatch({
        type: actionTypes.UPDATE_PASSWORD_SUCCESS,
        payload: data.result,
      });
    } else {
      dispatch({
        type: actionTypes.FAILED_REQUEST,
        keyState: 'update',
        payload: null,
      });
    }
  },
  updateCompany: (jsonData) => async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_REQUEST,
      keyState: 'update',
      payload: null,
    });

    let data = await request.patch({
      entity: 'company',
      action: '/update',
      jsonData,
    });

    if (data.success === true) {
      dispatch({
        type: actionTypes.UPDATE_COMPANY_SUCCESS,
        payload: data.result,
      });
    } else {
      dispatch({
        type: actionTypes.FAILED_REQUEST,
        keyState: 'update',
        payload: null,
      });
    }
  },
  updateProfile: (jsonData) => async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_REQUEST,
      keyState: 'update',
      payload: null,
    });

    let data = await request.update({
      entity: 'admin',
      id: 'profile',
      jsonData,
    });

    if (data.success === true) {
      dispatch({
        type: actionTypes.UPDATE_PROFILE_SUCCESS,
        payload: data.result,
      });
    } else {
      dispatch({
        type: actionTypes.FAILED_REQUEST,
        keyState: 'update',
        payload: null,
      });
    }
  },
  completeProfile: (jsonData) => async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_REQUEST,
      keyState: 'update',
      payload: null,
    });

    let data = await request.update2({
      entity: 'admin',
      id: 'complete',
      jsonData,
    });
    if (data.success === true) {
      dispatch({
        type: actionTypes.COMPLETE_PROFILE_SUCCESS,
      });
      dispatch(current());
      history.push('/');
    } else {
      dispatch({
        type: actionTypes.FAILED_REQUEST,
        keyState: 'update',
        payload: null,
      });
    }
  },
  updateImageProfile: (jsonData) => async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_REQUEST,
      keyState: 'update',
      payload: null,
    });
    const formData = new FormData();
    formData.append('image', jsonData);

    let data = await request.update2({
      entity: 'admin',
      id: 'upload',
      jsonData: formData,
    });

    if (data.success === true) {
      dispatch({
        type: actionTypes.UPDATE_IMAGE_PROFILE_SUCCESS,
        payload: data.result,
      });
    } else {
      dispatch({
        type: actionTypes.FAILED_REQUEST,
        keyState: 'update',
        payload: null,
      });
    }
  },
  updateImageCompany: (jsonData) => async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_REQUEST,
      keyState: 'update',
      payload: null,
    });
    const formData = new FormData();
    formData.append('image', jsonData);

    let data = await request.update2({
      entity: 'company',
      id: 'upload',
      jsonData: formData,
    });

    if (data.success === true) {
      dispatch({
        type: actionTypes.UPDATE_IMAGE_COMPANY_SUCCESS,
        payload: data.result,
      });
    } else {
      dispatch({
        type: actionTypes.FAILED_REQUEST,
        keyState: 'update',
        payload: null,
      });
    }
  },
};
