import { Button, Col, Form, Input, Modal, InputNumber, Row, Progress } from 'antd';
import { useEffect, useRef, useState } from 'react';
import FlagSelector, { TnPhonePattern } from '@components/FlagSelector';
import useTranslations from '@/hooks/useTranslation';
import { PasswordRuleRegex, calculatePasswordStrength } from '@/forms/SignUp/SignUp1';
import { request } from '@/request';
import Loading from '@/components/Loading';
import successTick from '@/style/images/success-tick.svg';
import { motion } from 'framer-motion';

export const PersonalForm = ({ form }) => {
  const { t } = useTranslations();
  const timer = useRef(null);
  const [changePasswordForm] = Form.useForm();
  const [countryCode, setCountryCode] = useState('TN');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [isChangeLoading, setIsChangeIsLoading] = useState(false);
  const [isForgotLoading, setIsForgotLoading] = useState(false);
  const [shake, setShake] = useState(false);
  const [success, setSuccess] = useState({ change: false, forgot: false });

  const handlePasswordChangeForStrength = (e) => {
    const password = e.target.value;
    setPasswordStrength(calculatePasswordStrength(password));
  };

  const handleModalTimeout = () => {
    timer.current = setTimeout(() => {
      setIsModalVisible(false);
      setSuccess({ change: false, forgot: false });
    }, 2000);
  };

  const handlePasswordChange = async ({ oldPassword, newPassword, newPasswordVerified }) => {
    setShake(false);
    setIsChangeIsLoading(true);
    const data = await request.update({
      entity: 'admin',
      id: 'password',
      jsonData: {
        oldPassword,
        newPassword,
        newPasswordVerified,
      },
    });
    setIsChangeIsLoading(false);
    if (data.success) {
      setSuccess({ change: true, forgot: false });
      handleModalTimeout();
      return;
    }
    setShake(true);
  };

  const handleForgotPassword = async () => {
    const { email } = form.getFieldValue('profile');
    setIsForgotLoading(true);
    const data = await request.post({
      entity: 'forgotPassword',
      data: {
        email,
      },
    });
    setIsForgotLoading(false);
    if (data.success) {
      setSuccess({ change: false, forgot: true });
      handleModalTimeout();
    }
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const successfull = () => {
    return (
      <div className="change-pwd-success">
        <img src={successTick} alt="Success tick" className="success-tick" />
        <span className="text">
          {t(
            success.change
              ? 'settingsPage.change_password.success.change'
              : 'settingsPage.change_password.success.forgot',
          )}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="max-form-width">
        <Col>
          <Form.Item label={t('settingsPage.personal_information.full_name')}>
            <div className="user-full-name w-full">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please enter your first name!',
                  },
                ]}
                noStyle={true}
                name={['profile', 'surname']}
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please enter your name!',
                  },
                ]}
                noStyle={true}
                name={['profile', 'name']}
              >
                <Input />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please enter the username!',
              },
            ]}
            name={['profile', 'display_username']}
            label={t('settingsPage.personal_information.username')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['profile', 'email']}
            label={t('settingsPage.personal_information.email')}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(t('inputs.personal.phone_number.required'));
                  }
                  if (countryCode == 'TN' && !TnPhonePattern.test(value)) {
                    return Promise.reject(t('inputs.personal.phone_number.tn'));
                  }
                  const length = value.toString().length;
                  if (countryCode != 'TN' && !(length >= 5 && length <= 10)) {
                    return Promise.reject(t('inputs.personal.phone_number.invalid'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            label={t('settingsPage.personal_information.phone_number')}
            name={['profile', 'phoneNumber', 'number']}
          >
            <InputNumber
              className="phoneInput"
              placeholder={countryCode === 'TN' ? '22123123' : ''}
              size="middle"
              type="number"
              addonBefore={<FlagSelector update={setCountryCode} />}
            />
          </Form.Item>
          <Button
            className="change-pwd-btn btns-padding w-full"
            type={'link'}
            onClick={() => setIsModalVisible(true)}
          >
            {t('settingsPage.change_password.title')}
          </Button>
        </Col>
      </div>
      <Modal
        title={t('settingsPage.change_password.title')}
        visible={isModalVisible}
        onCancel={() => {
          setSuccess({ change: false, forgot: false });
          setIsModalVisible(false);
        }}
        footer={null}
      >
        {success.change || success.forgot ? (
          successfull()
        ) : (
          <Form name="changePasswordForm" form={changePasswordForm} onFinish={handlePasswordChange}>
            <motion.div
              className={shake ? 'old-pwd-error' : ''}
              animate={shake ? { x: [-10, 10, -10, 10, -5, 5, -5, 5, 0] } : {}}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('inputs.personal.password.required'),
                  },
                ]}
                name="oldPassword"
                label={t('settingsPage.change_password.old_pass')}
              >
                <Input.Password />
              </Form.Item>
            </motion.div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('inputs.personal.password.required'),
                },
                {
                  pattern: new RegExp(PasswordRuleRegex),
                  message: t('inputs.personal.password.invalid'),
                },
              ]}
              name="newPassword"
              label={t('settingsPage.change_password.new_pass')}
            >
              <Input.Password onChange={handlePasswordChangeForStrength} />
            </Form.Item>
            <Progress percent={passwordStrength} />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('inputs.personal.confirm_password.required'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('inputs.personal.confirm_password.invalid'));
                  },
                }),
              ]}
              name="newPasswordVerified"
              label={t('settingsPage.change_password.confirm_new_pass')}
            >
              <Input.Password />
            </Form.Item>
            <Row className="change-pwd-ctas">
              <Col>
                <Loading isLoading={isForgotLoading}>
                  <span className="forgot-password" onClick={handleForgotPassword}>
                    {t('signin.cta.forgot_password')}
                  </span>
                </Loading>
              </Col>
              <Col>
                <Button className="save" type="primary" htmlType="submit" loading={isChangeLoading}>
                  {t('common.save')}
                </Button>
                <Button onClick={() => setIsModalVisible(false)}>{t('common.cancel')}</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
};
