import ErpContextLayout from '../ErpContextLayout';

import { Layout } from 'antd';

const { Content } = Layout;

export default function ErpLayout({ children }) {
  return (
    <ErpContextLayout>
      <Layout className="site-layout-background">
        <Content
          className="whitebox"
          style={{
            width: '100%',
            backgroundColor: 'white',
            minheight: '100vh',
          }}
        >
          {children}
        </Content>
      </Layout>
    </ErpContextLayout>
  );
}
