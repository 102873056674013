import { createSelector } from 'reselect';

const saleSelect = (state) => state.sale;

export const selectSaleLoading = createSelector([saleSelect], (sale) => sale.loading);
export const selectSaleData = createSelector([saleSelect], (sale) => sale.data);
export const selectSaleDataById = createSelector([saleSelect], (sale) => sale.dataById);
export const selectSalePdf = createSelector([saleSelect], (sale) => sale.pdf);
export const selectSaleCounter = createSelector([saleSelect], (sale) => sale.counter);
export const selectSaleChangedItems = createSelector([saleSelect], (sale) => sale.changedItems);
export const selectSaleOpenModal = createSelector([saleSelect], (sale) => sale.openModal);
export const selectSaleOrderId = createSelector([saleSelect], (sale) => sale.orderId);
