import * as actionTypes from './types';

const INITIAL_STATE = {
  dataById: {},
  data: [],
  pdf: null,
  loading: false,
  counter: 0,
  changedItems: undefined,
  openModal: false,
  orderId: null,
};

const saleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOADING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FAILED_REQUEST:
      return state;

    case actionTypes.CHANGE_STATUS_SUCCESS:
      const { data } = state;
      const newData = data.map((i) => {
        if (!i.quote) {
          return i;
        }
        if (i.quote.id === action.payload._id) {
          const newRow = i;
          newRow.quote.isDraft = action.payload.isDraft;
          return newRow;
        }
        return i;
      });
      return { ...state, data: newData, loading: false };
    case actionTypes.CONVERSION_SUCCESS:
      const newsales = state.data.map((i) => {
        if (i.counter === action.payload.counter) {
          return action.payload;
        }
        return i;
      });
      return { ...state, data: newsales, loading: false };

    case actionTypes.CREATE_ENTITY_SUCCESS:
      return {
        ...state,
        data: [action.payload, ...state.data],
        counter: state.counter + 1,
        loading: false,
      };
    case actionTypes.CREATE_SALE_RETURN_SUCCESS:
      const newResult = state.data.map((i) => {
        if (i._id !== action.payload._id) {
          return i;
        } else return action.payload;
      });

      return {
        ...state,
        data: newResult,
        loading: false,
      };
    case actionTypes.PAYMENT_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
        loading: false,
      };
    case actionTypes.DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
        loading: false,
      };
    case actionTypes.UPDATE_SUCCESS:
      const updatedData = state.data.map((i) => {
        if (!i[action.payload.entity]) {
          return i;
        }
        const titles = ['quote', 'order', 'deliveryNote', 'exitVoucher', 'invoice'];
        if (i[action.payload.entity].id === action.payload.data._id) {
          let newRow = { ...i };
          titles.forEach((entity) => {
            if (newRow[entity]) {
              newRow[entity] = {
                ...i[entity],
                bill: action.payload.data?.bill?.name,
                date: action.payload.data.date,
                total: action.payload.data.total,
              };
            }
          });
          return newRow;
        }
        return i;
      });
      return { ...state, data: updatedData, dataById: action.payload.data, loading: false };

    case actionTypes.GET_DATA:
      let finalData = [];
      if (action.payload.page === 1) {
        finalData = action.payload.data;
      } else {
        finalData = [...state.data, ...action.payload.data];
        if (action.payload.data.length === 0) {
          return { ...state, data: finalData, loading: false };
        }
      }
      return { ...state, data: finalData, counter: action.payload.counter, loading: false };
    case actionTypes.GET_DATA_BY_ID:
      return { ...state, dataById: action.payload, loading: false };

    case actionTypes.RESET_ITEM_ID:
      return { ...state, loading: false, dataById: {} };

    case actionTypes.GENERATE_PDF_SUCCESS:
      return { ...state, pdf: action.payload, loading: false };
    case actionTypes.ITEM_LIST_SUCCESS:
      return {
        ...state,
        changedItems: action.payload.data,
        orderId: action.payload.id,
        loading: false,
      };
    case actionTypes.OPEN_MODAL:
      return { ...state, openModal: true, loading: false };
    case actionTypes.CLOSE_MODAL:
      return { ...state, openModal: false, loading: false };
    case actionTypes.CHANGE_PRICE_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.DELETE_SUCCESS:
      const canceledData = state.data.map((i) => {
        if (i[action.payload.entity]?.id === action.payload.id) {
          return { ...i, status: 'canceled' };
        }
        return i;
      });
      return { ...state, data: canceledData, loading: false };
    default:
      return state;
  }
};

export default saleReducer;
