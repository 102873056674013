import { SlidersOutlined } from '@ant-design/icons';

export const sortOptions = {
  sorter: true,
};

export const filterOptions = (setOpen, open, filter) => {
  return {
    filterDropdownOpen: open,
    onFilterDropdownVisibleChange: setOpen,
    filterIcon: (filtered) => {
      return (
        <span
          className={`ant-table-filter-trigger-container${filtered ? '-filtered' : ''} ${
            open ? ' open' : ''
          }`}
        >
          <SlidersOutlined />
        </span>
      );
    },
    filteredValue: filter || null,
    onHover: (e) => {
      setOpen(true);
    },
  };
};
