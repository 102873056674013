import { Checkbox, Form, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import useTranslations from '@/hooks/useTranslation';
import DEFAULT_ACCESS_DATA, {
  FULL_ACCESS,
  NO_ACCESS,
  areAllElementsChecked,
} from './defaultValues';

const isViewDisabled = (field, row) => {
  return field == 'read' && (row.create || row.delete || row.edit);
};

export const PermissionTable = ({ title, form, name, items, description }) => {
  const [data, setData] = useState(items);
  const { t } = useTranslations();
  const path = 'settingsPage.role.';

  const updateFields = (rowName, fieldUpdates) => {
    let newData = [...data];

    let row = newData.find((obj) => obj.name === rowName);

    if (row) {
      Object.keys(fieldUpdates).forEach((field) => {
        if (row.hasOwnProperty(field)) {
          row[field] = fieldUpdates[field];
        }
      });
    }

    // This is just done to keep the logic of the form that already exists
    form.setFieldsValue({ [name]: newData });

    setData(newData);
  };

  const column = (field, title, grantMinimalAccess = true) => {
    return {
      title: title,
      dataIndex: field,
      key: field + name,
      align: 'center',
      width: '8%',
      render: (value, row) => {
        return (
          <Checkbox
            disabled={isViewDisabled(field, row)}
            checked={value}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let fieldUpdates = { [field]: e.target.checked };
              if (grantMinimalAccess && e.target.checked) {
                fieldUpdates['read'] = true;
              }
              updateFields(row.name, fieldUpdates);
            }}
          />
        );
      },
    };
  };

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'contact' + name,
      align: 'left',
      width: '23%',
      onHeaderCell: (column) => {
        return {
          style: {
            minWidth: column.width,
            maxWidth: column.width,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        };
      },
      render: (value, row) => <>{t(path + 'add_role_fields.' + name + '.field.' + value)}</>,
    },
    {
      title: t(path + 'PermissionTable_columns.full_access'),
      dataIndex: 'full access',
      key: 'full-access' + name,
      align: 'center',
      width: '10%',
      render: (_, row) => {
        return (
          <Checkbox
            checked={areAllElementsChecked(row)}
            onChange={(e) => {
              if (e.target.checked) {
                updateFields(row.name, FULL_ACCESS);
                return;
              }
              updateFields(row.name, NO_ACCESS);
            }}
          />
        );
      },
    },
    column('create', t(path + 'PermissionTable_columns.create')),
    column('read', t(path + 'PermissionTable_columns.view'), false),
    column('edit', t(path + 'PermissionTable_columns.edit')),
    column('delete', t(path + 'PermissionTable_columns.delete')),
  ];

  useEffect(() => {
    if (!form.getFieldsValue()?.inventory) form.setFieldsValue(DEFAULT_ACCESS_DATA);
  }, [form]);

  return (
    <>
      <div className={'text fnt-s-18 fnt mrg20-0-20-0'}>{title}</div>
      <p style={{ color: '#999' }}>{description}</p>
      <Form.Item name={name} className={'roleTable max-w-100-400 '}>
        <>
          <Table
            tableLayout="fixed"
            className=".ant-table-thead ant-table-cell "
            style={{ width: '100%' }}
            bordered={true}
            pagination={false}
            columns={columns}
            dataSource={data}
          />
        </>
      </Form.Item>
    </>
  );
};
