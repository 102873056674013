import { translate } from '@/hooks/useTranslation';
import * as actionTypes from './types';
import { request } from '@/request';
import { convert, getData, openModal } from '@/redux/sales/actions';
import { notification } from 'antd';

export const erp = {
  openModal: () => async (dispatch) => {
    dispatch({
      type: actionTypes.OPEN_PURCHASE_MODAL,
    });
  },

  closeModal: () => async (dispatch) => {
    dispatch({
      type: actionTypes.CLOSE_PURCHASE_MODAL,
    });
  },
  resetState: () => async (dispatch) => {
    dispatch({
      type: actionTypes.RESET_STATE,
    });
  },

  changePurchasePrice: (id, body) => async (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_LOADING,
      keyState: 'updatePrice',
    });
    let data = {};
    data = await request.newUpdate({
      entity: 'item',
      action: 'change/purchase/price',
      id: id,
      jsonData: { items: body },
    });
    if (data.success === true) {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        keyState: 'updatePrice',
        payload: data.result,
      });
    } else {
      dispatch({
        type: actionTypes.REQUEST_FAILED,
        keyState: 'updatePrice',
        payload: null,
      });
    }
    dispatch(erp.closeModal());
  },

  getChangedItemList: (id) => async (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_LOADING,
      keyState: 'changedItems',
      payload: null,
    });
    let data = {};
    data = await request.newRead({
      entity: 'purchase',
      action: 'itemPriceDifference',
      id: id,
    });

    if (data.success === true) {
      dispatch({
        type: actionTypes.ITEM_LIST_SUCCESS,
        payload: { data: data.result, id },
      });
      if (data.result.length > 0) {
        dispatch(erp.openModal());
      }
    }
  },
  addPurchasePayment: (body) => async (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_LOADING,
      keyState: 'payment',
      payload: null,
    });
    let data = {};
    data = await request.create({
      entity: 'purchasePayment',
      jsonData: body,
    });
    if (data.success === true) {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        keyState: 'payment',
        payload: data.result,
      });
      dispatch(erp.read({ entity: 'purchase', id: data.result._id }));
    } else {
      dispatch({
        type: actionTypes.REQUEST_FAILED,
        keyState: 'payment',
        payload: null,
      });
    }
  },
  deletePurchasePayment: (id) => async (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_LOADING,
      keyState: 'payment',
      payload: null,
    });

    let data = {};
    data = await request.delete({
      entity: 'purchasePayment',
      id: id,
    });
    if (data.success === true) {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        keyState: 'payment',
        payload: data.result,
      });
      dispatch(erp.read({ entity: 'purchase', id: data.result._id }));
    } else {
      dispatch({
        type: actionTypes.REQUEST_FAILED,
        keyState: 'payment',
        payload: null,
      });
    }
  },

  resetAction:
    ({ actionType }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.RESET_ACTION,
        keyState: actionType,
        payload: null,
      });
    },

  resetCurrentPayload: () => async (dispatch) =>
    dispatch({ type: actionTypes.RESET_CURRENT_PAYLOAD }),
  currentItem:
    ({ data }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.CURRENT_ITEM,
        payload: { ...data },
      });
    },
  currentAction:
    ({ actionType, data }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.CURRENT_ACTION,
        keyState: actionType,
        payload: { ...data },
      });
    },
  listCategories:
    ({ entity, options = null }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'listCategories',
        payload: null,
      });

      // TODO : check inexistant getAll function
      // let data = await request.getAll({ entity });
      let reqOptions = options;
      if (options && Object.keys(options).length === 1 && options?.limit === 0) {
        // We are ignoring pagination until the items/category pagination issue is fixed
        reqOptions = { withItems: true, ignoreLimitPageSkip: true };
      }

      let data = await request.list({
        entity,
        options: reqOptions || { ignoreLimitPageSkip: true },
      });
      if (data.success === true) {
        const result = {
          items: data.result,
        };
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'listCategories',
          payload: result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'listCategories',
          payload: null,
        });
      }
    },
  listRoles: () => async (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_LOADING,
      keyState: 'listRoles',
      payload: null,
    });

    let data = await request.list({ entity: 'role' });

    if (data.success === true) {
      const result = {
        items: data.result,
      };
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        keyState: 'listRoles',
        payload: result,
      });
    } else {
      dispatch({
        type: actionTypes.REQUEST_FAILED,
        keyState: 'listRoles',
        payload: null,
      });
    }
  },
  listUsers:
    ({ entity }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'listUsers',
        payload: null,
      });

      let data = await request.listAdmin({ entity });
      if (data.success === true) {
        const result = {
          items: data.result,
        };
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'listUsers',
          payload: result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'listUsers',
          payload: null,
        });
      }
    },
  readUser:
    ({ entity, id }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'readUser',
        payload: null,
      });

      let data = await request.readAdmin({ entity, id });

      if (data.success === true) {
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: data.result,
        });
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'readUser',
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'readUser',
          payload: null,
        });
      }
    },
  createUser:
    ({ entity, jsonData }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'createUser',
        payload: null,
      });
      let data = await request.createAdmin({ entity, jsonData });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'createUser',
          payload: data.result,
        });

        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'createUser',
          payload: { message: data.message },
        });
      }
    },

  deleteUser:
    ({ entity, id }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'deleteUser',
        payload: null,
      });

      let data = await request.deleteAdmin({ entity, id });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'deleteUser',
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'deleteUser',
          payload: null,
        });
      }
    },
  list:
    ({ entity, options = { page: 1 } }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'list',
        payload: null,
      });
      let pageSize = 10;
      if (options.limit) {
        pageSize = options.limit;
      }

      let data = await request.list({ entity, options });
      if (data.success === true) {
        const result = {
          items: data.result,
          pagination: {
            current: parseInt(data.pagination.page, 10),
            pageSize,
            total: parseInt(data.pagination.count, 10),
          },
        };

        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'list',
          payload: result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'list',
          payload: null,
        });
      }
    },
  listEntity:
    ({ entity, options = { page: 1 } }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'list',
        payload: null,
      });

      let data = await request.listEntity({ entity, options });

      if (data.success === true) {
        const result = {
          items: data.result,
          pagination: {
            current: parseInt(data.pagination.page, 10),
            pageSize: 10,
            total: parseInt(data.pagination.count, 10),
          },
        };

        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'list',
          payload: result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'list',
          payload: null,
        });
      }
    },
  listById:
    ({ entity, id, options = { page: 1 } }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'listItemByCategory',
        payload: null,
      });

      let data = await request.listById({ entity, id, options });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'listItemByCategory',
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'listItemByCategory',
          payload: null,
        });
      }
    },
  create:
    ({ entity, jsonData, changePrice = false }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'create',
        payload: null,
      });
      let data = await request.create({ entity, jsonData });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'create',
          payload: data.result,
        });
        if (changePrice) {
          dispatch(erp.getChangedItemList(data.result._id));
        }
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: data.result,
        });

        if (entity === 'role') {
          dispatch(erp.listRoles());
        }
      } else {
        const errorMessage = translate(`serverErrors.${data?.message}`);
        if (!errorMessage?.includes('serverErrors.')) {
          notification.error({
            message: translate('common.reasonOfFailure'),
            description: errorMessage,
          });
        }
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'create',
          payload: null,
        });
      }
    },
  recordPayment:
    ({ entity, jsonData }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'recordPayment',
        payload: null,
      });

      let data = await request.create({ entity, jsonData });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'recordPayment',
          payload: data.result,
        });
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: data.result.invoice,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'recordPayment',
          payload: null,
        });
      }
    },
  read:
    ({ entity, id }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'read',
        payload: null,
      });
      let data = await request.read({ entity, id });
      if (data.success === true) {
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: data.result,
        });
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'read',
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'read',
          payload: null,
        });
      }
    },
  reassignRole:
    ({ entity, jsonData }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'updateUser',
        payload: null,
      });

      let data = await request.reassignRole({ entity, jsonData });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'updateUser',
          payload: true,
        });
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: true,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'updateUser',
          payload: null,
        });
      }
    },
  update:
    ({ entity, id, jsonData, changePrice = false }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'update',
        payload: null,
      });

      let data = await request.update({ entity, id, jsonData });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'update',
          payload: data.result,
        });
        if (changePrice) {
          dispatch(erp.getChangedItemList(data.result._id));
        }
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'update',
          payload: null,
        });
      }
    },

  delete:
    ({ entity, id }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'delete',
        payload: null,
      });

      let data = await request.delete({ entity, id });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'delete',
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'delete',
          payload: null,
        });
      }
    },

  deleteMany:
    ({ entity, ids }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'deleteMany',
        payload: null,
      });

      let data = await request.deleteMany({ entity, ids });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'deleteMany',
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'deleteMany',
          payload: null,
        });
      }
    },
  confirm:
    ({ entity, id }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'confirm',
        payload: null,
      });
      let data = {};
      if (entity === 'quote') {
        data = await request.put({
          entity: 'quote',
          action: 'approve',
          id: id,
        });
        dispatch(convert('quote', 'order', id));
      } else {
        data = await request.newPost({ entity, action: 'apply', id, jsonData: null });
      }
      if (data.success === true) {
        dispatch({
          type: actionTypes.CONFIRM_SUCCESS,
          payload: data.result,
        });
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'confirm',
          payload: null,
        });
      }
    },

  search:
    ({ entity, options }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'search',
        payload: null,
      });

      let data = await request.search({ entity, options });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'search',
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'search',
          payload: null,
        });
      }
    },
  changeCategoryParent:
    ({ entity, id, jsonData }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'update',
        payload: null,
      });
      let data = await request.changeParentCategory({ entity, id, jsonData });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'update',
          payload: data.result,
        });
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: data.result,
        });
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'update',
          payload: null,
        });
      }
    },
  setCategoryOrder:
    ({ entity, categories, nb_category }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING,
        keyState: 'listCategories',
        payload: null,
      });
      let data = await request.setCategoryOrder({ entity, categories });

      if (data.success === true) {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          keyState: 'update',
          payload: data.result,
        });
        dispatch(erp.listCategories({ entity }));
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED,
          keyState: 'listCategories',
          payload: null,
        });
      }
    },
};
