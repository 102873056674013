import * as actionTypes from './types';

export const initialState = {
  open: false,
  title: '',
  status: () => null,
  Avatar: () => null,
  Body: () => null,
  HeaderDate: () => null,
};

export function contextReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_MODAL:
      return {
        ...state,
        ...action,
        open: true,
      };
    case actionTypes.HIDE_MODAL:
      return {
        ...state,
        open: false,
      };
    case actionTypes.UPDATE_FORM:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      };
    default:
      return state;
  }
}
