/**
 * Enum for table filter options.
 *
 * @property DATE - Represents a date filter.
 * @property NUMBER - Represents a number filter.
 * @property STRING - Represents a string in document filter.
 * @property IN_OTHER_DOC - Represents a string in other document filter. When using this, you must specify the otherModelName and the localField will be automatically extracted from dataIndex.
 * @property DRAFT_STATUS - Represented as isDraft: boolean in the DB.
 * @property ENUM - Represents the enums on the DB. When using this, you must specify the options that must be shown an example is written in Invoices.jsx.
 * @example This is an example for the use of IN_OTHER_DOC if you want to look for the owners in the adjustments the localField will be = owner and the otherModelName will be = admin ( you must specify the otherModelName).
 */
export enum TableFilterOptions {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  IN_OTHER_DOC = 'IN_OTHER_DOC',
  DRAFT_STATUS = 'DRAFT_STATUS',
  ENUM = 'ENUM',
}
