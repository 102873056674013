import useTranslations from '@/hooks/useTranslation';
import { profileSetting } from '@/redux/auth/action/profile';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, InputNumber, Select, Modal, Popconfirm, Radio } from 'antd';
import { getCurrencyData } from '@/utils/currencies';
import { selectCompany } from '@/redux/auth/selectors';

export const enum TaxType {
  PERCENTILE = 'PERCENTILE',
  NUMBER = 'NUMBER',
}

export interface Tax {
  name: string;
  value: number;
  type: TaxType;
  apply_after_vat?: boolean;
  hidden: boolean;
}

const EMPTY_TAX: Tax = {
  name: '',
  type: TaxType.PERCENTILE,
  value: 1,
  apply_after_vat: false,
  hidden: false,
};

export const DEFAULT_OTHER_TAXES = ['fodec', 'stamp'];

const OtherTaxes = ({ taxes }: { taxes: Tax[] }) => {
  const { t } = useTranslations('settingsPage.taxes.other_taxes');
  const dispatch = useDispatch();
  const { currency } = useSelector(selectCompany);
  const [form] = Form.useForm();
  const [taxType, setTaxType] = useState(TaxType.PERCENTILE);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const deleteTax = (optToDelete: Tax) => {
    const newTaxesOptions = taxes.filter((option) => option !== optToDelete);
    dispatch(profileSetting.updateCompany({ taxes: newTaxesOptions }));
  };

  const handleOk = async () => {
    const areFieldsValid = await form.validateFields();
    if (!areFieldsValid) return;
    if (taxType === TaxType.PERCENTILE && form.getFieldValue('value') >= 100) return;
    form.submit();
    setIsModalVisible(false);
  };

  const onFinish = (values: Tax) => {
    const newTaxesOptions = [...taxes, values];
    dispatch(profileSetting.updateCompany({ taxes: newTaxesOptions }));
    form.resetFields();
  };

  return (
    <div className="max-form-width other_taxes">
      <h4>{t('your_taxes')}</h4>
      <div className="taxes_list">
        {(taxes || [])?.map((tax, index) => (
          <div key={index} className="tax">
            <h4>
              {DEFAULT_OTHER_TAXES.includes(tax?.name) ? t(`common.${tax?.name}`, true) : tax?.name}
            </h4>
            <div className="values">
              <div>
                <span>{`Type: `}</span>
                <span>{tax?.type}</span>
              </div>
              <div>
                <span>{`${t('common.value', true)} `}</span>
                <span>
                  {tax?.value}
                  {`${tax?.type === TaxType.PERCENTILE ? '%' : ` ${getCurrencyData(currency, 'symbol') || currency}`}`}
                </span>
              </div>
              <Popconfirm
                title={t('delete')}
                okText={t('common.confirm', true)}
                onConfirm={() => deleteTax(tax)}
                cancelText={t('common.cancel', true)}
              >
                <CloseCircleOutlined className="trash_icon" />
              </Popconfirm>
            </div>
            <span>{t(`${tax?.apply_after_vat ? 'applied_after_vat' : 'applied_before_vat'}`)}</span>
          </div>
        ))}
        <div className="tax cursor-pointer" onClick={() => setIsModalVisible(true)}>
          <PlusCircleOutlined style={{ fontSize: 20 }} />
        </div>
      </div>
      <Modal
        title={t('add_new')}
        width={390}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleOk}
        okText={t('common.confirm', true)}
        cancelText={t('common.cancel', true)}
      >
        <Form form={form} onFinish={onFinish} initialValues={EMPTY_TAX}>
          <Form.Item
            name="name"
            rules={[{ required: true, message: t('inputs.firm.city.required', true) }]}
          >
            <Input placeholder={t('tax_name')} />
          </Form.Item>
          {taxType === TaxType.PERCENTILE && (
            <Form.Item name="apply_after_vat">
              <Radio.Group
                className="flex justify items-center"
                name="apply_after_vat"
                defaultValue={false}
              >
                <Radio value={false}>{t('applied_before_vat')}</Radio>
                <Radio value={true}>{t('applied_after_vat')}</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item name="type">
            <Select
              placeholder="Select a tax type"
              onChange={(value) => {
                form.setFieldValue('type', value);
                setTaxType(value);
              }}
            >
              <Select.Option value={TaxType.PERCENTILE}>
                {t(`option_types.${TaxType.PERCENTILE}`)}
              </Select.Option>
              <Select.Option value={TaxType.NUMBER}>
                {t(`option_types.${TaxType.NUMBER}`)}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="value">
            <InputNumber min={1} max={taxType === TaxType.PERCENTILE ? 100 : undefined} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OtherTaxes;
