import { createSelector } from 'reselect';

const selectErp = (state) => state.erp;

export const selectCurrentItem = createSelector([selectErp], (erp) => erp.current);

export const selectListItems = createSelector([selectErp], (erp) => erp.list);
export const selectItemById = (itemId) =>
  createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectErp], (erp) => erp.create);
export const selectConfirmItem = createSelector([selectErp], (erp) => erp.confirm);

export const selectUpdatedItem = createSelector([selectErp], (erp) => erp.update);

export const selectRecordPaymentItem = createSelector([selectErp], (erp) => erp.recordPayment);

export const selectReadItem = createSelector([selectErp], (erp) => erp.read);

export const selectDeletedItem = createSelector([selectErp], (erp) => erp.delete);

export const selectDeletedManyItems = createSelector([selectErp], (erp) => erp.deleteMany);

export const selectSearchedItems = createSelector([selectErp], (erp) => erp.search);

export const selectCategories = createSelector([selectErp], (erp) => erp.listCategories);

export const selectUsers = createSelector([selectErp], (erp) => erp.listUsers);

export const selectDeletedUser = createSelector([selectErp], (erp) => erp.deleteUser);

export const selectUpdatedUser = createSelector([selectErp], (erp) => erp.updateUser);

export const selectRoles = createSelector([selectErp], (erp) => erp.listRoles);

export const selectCreatedUser = createSelector([selectErp], (erp) => erp.createUser);
export const selectItemsByCategory = createSelector([selectErp], (erp) => erp.listItemByCategory);
export const selectcategoryById = (itemId) =>
  createSelector(selectCategories, (list) => list.result.items.find((item) => item._id === itemId));

export const selectPurchaseChangedItems = createSelector(
  [selectErp],
  (erp) => erp.changedItems.result,
);
export const selectPurchaseOpenModal = createSelector([selectErp], (erp) => erp.openModal);
