const contextSelectors = (state) => {
  return {
    isSiderOpen: () => {
      return state.open;
    },
    getFormData: () => {
      return state.form;
    },
  };
};

export default contextSelectors;
