import React, { useCallback } from 'react';
import { Section } from '@/modules/SettingsModule/Section';
import { Divider, Form, Input } from 'antd';
import { UpdateLogo } from '@/modules/SettingsModule/tabs/Profile/updateLogo';
import { useEffect, useState } from 'react';
import { CompanyForm } from '@/modules/SettingsModule/tabs/Profile/companyForm';
import UnknownCompany from '@/style/images/company.webp';
import { Buttons } from '@/modules/SettingsModule/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompany } from '@/redux/auth/selectors';
import { profileSetting } from '@/redux/auth/action/profile';
import useTranslations from '@/hooks/useTranslation';
import { selectSaleData } from '@/redux/sales/selectors';

import CurrencySelect from '@/settings/CurrencySelect';
import PdfTemplates from './PdfTemplatesForm';
import { DEFAULT_PDF_TEMPLATE, PDFTemplates } from './PDF.Types';
import { isEmpty } from 'lodash';
import Loading from '@/components/Loading';

const CompanyProfile = () => {
  const [form] = Form.useForm();
  const company = useSelector(selectCompany);
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const sales = useSelector(selectSaleData);

  const [profileData, setProfileData] = useState({ company });
  const [companyImage, setCompanyImages] = useState();

  const areCommonFieldsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    const newFields = keys1.filter(
      (key) => JSON.stringify(obj1[key]) !== '' && !keys2.includes(key),
    );
    if (newFields.length > 0) return false;
    const commonKeys = keys1.filter((key) => keys2.includes(key));
    for (const key of commonKeys) {
      if (key !== 'image' && JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        return false;
      }
    }
    return true;
  };

  const setFormCurrency = useCallback(
    (value: string) => {
      form.setFieldValue(['company', 'currency'], value);
    },
    [form],
  );

  const setFormPDFTemplate = useCallback(
    (value: PDFTemplates) => {
      form.setFieldValue(['company', 'pdf_template'], value);
    },
    [form],
  );

  useEffect(() => {
    form.setFieldsValue(profileData);
    if (!isEmpty(profileData?.company?.image)) setCompanyImages(profileData?.company?.image);
  }, [profileData]);

  const updateCompany = (values) => {
    form.setFieldsValue(values.company);
    dispatch(profileSetting.updateCompany(values.company));
  };

  const updateCompanyImage = () => {
    if (companyImage !== company.image) {
      dispatch(profileSetting.updateImageCompany(companyImage));
    }
  };

  const handleSubmit = (values) => {
    if (!areCommonFieldsEqual(values.company, company)) {
      updateCompany(values);
    }
    updateCompanyImage();
  };

  const handleCancel = () => {
    form.setFieldsValue(profileData);
    setCompanyImages(profileData?.company?.image);
  };

  useEffect(() => {
    if (isEmpty(profileData?.company)) setProfileData({ company });
  }, [company]);

  return (
    <Loading isLoading={isEmpty(profileData?.company)}>
      <Form
        className={'page'}
        form={form}
        name="settingForm"
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <div className={'main'}>
          <Section
            title={t('settingsPage.company_logo.title')}
            description={t('settingsPage.company_logo.subtitle')}
            Body={() => (
              <UpdateLogo
                defaultImage={company.image}
                form={form}
                profile={false}
                name={['company', 'image']}
                image={companyImage || UnknownCompany}
                setImage={setCompanyImages}
                title={t('settingsPage.company_logo.upload_title')}
                description={t('settingsPage.company_logo.upload_subtitle')}
              />
            )}
          />
          <Divider />
          <Section
            title={t('settingsPage.company_information.title')}
            description={t('settingsPage.company_information.subtitle')}
            Body={CompanyForm}
          />
          <Divider />
          <Section
            title={t('settingsPage.company_information.currency_title')}
            description={t('settingsPage.company_information.currency_subtitle')}
            Body={() => (
              <Form.Item
                name={['company', 'currency']}
                label={t('settingsPage.company_information.currency')}
              >
                <CurrencySelect updateCurrency={setFormCurrency} />
              </Form.Item>
            )}
          />
          <Divider />
          <Section
            title={t('settingsPage.company_information.pdf_template')}
            description={t('settingsPage.company_information.pdf_subtitle')}
            Body={() => (
              <Form.Item
                name={['company', 'pdf_template']}
                label={t('settingsPage.company_information.pdf_title')}
              >
                <PdfTemplates
                  selectedTemplate={company?.pdf_template || DEFAULT_PDF_TEMPLATE}
                  updatePDFTemplate={setFormPDFTemplate}
                />
              </Form.Item>
            )}
          />
          <Divider />
          <Section
            title={t('settingsPage.company_information.invoice_counter_title')}
            description={t('settingsPage.company_information.invoice_counter_subtitle')}
            Body={() => (
              <Form.Item
                className="max-form-width"
                name={['company', 'counter_initialisation', 'value']}
                label={t('settingsPage.company_information.invoice_counter')}
              >
                <Input
                  type={'number'}
                  defaultValue={1}
                  disabled={sales.length > 0 || company?.counter_initialisation?.enabled === false}
                />
              </Form.Item>
            )}
          />
        </div>
        <Buttons onCancel={handleCancel} />
      </Form>
    </Loading>
  );
};

export default CompanyProfile;
