import { Section } from '@/modules/SettingsModule/Section';
import useTranslations from '@/hooks/useTranslation';

export default function Support() {
  const { t } = useTranslations();
  const path = 'settingsPage.support.';
  return (
    <div className={'page'}>
      <Section
        title={t(path + 'faq.title')}
        description={
          <a href={t(path + 'faq.link')} target="_blank" rel="noopener noreferrer">
            supplyzpro.com
          </a>
        }
      />
      <Section
        title={t(path + 'version.title')}
        description={
          <>
            <p>{t(path + 'version.description')}</p>
            <p>
              <a href="mailto:contact@supplyzpro.com">contact@supplyzpro.com</a>
            </p>{' '}
          </>
        }
      />
    </div>
  );
}
