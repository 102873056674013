import { useState } from 'react';
import { Button, Form, Input, InputNumber, Layout, Progress } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import FlagSelector, { TnPhonePattern } from '@components/FlagSelector';
import { request } from '@/request';
import useTranslations from '@/hooks/useTranslation';

export const PasswordRuleRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^a-zA-Z\\d]).{8,}$';
export const calculatePasswordStrength = (password: string) => {
  let strength = 0;
  if (password.match(/[a-z]/)) strength++;
  if (password.match(/[A-Z]/)) strength++;
  if (password.match(/\d/)) strength++;
  if (password.length >= 8) strength++;
  if (password.match(/[^a-zA-Z0-9]/g)) strength++;
  return (strength / 5) * 100;
};
const { Content } = Layout;

const SignUp1 = ({ next, userData, setUserData }) => {
  const { t } = useTranslations();
  const [countryCode, setCountryCode] = useState('TN');
  const [passwordStrength, setPasswordStrength] = useState(0);

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setPasswordStrength(calculatePasswordStrength(password));
  };

  const checkEmail = async (values): Promise<boolean> => {
    const data = await request.post({
      entity: 'checkEmail',
      data: {
        email: values.email,
      },
    });
    if (data.success) return true;
    return false;
  };

  const onFinish = async (values) => {
    const validation = await checkEmail(values);
    if (!validation) return;
    setUserData({
      ...userData,
      ...values,
      phoneNumberCountryCode: countryCode,
    });
    next();
  };

  return (
    <Content>
      <div className="site-layout-content">
        <Form
          name="signup_form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={t('inputs.personal.first_name.label')}
            name="name"
            className="signup-form"
            initialValue={userData['name']}
            rules={[
              {
                required: true,
                message: t('inputs.personal.first_name.required'),
              },
            ]}
          >
            <Input className="signup-input" size="middle" />
          </Form.Item>
          <Form.Item
            label={t('inputs.personal.last_name.label')}
            name="surname"
            className="signup-form"
            initialValue={userData['surname']}
            rules={[
              {
                required: true,
                message: t('inputs.personal.last_name.required'),
              },
            ]}
          >
            <Input size="middle" />
          </Form.Item>
          <Form.Item
            label={t('inputs.personal.email.label')}
            name="email"
            className="signup-form"
            initialValue={userData['email']}
            rules={[
              {
                required: true,
                message: t('inputs.personal.email.required'),
              },
              {
                type: 'email',
                message: t('inputs.personal.email.invalid'),
              },
            ]}
          >
            <Input autoComplete="email" size="middle" />
          </Form.Item>
          <Form.Item
            label={t('inputs.personal.password.label')}
            name="password"
            className="signup-form"
            initialValue={userData['password']}
            rules={[
              {
                required: true,
                message: t('inputs.personal.password.required'),
              },
              {
                pattern: new RegExp(PasswordRuleRegex),
                message: t('inputs.personal.password.invalid'),
              },
            ]}
          >
            <Input.Password
              size="middle"
              onChange={handlePasswordChange}
              iconRender={(visible) =>
                visible ? (
                  <EyeTwoTone style={{ padding: '0', opacity: 0.5 }} />
                ) : (
                  <EyeInvisibleOutlined style={{ opacity: 0.5 }} />
                )
              }
            />
          </Form.Item>
          <Progress percent={passwordStrength} />
          <Form.Item
            label={t('inputs.personal.confirm_password.label')}
            name="confirm-password"
            className="signup-form"
            initialValue={userData['confirm-password']}
            rules={[
              {
                required: true,
                message: t('inputs.personal.confirm_password.required'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('inputs.personal.confirm_password.invalid'));
                },
              }),
            ]}
          >
            <Input.Password
              size="middle"
              iconRender={(visible) =>
                visible ? (
                  <EyeTwoTone style={{ padding: '0', opacity: 0.5 }} />
                ) : (
                  <EyeInvisibleOutlined style={{ opacity: 0.5 }} />
                )
              }
            />
          </Form.Item>
          <Form.Item
            label={t('inputs.personal.phone_number.label')}
            className="signup-form"
            rules={[
              {
                required: true,
                message: t('inputs.personal.phone_number.required'),
              },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject();
                  }
                  if (countryCode == 'TN' && !TnPhonePattern.test(value)) {
                    return Promise.reject(t('inputs.personal.phone_number.tn'));
                  }
                  const length = value.toString().length;
                  if (countryCode != 'TN' && !(length >= 5 && length <= 10)) {
                    return Promise.reject(t('inputs.personal.phone_number.invalid'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            name="phoneNumber"
            initialValue={userData['phoneNumber']}
          >
            <InputNumber
              size="middle"
              type="number"
              placeholder={countryCode === 'TN' ? '22123123' : ''}
              addonBefore={<FlagSelector update={setCountryCode} />}
            />
          </Form.Item>

          <Form.Item>
            <Button className="button-signup1" type="primary" htmlType="submit">
              {t('common.next')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Content>
  );
};

export default SignUp1;
