import { Form, Input, InputNumber } from 'antd';
import { useState } from 'react';
import FlagSelector, { TnPhonePattern } from '@components/FlagSelector';
import useTranslations from '@/hooks/useTranslation';

export const CompanyForm = () => {
  const { t } = useTranslations();
  const [countryCode, setCountryCode] = useState('TN');

  return (
    <div className="max-form-width">
      <Form.Item
        name={['company', 'name']}
        label={t('settingsPage.company_information.company_name')}
        rules={[
          {
            required: true,
            message: 'Please enter the company name!',
          },
        ]}
        requiredMark={false}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['company', 'website']}
        label={t('settingsPage.company_information.website')}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['company', 'address', 'street']}
        label={t('settingsPage.company_information.address')}
        rules={[
          {
            required: true,
            message: 'Please enter the address!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['company', 'phoneNumber', 'number']}
        label={t('settingsPage.company_information.phone_number')}
        rules={[
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.reject(t('inputs.personal.phone_number.required'));
              }
              if (countryCode == 'TN' && !TnPhonePattern.test(value)) {
                return Promise.reject(t('inputs.personal.phone_number.tn'));
              }
              const length = value.toString().length;
              if (countryCode != 'TN' && !(length >= 5 && length <= 10)) {
                return Promise.reject(t('inputs.personal.phone_number.invalid'));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <InputNumber
          className="phoneInput"
          size="middle"
          type="number"
          addonBefore={<FlagSelector update={setCountryCode} />}
        />
      </Form.Item>
      <Form.Item name={['company', 'mf']} label={t('settingsPage.company_information.tax')}>
        <Input />
      </Form.Item>
      <Form.Item name={['company', 'terms']} label={t('settingsPage.company_information.terms')}>
        <Input.TextArea maxLength={200} rows={3} style={{ height: 60 }} />
      </Form.Item>
      <Form.Item name={['company', 'notes']} label={t('settingsPage.company_information.notes')}>
        <Input.TextArea maxLength={200} rows={3} style={{ height: 60 }} />
      </Form.Item>
      <Form.Item
        name={['company', 'bank_details']}
        label={t('settingsPage.company_information.bank_details')}
      >
        <Input.TextArea maxLength={200} rows={3} style={{ height: 60 }} />
      </Form.Item>
    </div>
  );
};
