import { DEFAULT_CURRENCY } from '@/components/PriceDisplay';
import { translate } from '@/hooks/useTranslation';
import dayjs from 'dayjs';
import { getCurrencyData } from './currencies';
import { TaxType } from '@/modules/SettingsModule/tabs/Taxes/OtherTaxes';

/*  
 To get nested object properties.
 admin = {
    location: {
        lat: 50,
        long: 9
    }
 }

 get(admin, 'location.lat')     // 50
 get(admin, 'location.foo.bar') // undefined
*/

export function get(obj, key) {
  return key.split('.').reduce(function (o, x) {
    return o === undefined || o === null ? o : o[x];
  }, obj);

  // key.split('.').reduce(function(o, x) {
  //     return (o === undefined || o === null) ? o : o[x];
  //   }, obj);
}

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  let a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (o !== null) {
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    } else {
      return;
    }
  }
  return o;
};

/*
 To check only if a property exists, without getting its value. It similer get function.
*/
export function has(obj, key) {
  return key.split('.').every(function (x) {
    if (typeof obj !== 'object' || obj === null || x in obj === false)
      /// !x in obj or  x in obj === true *** if you find any bug
      return false;
    obj = obj[x];
    return true;
  });
}

/*
 convert indexes to properties
*/
export function valueByString(obj, string, devider) {
  if (devider === undefined) {
    devider = '|';
  }
  return string
    .split(devider)
    .map(function (key) {
      return get(obj, key);
    })
    .join(' ');
}

/**
 Find item path in categories
 */
export function findItemPath(itemId, categories, currentPath = [{ breadcrumbName: '' }]) {
  for (const category of categories) {
    if (category._id === itemId) {
      return [...currentPath, { breadcrumbName: category.name }];
    }

    for (const subcategory of category.subcategories ?? []) {
      const newPath = [...currentPath, { breadcrumbName: category.name }];
      const path = findItemPath(itemId, [subcategory], newPath);
      if (path) {
        return path;
      }
    }
  }

  return null;
}

/*
 Submit multi-part form using ajax.
*/
export function toFormData(form) {
  let formData = new FormData();
  const elements = form.querySelectorAll('input, select, textarea');
  for (let i = 0; i < elements.length; ++i) {
    const element = elements[i];
    const name = element.name;

    if (name && element.dataset.disabled !== 'true') {
      if (element.type === 'file') {
        const file = element.files[0];
        formData.append(name, file);
      } else {
        const value = element.value;
        if (value && value.trim()) {
          formData.append(name, value);
        }
      }
    }
  }

  return formData;
}

/*
 Format Date to display admin
*/
export function formatDate(param) {
  if (!param) {
    return '';
  }
  const date = new Date(param);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear();
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  const fullDate = `${day}/${month}/${year}`;
  return param ? fullDate : '';
}

export const isDate = function ({ date, format = 'YYYY-MM-DD' }) {
  if (typeof date == 'boolean') return false;
  if (typeof date == 'number') return false;
  if (dayjs(date, format).isValid()) return true;
  return false;
};

/*
 Format Datetime to display admin
*/
export function formatDatetime(param) {
  if (!param) {
    return '';
  }

  let date = new Date(param);
  if (isNaN(date.getTime())) {
    return '';
  }
  let time = date.toISOString().split('T')[1].substring(0, 8);
  return formatDate(param) + ` ${translate('common.at')} ` + time;
}

/*
 Format Datetime to local time
*/
export function formatDateToLocaltime(param) {
  const time = new Date(param).toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  });
  return param ? formatDate(param) + ` ${translate('common.at')} ` + time : '';
}

/*
 Set object value in html
*/
export function bindValue(obj, parentElement) {
  parentElement.querySelectorAll('[data-property]').forEach((element) => {
    const type = element.dataset.type;
    let value = valueByString(obj, element.dataset.property);
    switch (type) {
      case 'date':
        value = formatDate(value);
        break;

      case 'datetime':
        value = formatDatetime(value);
        break;

      case 'currency':
        value = value.toFixed(3).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        break;

      default:
        break;
    }
    element.innerHTML = value;
  });
}

/**
 * this function is mainly used to format category data
 */
export function buildCategoryTree(categories) {
  const categoryMap = {};
  categories.forEach((category) => {
    category.subcategories = [];
    categoryMap[category._id] = category;
  });
  const categoryTree = [];
  categories.forEach((category) => {
    if (category.parentCategory === '' || !categoryMap[category.parentCategory]) {
      categoryTree.push(category);
    } else {
      categoryMap[category.parentCategory].subcategories.push(category);
    }
  });
  return categoryTree;
}

export function changeTreeField(arrayOfObjects) {
  const updatedArray = arrayOfObjects.map((obj) => {
    const updatedObj = { ...obj };

    if (updatedObj.hasOwnProperty('subcategories')) {
      if (updatedObj.subcategories.length > 0) {
        updatedObj.children = changeTreeField(updatedObj.subcategories);
      }

      delete updatedObj.subcategories;
    }

    return updatedObj;
  });

  return updatedArray;
}

export function getNestedProperty(obj, path) {
  return path
    .split('.')
    .reduce((acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : undefined), obj);
}

/**
 * this function is mainly used  to format numbers with specified decimal places
 */
export const formatNumber = (value, decimalPlaces = 2) => {
  return value !== undefined ? Number(value).toFixed(decimalPlaces) : null;
};

/**
 * Finds the minimum value of a specified key in an array of objects.
 */
export function findMinValue(objectsArray, key) {
  if (!Array.isArray(objectsArray) || objectsArray.length === 0) {
    return undefined; // Handle empty or invalid input
  }

  let minValue = objectsArray[0][key];

  for (let i = 1; i < objectsArray.length; i++) {
    const currentValue = objectsArray[i][key];

    if (currentValue < minValue) {
      minValue = currentValue;
    }
  }

  return minValue;
}

/**
 * Finds the maximum value of a specified key in an array of objects.
 */
export function findMaxValue(objectsArray, key) {
  if (!Array.isArray(objectsArray) || objectsArray.length === 0) {
    return undefined; // Handle empty or invalid input
  }

  let maxValue = objectsArray[0][key];

  for (let i = 1; i < objectsArray.length; i++) {
    const currentValue = objectsArray[i][key];

    if (currentValue > maxValue) {
      maxValue = currentValue;
    }
  }

  return maxValue;
}

export function fixDateArrayObjet(objectsArray) {
  if (!Array.isArray(objectsArray) || objectsArray.length === 0) {
    return objectsArray; // Return the original array for empty or invalid input
  }

  const newObjectsArray = objectsArray.map((obj) => {
    const newDate = formatDate(obj?.date);
    return { ...obj, date: newDate };
  });

  return newObjectsArray;
}

export const formatPrice = (price, companyCurrency = DEFAULT_CURRENCY) => {
  if (price === '' || price === undefined || price === null) return ['', '', ''];
  if (price == 0) return [0, 0];
  const fractionDigit = getCurrencyData(companyCurrency, 'fractionDigit') ?? 3;
  let formattedPrice = parseFloat(price || '');
  const fullPriceFormatted = formattedPrice.toString().replace('.', ',');
  formattedPrice =
    Math.round(formattedPrice * Math.pow(10, fractionDigit)) / Math.pow(10, fractionDigit);
  let priceString = formattedPrice.toString().replace('.', ',');
  const decimalIndex = priceString.indexOf(',');
  if (decimalIndex !== -1) {
    const decimalPart = priceString.slice(decimalIndex + 1);
    if (decimalPart.length < fractionDigit) {
      priceString += '0'.repeat(fractionDigit - decimalPart.length);
    }
  }
  return [
    `${priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${getCurrencyData(companyCurrency, 'symbol') || companyCurrency}`, // Formatted price with currency
    fullPriceFormatted.replace(/\B(?=(\d{3})+(?!\d))/g, ' '), // Full price formatted
    priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ' '), // Formatted price without currency
  ];
};

export const formatFloat = (value) => {
  if (value === '' || value === undefined || value === null) return '';
  if (Number.isInteger(value)) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  let valueStr = value.toString();
  while (valueStr[valueStr.length - 1] === '0') {
    valueStr = valueStr.slice(0, -1);
  }
  if (valueStr[valueStr.length - 1] === '.') {
    valueStr = valueStr.slice(0, -1);
  }
  return valueStr.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const removeBeforeFirstDot = (input) => {
  const [, ...rest] = input.split('.');
  return rest.join('.');
};

export const getBeforeFirstDot = (input) => {
  const [firstPart] = input.split('.');
  return firstPart;
};

export const transformFilterData = (result, field = '', name = 'name') => {
  return (result || [])?.map((option) => {
    const value = option?.[field] ? option?.[field]?.[name] : '';
    let newOption = { ...option };
    newOption[`${field}.${name}`] = value;
    return newOption;
  });
};

export const transformPhoneNumberData = (result) => {
  return (result || [])?.map((option) => {
    const phoneNumber = option?.phoneNumber?.number ? option?.phoneNumber?.number : '';
    return { ...option, phoneNumber };
  });
};

export const formatLongNumbers = (value) => {
  if (value > 99999) {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    }
  }
  return value.toString();
};

export const calculateTax = (tax, totalHT, totalWithVAT) => {
  return tax.type === TaxType.NUMBER
    ? tax.value
    : !tax?.apply_after_vat
      ? totalHT * (tax.value / 100)
      : totalWithVAT * (tax.value / 100);
};

export const fieldRequiredMessage = (t, field) => {
  return `${t('common.field_required', true, { field: t(field)?.toString()?.toLowerCase() })}`;
};
