export const FAILED_REQUEST = 'AUTH_FAILED_REQUEST';
export const LOADING_REQUEST = 'AUTH_LOADING_REQUEST';

export const LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';

export const LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const CURRENT_SUCCESS = 'CURRENT_SUCCESS';

export const SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const GET_ADMIN_POSITION = 'AUTH_GET_ADMIN_POSITION';

export const GET_COMPANY_INDUSTRY = 'AUTH_GET_COMPANY_INDUSTRY';
export const GET_STOCK_TYPE = 'AUTH_GET_STOCK_TYPE';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_PROFILE_SUCCESS';
export const UPDATE_PROFILE_SUCCESS = 'AUTH_UPDATE_PROFILE_SUCCESS';
export const COMPLETE_PROFILE_SUCCESS = 'AUTH_COMPLETE_PROFILE_SUCCESS';
export const UPDATE_COMPANY_SUCCESS = 'AUTH_UPDATE_COMPANY_SUCCESS';
export const UPDATE_IMAGE_PROFILE_SUCCESS = 'AUTH_UPDATE_IMAGE_PROFILE_SUCCESS';
export const UPDATE_IMAGE_COMPANY_SUCCESS = 'AUTH_UPDATE_IMAGE_COMPANY_SUCCESS';

export const UPDATE_COMPANY = 'AUTH_UPDATE_COMPANY';
export const FAILED_LOGIN_SUB = 'FAILED_LOGIN_SUB';
