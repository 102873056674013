import useTranslations from '@/hooks/useTranslation';
import { Subscription } from '@/types';
import { formatDate } from '@/utils/helpers';
import { Typography } from 'antd';

const { Text } = Typography;
type CurrentSubDetailsProps = {
  subscription: Subscription;
};

const CurrentSubDetails = ({
  subscription: { end_date, max_number_of_users, remainingDaysForSubExpiration },
}: CurrentSubDetailsProps) => {
  const { t } = useTranslations('settingsPage.subscription');
  return (
    <div className="max-form-width">
      <div>
        <h4>{`${t('current_sub_details')}:`}</h4>
        <h5>{`${t('max_number_of_users')}: ${max_number_of_users} ${t('users')}`}</h5>
        <h5>{`${t('common.end_date', true)}: ${formatDate(end_date)}`}</h5>
        <h5>
          {`${t('sub_remaining_days')}: `}
          {remainingDaysForSubExpiration}
          {` ${t('common.day', true)}${remainingDaysForSubExpiration > 1 ? 's' : ''}`}
        </h5>
      </div>
    </div>
  );
};

export default CurrentSubDetails;
