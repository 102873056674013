import { Image, Spin } from 'antd';
import supplyzloader from '../../style/images/supplyzLoader.gif';

export default function Loading({ isLoading, children }) {
  const Img = <Image src={supplyzloader} alt="supplyzpro" />;

  return (
    <Spin indicator={Img} spinning={isLoading}>
      {children}
    </Spin>
  );
}
