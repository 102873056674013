import ErpPanel from '@components/ErpPanel';
import Permissions from './form';
import Read from './read';
import { ErpLayout } from '@/layout';
import { RoleModel } from '@/models/settings/RoleModel';

export default function RoleModule({ config }) {
  return (
    <ErpLayout>
      <ErpPanel<RoleModel>
        config={config}
        CreateForm={Permissions}
        UpdateForm={Permissions}
        ReadItem={Read}
        itemFromForm={itemFromForm}
        formFromItem={formFromItem}
      />
    </ErpLayout>
  );
}
const itemFromForm = (formData) => {
  return new RoleModel(formData);
};

const formFromItem = (role: RoleModel) => {
  return RoleModel.getForm(role);
};
