export const FAILED_REQUEST = 'SALE_FAILED_REQUEST';
export const LOADING_REQUEST = 'SALE_LOADING_REQUEST';
export const RESET_ITEM_ID = 'RESET_ITEM_ID';
export const CHANGE_STATUS_SUCCESS = 'SALE_CONFIRM_STATUS_SUCCESS';

export const CREATE_ENTITY_SUCCESS = 'SALE_CREATE_ENTITY_SUCCESS';

export const CREATE_SALE_RETURN_SUCCESS = 'CREATE_SALE_RETURN_SUCCESS';

export const PAYMENT_SUCCESS = 'INVOICE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_INVOICE_PAYMENT_SUCCESS';
export const CONVERSION_SUCCESS = 'GOOGLE_CONVERSION_SUCCESS';

export const UPDATE_SUCCESS = 'SALE_UPDATE_SUCCESS';
export const GENERATE_PDF_SUCCESS = 'SALE_GENERATE_PDF_SUCCESS';
export const GET_DATA = 'SALE_GET_DATA';
export const GET_DATA_BY_ID = 'SALE_GET_DATA_BY_ID';
export const ITEM_LIST_SUCCESS = 'SALE_ITEM_LIST_SUCCESS';
export const OPEN_MODAL = 'SALE_OPEN_MODAL';
export const CLOSE_MODAL = 'SALE_CLOSE_MODAL';
export const CHANGE_PRICE_SUCCESS = 'SALE_CHANGE_PRICE_SUCCESS';

export const DELETE_SUCCESS = 'SALE_DELETE_SUCCESS';
