import { Section } from '@/modules/SettingsModule/Section';
import { PermissionsForm } from './permissionsForm';
import { PermissionTable } from '@/modules/SettingsModule/tabs/Permissions/form/permissionTable';
import DEFAULT_ACCESS_DATA from './defaultValues';
import { useEffect } from 'react';
import useTranslations from '@/hooks/useTranslation';
import { SwitchField } from '@/modules/SettingsModule/tabs/Permissions/form/SwitchField';
import { useSelector } from 'react-redux';
import { selectCurrentItem } from '@/redux/erp/selectors';

export default function Permissions({ form, update }) {
  const { t } = useTranslations('settingsPage.role');
  const { result: selectedRole } = useSelector(selectCurrentItem);

  useEffect(() => {
    if (!update) {
      form.setFieldsValue(DEFAULT_ACCESS_DATA);
      return;
    }
    // TODO: Add loader and investigate form validation
    form.setFieldsValue(selectedRole);
  }, [form, update]);

  return (
    <div className={'page'} style={{ height: 'calc(100vh - 300px)', overflowY: 'initial' }}>
      <Section
        title={t('title')}
        description={t('description')}
        Body={() => (
          <>
            <PermissionsForm />
            <SwitchField
              name="bi"
              form={form}
              title={t('add_role_fields.BI.title')}
              description={t('add_role_fields.BI.description')}
            />
            <SwitchField
              name="settings"
              form={form}
              title={t('add_role_fields.settings.title')}
              description={t('add_role_fields.settings.description')}
            />
            <PermissionTable
              title={t('add_role_fields.inventory.title')}
              form={form}
              items={form.getFieldValue('inventory')}
              name={'inventory'}
              description={t('add_role_fields.inventory.description')}
            />
            <PermissionTable
              title={t('add_role_fields.sale.title')}
              form={form}
              items={form.getFieldValue('sale')}
              name={'sale'}
              description={t('add_role_fields.sale.description')}
            />
            <PermissionTable
              title={t('add_role_fields.purchase.title')}
              form={form}
              items={form.getFieldValue('purchase')}
              name={'purchase'}
              description={t('add_role_fields.purchase.description')}
            />
          </>
        )}
      />
    </div>
  );
}
