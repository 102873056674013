import axios from 'axios';
import { API_BASE_URL } from '@/config/serverApiConfig';
import errorHandler from './errorHandler';
import successHandler from './successHandler';
import { endpoints } from '@/request/api';
import { DEFAULT_LANGUAGE } from '@/hooks/useTranslation';
import qs from 'qs';
import { isEmpty } from 'lodash';
import { DEFAULT_HEADERS } from '@/types/constants';
import { queryClient } from '@/context/appContext';
import { QueryKeys } from '@/types';

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.withCredentials = true;

const request = {
  create: async ({ entity, jsonData, notify = true }) => {
    try {
      const response = await axios.post(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/create',

        jsonData,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: notify,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  read: async ({ entity, id }) => {
    try {
      const response = await axios.get(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/read/' + id,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  newRead: async ({ entity, action, id }) => {
    try {
      const response = await axios.get(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/' + action + '/' + id,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  update: async ({ entity, id = '', jsonData, notify = true }) => {
    try {
      const response = await axios.patch(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/update/' + id,
        jsonData,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: notify,
        notifyOnFailed: true,
      });
      queryClient.invalidateQueries([QueryKeys.Client, id]);
      //Here I am forcing refetching until we switch completely to react query and get rid of using this requests and redux
      queryClient.refetchQueries([QueryKeys.Client, id]);
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  update2: async ({ entity, jsonData, id, notify = true }) => {
    try {
      const response = await axios.post(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/' + id,
        jsonData,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: notify,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  newUpdate: async ({ entity, action, id, jsonData }) => {
    try {
      const response = await axios.put(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/' + action + '/' + id,
        jsonData,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  newPost: async ({ entity, action, id, jsonData = {} }) => {
    try {
      const response = await axios.post(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/' + action + '/' + id,
        jsonData,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  delete: async ({ entity, id }) => {
    try {
      const response = await axios.delete(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/delete/' + id,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  deleteMany: async ({ entity, ids }) => {
    try {
      const response = await axios.patch(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/bulk/delete/',
        { id: ids },
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  filter: async ({ entity, options = {} }) => {
    try {
      let filter = options.filter ? 'filter=' + options.filter : '';
      let equal = options.equal ? '&equal=' + options.equal : '';
      let query = `?${filter}${equal}`;

      const response = await axios.get(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/filter' + query,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  listEntity: async ({ entity, options = {} }) => {
    try {
      let query = '?';
      for (var key in options) {
        query += key + '=' + options[key] + '&';
      }
      query = query.slice(0, -1);

      //const response = await axios.get(service + '/' + entity + '/list' + query);
      const response = await axios.get(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/list/top',
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  search: async ({ entity, options = {} }) => {
    try {
      let query = '?';
      for (var key in options) {
        query += key + '=' + options[key] + '&';
      }
      query = query.slice(0, -1);
      // headersInstance.cancelToken = source.token;
      const response = await axios.get(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/search' + query,
        DEFAULT_HEADERS,
      );

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  listById: async ({ entity, id, options = {} }) => {
    try {
      let query = '?';
      for (var key in options) {
        query += key + '=' + options[key] + '&';
      }
      query = query.slice(0, -1);

      //const response = await axios.get(service + '/' + entity + '/list' + query);
      const response = await axios.get(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/list/grouped/' + id,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  list: async ({ entity, options = {} }) => {
    try {
      let query = qs.stringify(options);
      //const response = await axios.get(service + '/' + entity + '/list' + query);
      const response = await axios.get(
        `${endpoints[entity].service}/${endpoints[entity].entity}/list/user?${query}`,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  listAdmin: async ({ entity }) => {
    try {
      const response = await axios.get(
        endpoints[entity].service + '/admin/' + endpoints[entity].entity + '/list/user',
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  reassignRole: async ({ entity, jsonData }) => {
    try {
      const response = await axios.post(
        endpoints[entity].service + '/admin/' + endpoints[entity].entity + '/role/reassign',
        jsonData,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  readAdmin: async ({ entity, id }) => {
    try {
      const response = await axios.get(
        endpoints[entity].service + '/admin/' + endpoints[entity].entity + '/read/' + id,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  deleteAdmin: async ({ entity, id }) => {
    try {
      const response = await axios.delete(
        endpoints[entity].service + '/admin/' + endpoints[entity].entity + '/delete/' + id,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  createAdmin: async ({ entity, jsonData }) => {
    try {
      const response = await axios.post(
        endpoints[entity].service + '/admin/' + endpoints[entity].entity + '/create',
        jsonData,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  post: async ({ entity, data, options = {} }) => {
    try {
      const headers = {
        'Accept-Language': localStorage.getItem('Language') || DEFAULT_LANGUAGE,
      };

      if (!(data instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
      }

      const response = await axios.post(
        endpoints[entity].service + '/' + endpoints[entity].entity,
        data,
        { headers },
      );

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  get: async ({ entity, query = {} }) => {
    try {
      const response = await axios.get(endpoints[entity].service + '/' + endpoints[entity].entity, {
        params: query,
        headers: {
          'Accept-Language': localStorage.getItem('Language') || DEFAULT_LANGUAGE,
        },
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  patch: async ({ entity, action = null, jsonData }) => {
    try {
      const response = await axios.patch(
        endpoints[entity].service + '/' + endpoints[entity].entity + action,
        jsonData,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  put: async ({ entity, action, id }) => {
    try {
      const response = await axios.put(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/' + action + '/' + id,
        undefined,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  pdfRequest: async (id, type) => {
    try {
      const response = await axios.get(
        endpoints[type].service + '/' + endpoints[type].entity + '/pdf/' + id,
        {
          responseType: 'arraybuffer',
          headers: { 'Accept-Language': localStorage.getItem('Language') || 'en' },
        },
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true,
        validateSuccess: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  setCategoryOrder: async ({ entity, categories }) => {
    const changeParents = async (categories, jsonData = { category: '' }) => {
      const results = [];
      const promises = categories.map(async (category) => {
        const response = await axios.patch(
          endpoints[entity].service +
            '/' +
            endpoints[entity].entity +
            '/change/parent/' +
            category._id,
          jsonData,
          {
            headers: {
              'Accept-Language': localStorage.getItem('Language') || DEFAULT_LANGUAGE,
            },
          },
        );
        results.push(response);

        if (category.subcategories.length) {
          await changeParents(category.subcategories, { category: category._id });
        }
      });

      // Wait for all promises to settle
      await Promise.all(promises);

      return {
        results,
        data: { success: true, result: results, message: 'Order of Categories updated' },
      };
    };
    try {
      const response = await changeParents(categories);
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  source: () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return source;
  },
  static: async ({ entity }) => {
    try {
      const response = await axios.get(
        endpoints[entity].service + '/static/' + endpoints[entity].entity,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  check: async ({ entity, checked, field }) => {
    try {
      const response = await axios.post(
        `${endpoints[entity].service}/${endpoints[entity].entity}/${field}/${
          checked ? 'enable' : 'disable'
        }`,
        undefined,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  // auth
  login: async ({ loginData }) => {
    try {
      const response = await axios.post(
        endpoints['login'].service + '/' + endpoints['login'].entity + new Date().getTime(),
        loginData,
        {
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.getItem('Language') || DEFAULT_LANGUAGE,
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
        },
      );
      // This is done for better UX if a user opens from a new browser on
      // first login the language is kept as he wants it
      const userLanguage = response?.data?.result?.admin?.language;
      if (!isEmpty(userLanguage)) {
        localStorage.setItem('Language', userLanguage);
        window.location.reload();
      }
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  signup: async (signupData) => {
    try {
      const response = await axios.post(
        endpoints['signup'].service + '/' + endpoints['signup'].entity,
        signupData,
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  current: async () => {
    try {
      const response = await axios.get(
        endpoints['current'].service + '/' + endpoints['current'].entity + '/me',
        DEFAULT_HEADERS,
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        return { expired: true };
      }
      errorHandler(error);
    }
  },
  logout: async () => {
    try {
      await axios.post(
        endpoints['logout'].service +
          '/' +
          endpoints['logout'].entity +
          `?timestamp=${new Date().getTime()}`,
        undefined,
        DEFAULT_HEADERS,
      );
    } catch (error) {
      return errorHandler(error);
    }
  },

  getAdminPosition: async () => {
    try {
      const response = await axios({
        method: 'GET',
        url:
          API_BASE_URL +
          endpoints['adminPosition'].service +
          '/' +
          endpoints['adminPosition'].entity,
        headers: {
          'Accept-Language': localStorage.getItem('Language') || DEFAULT_LANGUAGE,
        },
      });

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  getCompanyIndustry: async () => {
    try {
      const response = await axios({
        method: 'GET',
        url:
          API_BASE_URL +
          endpoints['getCompanyIndustry'].service +
          '/' +
          endpoints['getCompanyIndustry'].entity,
        headers: {
          'Accept-Language': localStorage.getItem('Language') || DEFAULT_LANGUAGE,
        },
      });

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  getStockType: async () => {
    try {
      const response = await axios({
        method: 'GET',
        url:
          API_BASE_URL + endpoints['getStockType'].service + '/' + endpoints['getStockType'].entity,
        headers: {
          'Accept-Language': localStorage.getItem('Language') || DEFAULT_LANGUAGE,
        },
      });

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  listField: async ({
    entity,
    field,
    page = 1,
    limit = 5,
    filters = '',
    fieldsToSelect = [],
    localField = null,
    otherModelName = null,
  }) => {
    try {
      const url =
        isEmpty(otherModelName) || isEmpty(localField)
          ? `${endpoints[entity].service}/${endpoints[entity].entity}/list/filter`
          : `${endpoints[entity].service}/${endpoints[entity].entity}/list/filter${
              '/' + localField + '/' + otherModelName
            }`;
      const response = await axios.get(url, {
        headers: {
          'Accept-Language': localStorage.getItem('Language') || DEFAULT_LANGUAGE,
        },
        params: {
          field,
          page,
          limit,
          filters,
          fieldsToSelect,
        },
      });
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  rangeField: async ({ entity, field }) => {
    try {
      const response = await axios.get(
        `${endpoints[entity].service}/${endpoints[entity].entity}/fieldRange`,
        {
          headers: {
            'Accept-Language': localStorage.getItem('Language') || DEFAULT_LANGUAGE,
          },
          params: {
            field,
          },
        },
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  analytics: async ({ entity }) => {
    try {
      const response = await axios.get(
        endpoints[entity].service + '/' + endpoints[entity].entity + '/analytics',
      );
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  notifyClient: async ({ entity, id, payload }) => {
    try {
      const response = await axios.patch(
        endpoints['notifyClient'].service +
          `/${entity}/` +
          endpoints['notifyClient'].entity +
          `/${id}`,
        payload,
      );
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
};
export default request;
