export const RESET_STATE = 'ERP_RESET_STATE';
export const CURRENT_ITEM = 'ERP_CURRENT_ITEM';
export const RESET_CURRENT_PAYLOAD = 'RESET_CURRENT_PAYLOAD';
export const REQUEST_LOADING = 'ERP_REQUEST_LOADING';
export const REQUEST_SUCCESS = 'ERP_REQUEST_SUCCESS';
export const REQUEST_FAILED = 'ERP_REQUEST_FAILED';
export const CURRENT_ACTION = 'ERP_CURRENT_ACTION';
export const RESET_ACTION = 'ERP_RESET_ACTION';
export const DELETE_ACTION = 'ERP_DELETE_ACTION';
export const OPEN_PURCHASE_MODAL = 'PURCHASE_OPEN_MODAL';
export const CLOSE_PURCHASE_MODAL = 'PURCHASE_CLOSE_MODAL';
export const CHANGE_PRICE_SUCCESS = 'PURCHASE_CHANGE_PRICE_SUCCESS';
export const ITEM_LIST_SUCCESS = 'CHANGED_ITEM_List_SUCCESS';
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';
