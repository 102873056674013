import { Section } from '@/modules/SettingsModule/Section';
import useTranslations from '@/hooks/useTranslation';
import Privacy_Policy_en from '../../../../style/files/privacyPolicy/Privacy_Policy_for_Supplyz_inventory_EN.pdf';
import Privacy_Policy_fr from '../../../../style/files/privacyPolicy/Privacy_Policy_for_Supplyz_inventory_FR.pdf';
import terms_of_use_en from '../../../../style/files/termsOfUse/CONDITIONS_Dutlisation_SupplyzPro_EN.pdf';
import terms_of_use_fr from '../../../../style/files/termsOfUse/CONDITIONS_Dutlisation_SupplyzPro_FR.pdf';

export default function Security() {
  const { t } = useTranslations();
  const path = 'settingsPage.security.';

  const handleDownload = (url) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        const fileNameFromUrl = url.substring(url.lastIndexOf('/') + 1);

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', fileNameFromUrl);
        link.click();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was a problem with the download:', error);
      });
  };

  return (
    <div className={'page'}>
      <Section
        title={t(path + 'terms_of_use.title')}
        description={
          <a
            onClick={() =>
              handleDownload(
                localStorage.getItem('Language') === 'fr' ? terms_of_use_fr : terms_of_use_en,
              )
            }
          >
            {t(path + 'description')}
          </a>
        }
      />
      <Section
        title={t(path + 'privacy.title')}
        description={
          <a
            onClick={() =>
              handleDownload(
                localStorage.getItem('Language') === 'fr' ? Privacy_Policy_fr : Privacy_Policy_en,
              )
            }
          >
            {t(path + 'description')}
          </a>
        }
      />
    </div>
  );
}
