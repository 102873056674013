export const Section = ({ Body = () => null, title, description }) => {
  return (
    <div style={{ whiteSpace: 'pre-line' }} className="section">
      <div className="section__header">
        <h2 className="section__title">{title}</h2>
        <p className="section__description">{description}</p>
      </div>
      <div className="section__body">
        <Body />
      </div>
    </div>
  );
};
