import { Button, Modal } from 'antd';
import { usePopupContext } from '@/context/popup';

export default function PopupForm() {
  const { state, popupContextAction } = usePopupContext();
  const { Body } = state;
  const handleCancel = () => {
    popupContextAction.popup.hide();
  };

  return (
    <Modal
      title={state.title}
      visible={state.open}
      onCancel={handleCancel}
      footer={[
        <Button key="close" onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >
      <Body />
    </Modal>
  );
}
