import { createContext, useContext, useMemo, useReducer } from 'react';
import { contextReducer, initialState } from './reducer';
import contextActions from './actions';
import contextSelectors from './selectors';

const SiderContext = createContext();

function SiderContextProvider({ children }) {
  const [state, dispatch] = useReducer(contextReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  return <SiderContext.Provider value={value}>{children}</SiderContext.Provider>;
}

function useSiderContext() {
  const context = useContext(SiderContext);
  if (context === undefined) {
    throw new Error('useSiderContext must be used within a SiderContextProvider');
  }
  const [state, dispatch] = context;
  const siderContextAction = contextActions(dispatch);
  const siderContextSelector = contextSelectors(state);
  return { state, siderContextAction, siderContextSelector };
}

export { SiderContextProvider, useSiderContext };
