import RoleModule from '@/modules/SettingsModule/tabs/Permissions/module';
import useTranslations from '@/hooks/useTranslation';

export default function Role() {
  const { t } = useTranslations('settingsPage.role');
  const entity = 'role';
  const searchConfig = {
    displayLabels: ['name', 'surname'],
    searchFields: 'name,surname,birthday',
  };
  const entityDisplayLabels = ['name'];
  const dataTableColumns = [
    {
      title: t('columns.name'),
      dataIndex: 'name',
      type: 'string',
    },
    {
      title: t('columns.description'),
      dataIndex: 'description',
      type: 'string',
    },
  ];
  const ELEMENTS = [t('columns.name'), t('columns.description')];
  const PANEL_TITLE = t('name');
  const dataTableTitle = t('roleTable_title');
  const ADD_NEW_ENTITY = t('addButton');
  const DATATABLE_TITLE = t('title');
  const ENTITY_NAME = 'role'; //to check
  const CREATE_ENTITY = t('add_title');
  const UPDATE_ENTITY = t('update_title');
  const submitButton = t('addButton');

  const config = {
    ELEMENTS,
    entity,
    PANEL_TITLE,
    dataTableTitle,
    ENTITY_NAME,
    CREATE_ENTITY,
    ADD_NEW_ENTITY,
    UPDATE_ENTITY,
    DATATABLE_TITLE,
    dataTableColumns,
    searchConfig,
    entityDisplayLabels,
    submitButton,
    small: true,
  };
  return <RoleModule config={config} />;
}
