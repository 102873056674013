import { Button } from 'antd';
import useTranslations from '@/hooks/useTranslation';

interface ButtonsProps {
  onCancel?: () => void;
}

export const Buttons: React.FC<ButtonsProps> = ({ onCancel }) => {
  const { t } = useTranslations();
  return (
    <div className={'buttons'}>
      <Button onClick={onCancel} className={'mrg-r-15'} danger>
        {t('settingsPage.buttons.cancel')}
      </Button>
      <Button htmlType={'submit'} type={'primary'}>
        {t('settingsPage.buttons.save')}
      </Button>
    </div>
  );
};
