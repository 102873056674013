import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Button, Col, Dropdown, Input, Menu, Row } from 'antd';
import {
  AppstoreOutlined,
  BellOutlined,
  LogoutOutlined,
  MailOutlined,
  SettingOutlined,
  SearchOutlined,
  UserOutlined,
  TagOutlined,
} from '@ant-design/icons';
import photo from '@/style/images/profile.jpeg';
import uniqueId from '@/utils/uinqueId';
import { useSelector } from 'react-redux';
import { selectRole, selectUser } from '@/redux/auth/selectors';
import saleIcon from '@/style/images/sider_icons/Sales_icon_blue.svg';
import useTranslations from '@/hooks/useTranslation';
import { assessItemAccessPrivileges, readRole } from '@/utils/role';

export default function HeaderContent() {
  const { SubMenu } = Menu;
  const role = useSelector(selectRole);
  const history = useHistory();
  const { t } = useTranslations();
  const admin = useSelector(selectUser);
  const basePath = 'header.';
  const permissions = useMemo(() => {
    return {
      showSales: assessItemAccessPrivileges(role, 'sale'),
    };
  }, [role]);

  const profileDropdown = (
    <div className="profileDropdown whiteBox shadow" style={{ minWidth: '200px' }}>
      <div className="pad15" onClick={() => history.push('/settings')}>
        <Avatar
          className="avatar clickable"
          size="large"
          src={admin.image === '' ? photo : admin.image}
        />
        <div className="info clickable">
          <p className="strong">{(admin ?? {}).name}</p>
          <p>{(admin ?? {}).email}</p>
        </div>
      </div>
      <div className="line"></div>
      <div>
        <Menu>
          <Menu.Item
            icon={<LogoutOutlined />}
            key={`${uniqueId()}`}
            onClick={() => history.push('/logout')}
          >
            {t(`${basePath}logout`)}
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <div style={{ zIndex: 1000 }}>
      <Row className="headerIcon">
        <Col style={{ padding: '13px 0 0 27px ', width: '70%', maxWidth: 365 }}>
          <Input
            style={{ width: '100%' }}
            placeholder={t(`${basePath}search`)}
            className="inventory-search"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '30%',
          }}
        >
          {permissions?.showSales &&
            (readRole(role, 'quote') ||
              readRole(role, 'order') ||
              readRole(role, 'exit_voucher') ||
              readRole(role, 'delivery_note') ||
              readRole(role, 'invoice')) && (
              <Button
                type="link"
                className={'header-button'}
                style={{ color: '#326cfe' }}
                icon={<img src={saleIcon} alt="sale" style={{ width: 21, marginRight: 10 }} />}
                onClick={() => history.push('/salesTracking')}
              >
                {t(`${basePath}salesTracking`)}
              </Button>
            )}
          <Button
            type="link"
            className={'header-button'}
            style={{ color: '#326cfe' }}
            icon={<UserOutlined />}
            onClick={() => history.push('/settings/profile')}
          >
            {t(`${basePath}profile`)}
          </Button>
          <Dropdown overlay={profileDropdown} trigger={['click']} placement="bottomRight">
            <Avatar className="last" src={admin.image === '' ? photo : admin.image} />
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
}
