import React, { useState } from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import { DEFAULT_PDF_TEMPLATE, PDFTemplates, PDF_CHOICES } from './PDF.Types';

const PdfTemplates = ({
  selectedTemplate,
  updatePDFTemplate,
}: {
  selectedTemplate: PDFTemplates;
  updatePDFTemplate: (currency: string) => void;
}) => {
  const [choice, setChoice] = useState(selectedTemplate || DEFAULT_PDF_TEMPLATE);

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    updatePDFTemplate(value);
    setChoice(value);
  };

  return (
    <Radio.Group onChange={onChange} value={choice}>
      <div
        style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 16, width: '100%' }}
      >
        {PDF_CHOICES.map((templateChoice, index) => (
          <div
            key={index}
            style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'center' }}
          >
            <img
              className={`cursor-pointer bordered ${choice === templateChoice.value && 'border-chosen'}`}
              src={templateChoice.image}
              alt={templateChoice.label}
              style={{ height: 200, width: 230 }}
              onClick={() =>
                onChange({ target: { value: templateChoice.value } } as RadioChangeEvent)
              }
            />
            <Radio value={templateChoice.value}>
              <div>{templateChoice.label}</div>
            </Radio>
          </div>
        ))}
      </div>
    </Radio.Group>
  );
};

export default PdfTemplates;
