import { combineReducers } from 'redux';
import { reducer as authReducer } from './auth';
import { reducer as crudReducer } from './crud';
import { reducer as erpReducer } from './erp';
import { reducer as settingsReducer } from './settings';
import { reducer as notifyReducer } from './notify';
import saleReducer from '@/redux/sales/reducer';

const appReducer = combineReducers({
  auth: authReducer,
  notify: notifyReducer,
  crud: crudReducer,
  erp: erpReducer,
  settings: settingsReducer,
  sale: saleReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
