import { Section } from '@/modules/SettingsModule/Section';
import { PersonalForm } from '@/modules/SettingsModule/tabs/Profile/personalForm';
import { Buttons } from '@/modules/SettingsModule/buttons';
import { Form } from 'antd';
import useTranslations from '@/hooks/useTranslation';
import BugForm from './bugForm';

export default function Bug() {
  const { t } = useTranslations();
  const path = 'settingsPage.report_bug.';

  return (
    <Form className={'page'}>
      <div className={'main'}>
        <Section title={t(path + 'title')} description={t(path + 'description')} Body={BugForm} />
      </div>
    </Form>
  );
}
