import { Section } from '@/modules/SettingsModule/Section';
import { Divider } from 'antd';
import UsersList from './UsersList';
import AddUsers from './AddUsers';
import { erp } from '@/redux/erp/actions';
import { useEffect } from 'react';
import { selectRoles, selectUsers } from '@/redux/erp/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { selectRole } from '@/redux/auth/selectors';
import useTranslations from '@/hooks/useTranslation';

export default function AccessControl() {
  const role = useSelector(selectRole);
  const { result: listResult, isLoading: listIsLoading } = useSelector(selectUsers);
  const { result: rolesResult, isLoading } = useSelector(selectRoles);
  const { items } = listResult;
  const { t } = useTranslations();
  const path = 'settingsPage.access_control.';
  const dispatch = useDispatch();
  const entity = 'employee';

  useEffect(() => {
    dispatch(erp.listUsers({ entity }));
    dispatch(erp.listRoles());
  }, [dispatch]);

  useEffect(async () => {
    if (items.length === 0) {
      dispatch(erp.listUsers({ entity }));
    }
  }, [items]);

  useEffect(() => {
    if (rolesResult.items.length === 0) {
      dispatch(erp.listRoles());
    }
  }, [rolesResult.items]);

  return (
    <div className={'page'}>
      <div className="main" style={{ overflowX: 'hidden' }}>
        <Section
          title={t(path + 'edit.title')}
          description={t(path + 'edit.description')}
          Body={() => (
            <UsersList isLoading={listIsLoading} users={items} roles={rolesResult.items} />
          )}
        />
        <Divider />
        {role?.settings ? (
          <Section
            title={t(path + 'add_user.title')}
            description={t(path + 'add_user.description')}
            Body={() => <AddUsers roles={rolesResult.items} isRolesLoading={isLoading} />}
          />
        ) : null}
      </div>
    </div>
  );
}
