import { Form, Input } from 'antd';
import React from 'react';
import useTranslations from '@/hooks/useTranslation';

export const PermissionsForm = () => {
  const { t } = useTranslations();
  const path = 'settingsPage.role.add_role_fields.';

  return (
    <>
      <Form.Item
        name="name"
        label={t(path + 'role')}
        rules={[
          {
            required: true,
            message: `${t(path + 'role_required')}`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="description" label={t(path + 'description')}>
        <Input />
      </Form.Item>
    </>
  );
};
