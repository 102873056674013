import { selectCompany } from '@/redux/auth/selectors';
import { formatPrice } from '@/utils/helpers';
import { Tooltip } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

enum Currency {
  EURO = 'Euro',
  TND = 'TND',
}

export const DEFAULT_CURRENCY = Currency.TND;

const PriceDisplay = ({
  price,
  showTooltip = true,
  withCurrency = true,
}: {
  price: number;
  showTooltip?: boolean;
  withCurrency?: boolean;
}) => {
  const company = useSelector(selectCompany);

  if (!price) {
    return '';
  }

  const [priceFormatted, fullPriceFormatted, priceFormattedWithoutCurrency] = formatPrice(
    price,
    company?.currency || DEFAULT_CURRENCY,
  );
  const showingPrice = withCurrency ? priceFormatted : priceFormattedWithoutCurrency;
  return priceFormatted !== fullPriceFormatted && showTooltip ? (
    <Tooltip title={fullPriceFormatted} placement="bottom">
      {showingPrice ?? 0}
    </Tooltip>
  ) : (
    <div>{showingPrice ?? 0}</div>
  );
};

export default PriceDisplay;
