import React from 'react';
import { Section } from '@/modules/SettingsModule/Section';
import { Divider } from 'antd';
import useTranslations from '@/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { selectCompany } from '@/redux/auth/selectors';
import { SubscriptionStatus } from '@/types';
import { WarningOutlined } from '@ant-design/icons';
import CurrentSubDetails from './CurrentSubDetails';
import OnlinePayment from './OnlinePayment';
import Loading from '@/components/Loading';
import { isEmpty } from 'lodash';

const SubscriptionSettings = () => {
  const { t } = useTranslations('settingsPage.subscription');
  const { subscription, remainingDaysForSubExpiration } = useSelector(selectCompany);
  const subStatus =
    remainingDaysForSubExpiration < 15
      ? SubscriptionStatus.Danger
      : remainingDaysForSubExpiration < 30
        ? SubscriptionStatus.Warning
        : null;

  return (
    <Loading isLoading={isEmpty(subscription) || Number.isNaN(remainingDaysForSubExpiration)}>
      <div className={'page'}>
        <div className={'main'} style={{ overflowY: 'hidden' }}>
          {!isEmpty(subscription) && !Number.isNaN(remainingDaysForSubExpiration) && (
            <Section
              title={t('title')}
              description={
                subStatus ? (
                  <div className={`subscription-info subscription-info__${subStatus}`}>
                    <WarningOutlined className="subscription-info__icon" />
                    <span className="font-black">
                      {remainingDaysForSubExpiration > 0
                        ? t('settingsPage.subscription.sub_ends_in', true, {
                            remainingDaysForSubExpiration: remainingDaysForSubExpiration,
                          })
                        : t('settingsPage.subscription.sub_ends_today', true)}
                    </span>
                  </div>
                ) : (
                  t('settingsPage.subscription.sub_ends_in', true, {
                    remainingDaysForSubExpiration: remainingDaysForSubExpiration,
                  })
                )
              }
              Body={() => (
                <CurrentSubDetails
                  subscription={{ ...subscription, remainingDaysForSubExpiration }}
                />
              )}
            />
          )}
          <Divider />
          <OnlinePayment />
        </div>
      </div>
    </Loading>
  );
};

export default SubscriptionSettings;
