import { useEffect } from 'react';
import DataTable from './DataTable';
import Create from './Create';
import Update from './Update';
import Delete from './Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useErpContext } from '../../context/erp';
import { erp } from '../../redux/erp/actions';
import Read from './Read';
import { selectRole } from '@/redux/auth/selectors';
import { isEmpty } from 'lodash';
import Visibility from '@components/Visibility';

export default function ErpPanel<DataStructure>({
  isDisabled = () => {
    return false;
  },
  config,
  CreateForm,
  UpdateForm,
  ReadItem,
  itemFromForm = null,
  formFromItem = null,
  changePrice = false,
  markAsPaid = null,
}) {
  const role = useSelector(selectRole);
  const { inventory, sale, settings, role: userRole } = role;
  const dispatch = useDispatch();
  const { state } = useErpContext();
  const { update, read, create, dataTableList, deleteModal } = state;
  type Role = () => Boolean;
  const readRole: Role = () =>
    sale[config.ENTITY_NAME]?.read || inventory[config.ENTITY_NAME]?.read || userRole?.read;
  const updateRole: Role = () =>
    settings ||
    sale[config.ENTITY_NAME]?.edit ||
    inventory[config.ENTITY_NAME]?.edit ||
    userRole?.edit;
  const createRole: Role = () =>
    sale[config.ENTITY_NAME]?.create || inventory[config.ENTITY_NAME]?.create || userRole?.create;
  const deleteRole: Role = () =>
    sale[config.ENTITY_NAME]?.delete || inventory[config.ENTITY_NAME]?.delete || userRole?.delete;

  useEffect(() => {
    dispatch(erp.resetAction({ actionType: 'read' }));
  }, [role]);
  return (
    <>
      {!isEmpty(role) && (
        <>
          <Visibility isVisible={dataTableList.isOpen}>
            <DataTable isDisabled={isDisabled} config={config} />
          </Visibility>

          {readRole() && (
            <Visibility isVisible={read.isOpen}>
              <Read<DataStructure> ReadItem={ReadItem} config={config} markAsPaid={markAsPaid} />
            </Visibility>
          )}

          {updateRole() && (
            <Visibility isVisible={update.isOpen}>
              <Update
                config={config}
                UpdateForm={UpdateForm}
                itemFromForm={itemFromForm}
                formFromItem={formFromItem}
                changePrice={changePrice}
              />
            </Visibility>
          )}

          {createRole() && (
            <Visibility isVisible={create.isOpen}>
              <Create<DataStructure>
                config={config}
                CreateForm={CreateForm}
                itemFromForm={itemFromForm}
                changePrice={changePrice}
              />
            </Visibility>
          )}

          {deleteRole() && (
            <Visibility isVisible={deleteModal.isOpen}>
              <Delete config={config} />
            </Visibility>
          )}
        </>
      )}
    </>
  );
}

// const Visibility = ({ isVisible, children }) => {
//   const show = isVisible
//     ? { display: 'block', opacity: 1, padding: 0 }
//     : { display: 'none', opacity: 0 };
//   return <div style={show}>{children}</div>;
// };
