import { Layout, PageHeader, Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectRole, selectUser } from '@/redux/auth/selectors';
import useTranslations from '@/hooks/useTranslation';
import { useEffect, useState } from 'react';

const { Content } = Layout;

export default function Settings({ children }) {
  const { t } = useTranslations('settingsPage.tabs');
  const role = useSelector(selectRole);
  const user = useSelector(selectUser);
  const location = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(() => location.pathname.split('/').pop() || 'profile');

  useEffect(() => {
    const tabFromUrl = location.pathname.split('/').pop();
    if (tabFromUrl && tabFromUrl !== activeKey) {
      setActiveKey(tabFromUrl);
    }
  }, [location.pathname]);

  const handleTabChange = (key) => {
    setActiveKey(key);
    history.push(`/settings/${key}`);
  };

  return (
    <div className="settings-page">
      <PageHeader
        title={t('settingsPage.title', true)}
        ghost={false}
        style={{ padding: '20px 40px 10px' }}
      />
      <Content style={{ padding: '0 40px' }}>
        <Tabs animated={false} activeKey={activeKey} onTabClick={handleTabChange}>
          <Tabs.TabPane tab={t('profile')} key="profile" />
          {!user?.invitation?.isInvited && role?.settings && (
            <>
              <Tabs.TabPane tab={t('company')} key="company" />
              <Tabs.TabPane tab="Taxes" key="taxes" />
              <Tabs.TabPane tab="Subscription" key="subscription" />
            </>
          )}
          {role?.settings && (
            <>
              <Tabs.TabPane tab={t('global_settings')} key="notifications" />
              <Tabs.TabPane tab={t('access_control')} key="access-control" />
              <Tabs.TabPane tab={t('roles_and_permissions')} key="permissions" />
            </>
          )}
          <Tabs.TabPane tab={t('security_and_privacy')} key="security" />
          <Tabs.TabPane tab={t('support_and_help')} key="support" />
          <Tabs.TabPane tab={t('report_a_bug')} key="bug" />
        </Tabs>
        {children}
      </Content>
    </div>
  );
}
