import { useEffect, useState } from 'react';
import { Button, Divider, Form, Layout, PageHeader } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useErpContext } from '../../context/erp';
import { erp } from '../../redux/erp/actions';
import Loading from '../Loading';
import uniqueId from '../../utils/uinqueId';
import { selectConfirmItem, selectUpdatedItem } from '../../redux/erp/selectors';
import { selectReadItem } from '@/redux/erp/selectors';
import { editRole } from '@/utils/role';
import { selectRole } from '@/redux/auth/selectors';
import { getNestedProperty } from '@/utils/helpers';
import useTranslations from '@/hooks/useTranslation';

const { Content } = Layout;

export default function Update({ config, UpdateForm, itemFromForm, formFromItem, changePrice }) {
  let { entity, UPDATE_ENTITY, editTitle } = config;
  const { t } = useTranslations('common');
  const { erpContextAction } = useErpContext();
  const { updatePanel } = erpContextAction;
  const dispatch = useDispatch();
  const { isSuccess } = useSelector(selectUpdatedItem);
  const { result: current, isLoading } = useSelector(selectReadItem);
  const role = useSelector(selectRole);
  let [form] = Form.useForm();
  const [ready, setReady] = useState(false);
  const { isSuccess: confirmSuccess } = useSelector(selectConfirmItem);

  const close = () => {
    updatePanel.close();
    form.resetFields();
    setReady(false);
    dispatch(erp.resetAction({ actionType: 'read' }));
  };

  function isUndefined(obj) {
    for (const key in obj) {
      if (obj[key] !== undefined) {
        return false;
      }
    }
    return true;
  }
  const onSubmit = (fieldsValue) => {
    if (fieldsValue) {
      const item = itemFromForm(fieldsValue);
      const id = current._id;
      dispatch(erp.update({ entity, id, jsonData: item, changePrice }));
      setReady(false);
    }
  };
  function Confirm() {
    const id = current._id;
    dispatch(erp.confirm({ entity, id: id }));
    updatePanel.close();
  }

  useEffect(() => {
    if (isSuccess || confirmSuccess) {
      form.resetFields();
      dispatch(erp.resetAction({ actionType: 'update' }));
      updatePanel.close();
      dispatch(erp.list({ entity }));
      setReady(false);
    }
  }, [isSuccess, confirmSuccess]);

  useEffect(() => {
    if (current) {
      form.setFieldsValue(formFromItem(current));
      const interval = setInterval(() => {
        if (!isUndefined(form.getFieldsValue(true))) {
          setReady(true);
          clearInterval(interval);
        }
      }, 10);
    }
  }, [current, entity, form]);

  return (
    <>
      <PageHeader
        className={'header'}
        backIcon={<ArrowLeftOutlined />}
        onBack={close}
        title={editTitle}
        ghost={false}
        style={{
          boxShadow: '0 0px 6px rgba(0, 0, 0, 0.1)',
        }}
        extra={[
          <Button key={`${uniqueId()}`} onClick={close} icon={<CloseCircleOutlined />}>
            {t('cancel')}
          </Button>,
          <SaveForm config={config} form={form} key={`${uniqueId()}`} />,
          config?.draft &&
          form.getFieldValue('isDraft') &&
          editRole(role, config.entity) &&
          (!config?.isDeleted || !getNestedProperty(current, config?.isDeleted)) ? (
            <Button icon={<CheckCircleOutlined />} onClick={Confirm}>
              {t('confirm')}
            </Button>
          ) : null,
        ]}
      />
      <Loading isLoading={isLoading}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Content
            className={'inside-content'}
            style={{
              paddingTop: 20,
              ...(config.small ? { height: 'calc(100vh - 360px)', paddingTop: 20 } : {}),
            }}
          >
            {ready ? (
              <Loading isLoading={isLoading}>
                <UpdateForm config={config} update={true} form={form} />
              </Loading>
            ) : null}
          </Content>
        </Form>
      </Loading>
    </>
  );
}

function SaveForm({ form, config }) {
  let { UPDATE_ENTITY } = config;
  const handelClick = () => {
    form.submit();
  };

  return (
    <Button onClick={handelClick} type="primary" icon={<EditOutlined />}>
      {UPDATE_ENTITY}
    </Button>
  );
}
