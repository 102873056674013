import { motion } from 'framer-motion';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '@/redux/auth/selectors';

const PublicRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useSelector(selectAuth);

  return (
    // Show the component only when the admin is logged in
    // Otherwise, redirect the admin to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Redirect to="/" />
        ) : (
          <motion.div initial={{ x: 200 }} animate={{ x: 0 }} exit={{ scale: 0 }}>
            <Component {...props} />
          </motion.div>
        )
      }
    />
  );
};

export default PublicRoute;
