export const config = {
  item: {
    name: 'item',
    category: 'inventory',
  },
  setCategory: {
    name: 'category',
    category: 'inventory',
  },
  item_group: {
    name: 'category',
    category: 'inventory',
  },
  adjustment: {
    name: 'inventory_adjustment',
    category: 'inventory',
  },
  transfer_order: {
    name: 'transfer_order',
    category: 'inventory',
  },
  warehouse: {
    name: 'store',
    category: 'inventory',
  },
  quote: {
    name: 'quote',
    category: 'sale',
  },
  order: {
    name: 'order',
    category: 'sale',
  },
  invoice: {
    name: 'invoice',
    category: 'sale',
  },
  delivery_note: {
    name: 'delivery_note',
    category: 'sale',
  },
  exit_voucher: {
    name: 'exit_voucher',
    category: 'sale',
  },
  client: {
    name: 'client',
    category: 'sale',
  },
  sale_return: {
    name: 'sale_return',
    category: 'sale',
  },
  supplier: {
    name: 'supplier',
    category: 'purchase',
  },
  vendor: {
    name: 'vendor',
    category: 'purchase',
  },
  role: {
    name: 'role',
    category: 'none',
  },
};
