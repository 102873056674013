import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Typography } from 'antd';

import { useAppContext } from '@/context/appContext';

import supplyzLogo from '@/style/images/supplyzSideLogo.svg';
import dashboardIcon from '@/style/images/sider_icons/Dashboard_icon.svg';
import saleIcon from '@/style/images/sider_icons/Sales_icon.svg';
import purchaseIcon from '../../style/images/sider_icons/Purchase_icon.svg';
import documentsIcon from '../../style/images/sider_icons/Documents_icon.svg';
import settingsIcon from '../../style/images/sider_icons/Settings_icon.svg';

import items from '@/style/images/sider_icons/items.svg';
import { WarningOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCompany, selectRole, selectUser } from '@/redux/auth/selectors';
import { assessItemAccessPrivileges, readRole } from '@/utils/role';
import { isEmpty } from 'lodash';
import useTranslations from '@/hooks/useTranslation';
import { SubscriptionStatus } from '@/types';
import usePathname from '@/hooks/usePathname';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;
const basePath = 'sideBar.';
const inventoryItemsPath = basePath + 'inventory.';
const salesItemsPath = basePath + 'sales.';
const purchasesItemsPath = basePath + 'purchases.';
const INVENTORY_SIDEBAR_KEYS = [
  'items',
  'setCategories',
  'inventoryAdjustment',
  'warehouses',
  'transferOrder',
];
const SALES_SIDEBAR_KEYS = [
  'salesTracking',
  'quotes',
  'salesOrders',
  'deliveryNotes',
  'exitVouchers',
  'invoices',
  'clients',
  'salesReturns',
];
const PURCHASES_SIDEBAR_KEYS = ['vendor', 'supplier'];

export default function Navigation() {
  const { t } = useTranslations();
  const role = useSelector(selectRole);
  const company = useSelector(selectCompany);
  const subStatus =
    company?.remainingDaysForSubExpiration < 15
      ? SubscriptionStatus.Danger
      : company?.remainingDaysForSubExpiration < 30
        ? SubscriptionStatus.Warning
        : null;
  const { state: stateApp } = useAppContext();
  const { isNavMenuClose } = stateApp;
  const [openKeys, setOpenKeys] = useState([]);
  const profile = useSelector(selectUser);
  const pathname = usePathname();

  const permissions = useMemo(() => {
    return {
      showBI: assessItemAccessPrivileges(role, 'bi'),
      showInventory: assessItemAccessPrivileges(role, 'inventory'),
      showSales: assessItemAccessPrivileges(role, 'sale'),
      showPurchases: assessItemAccessPrivileges(role, 'purchase'),
    };
  }, [role]);

  useEffect(() => {
    if (!isEmpty(permissions)) {
      const animationDelay = setTimeout(() => {
        if (!!(isEmpty(pathname) || pathname === 'settings' || pathname === 'bug')) {
          if (permissions.showInventory) {
            setOpenKeys(['Inventory']);
          } else if (permissions.showSales) {
            setOpenKeys(['Sale']);
          } else if (permissions.showPurchases) {
            setOpenKeys(['Purchase']);
          }
        } else {
          if (INVENTORY_SIDEBAR_KEYS.includes(pathname)) {
            setOpenKeys(['Inventory']);
          } else if (SALES_SIDEBAR_KEYS.includes(pathname)) {
            setOpenKeys(['Sales']);
          } else if (PURCHASES_SIDEBAR_KEYS.includes(pathname)) {
            setOpenKeys(['Purchase']);
          }
        }
      }, 700);
      return () => clearTimeout(animationDelay);
    }
  }, [permissions, pathname]);

  if (isEmpty(role)) return <Sider className="navigation"></Sider>;

  return (
    <>
      <Sider className="navigation">
        <Content>
          {!profile.isProfileCompleted ? (
            <div className="logo">{!isNavMenuClose && <img src={supplyzLogo} alt="Logo" />}</div>
          ) : (
            <Link to={'/'} className="logo">
              {!isNavMenuClose && <img src={supplyzLogo} alt="Logo" />}
            </Link>
          )}
        </Content>
        <Content className={'menu'}>
          {subStatus && !profile?.invitation?.isInvited && role?.settings && (
            <div
              className={`subscription-info subscription-info__side-bar subscription-info__${subStatus}`}
            >
              <WarningOutlined className="subscription-info__icon" />
              <span className="font-black">
                {company?.remainingDaysForSubExpiration > 0
                  ? t('settingsPage.subscription.sub_ends_in', true, {
                      remainingDaysForSubExpiration: company?.remainingDaysForSubExpiration,
                    })
                  : t('settingsPage.subscription.sub_ends_today', true)}
              </span>
            </div>
          )}
          <Menu
            className={
              subStatus &&
              !profile?.invitation?.isInvited &&
              role?.settings &&
              'menu-with-subscription-message'
            }
            defaultSelectedKeys={[pathname]}
            selectedKeys={[pathname]}
            mode="inline"
            openKeys={openKeys}
            onOpenChange={(open) => {
              setOpenKeys(open.slice(-1));
            }}
          >
            <div className="sidebar-items">
              <Menu.ItemGroup>
                <Menu.Item
                  key={''}
                  title={'Dashboard'}
                  icon={<img src={dashboardIcon} alt="Dashboard" style={{ width: 21 }} />}
                  disabled={!profile.isProfileCompleted}
                >
                  <Link to={'/'} />
                  {t(`${basePath}home`)}
                </Menu.Item>
                {permissions?.showInventory && (
                  <SubMenu
                    key={'Inventory'}
                    icon={<img src={items} alt="inventory" style={{ width: 17 }} />}
                    title={t(`${inventoryItemsPath}title`)}
                    disabled={!profile.isProfileCompleted}
                  >
                    {readRole(role, 'item') ? (
                      <Menu.Item key={INVENTORY_SIDEBAR_KEYS[0]}>
                        <Link to={'/items'} />
                        {t(`${inventoryItemsPath}item`)}
                      </Menu.Item>
                    ) : null}
                    {company.dynamic_categories && readRole(role, 'setCategory') ? (
                      <Menu.Item key={INVENTORY_SIDEBAR_KEYS[1]}>
                        <Link to={'/setCategories'} />
                        {t(`${inventoryItemsPath}setCategories`)}
                      </Menu.Item>
                    ) : null}
                    {readRole(role, 'adjustment') ? (
                      <Menu.Item key={INVENTORY_SIDEBAR_KEYS[2]}>
                        <Link to={'/inventoryAdjustment'} />
                        {t(`${inventoryItemsPath}inventoryAdjustment`)}
                      </Menu.Item>
                    ) : null}
                    {company.multiple_warehouse ? (
                      <>
                        {readRole(role, 'warehouse') ? (
                          <Menu.Item key={INVENTORY_SIDEBAR_KEYS[3]}>
                            <Link to={'/warehouses'} />
                            {t(`${inventoryItemsPath}warehouse`)}
                          </Menu.Item>
                        ) : null}
                        {readRole(role, 'transfer_order') ? (
                          <Menu.Item key={INVENTORY_SIDEBAR_KEYS[4]}>
                            <Link to={'/transferOrder'} />
                            {t(`${inventoryItemsPath}transferOrder`)}
                          </Menu.Item>
                        ) : null}
                      </>
                    ) : null}
                  </SubMenu>
                )}
                {permissions?.showSales && (
                  <SubMenu
                    key={'Sales'}
                    icon={<img src={saleIcon} alt="sale" style={{ width: 21 }} />}
                    title={t(`${salesItemsPath}title`)}
                    disabled={!profile.isProfileCompleted}
                  >
                    {readRole(role, 'quote') ||
                    readRole(role, 'order') ||
                    readRole(role, 'exit_voucher') ||
                    readRole(role, 'delivery_note') ||
                    readRole(role, 'invoice') ? (
                      <Menu.Item key={SALES_SIDEBAR_KEYS[0]}>
                        <Link to={'/salesTracking'} />
                        {t(`${salesItemsPath}salesTracking`)}
                      </Menu.Item>
                    ) : null}
                    {readRole(role, 'quote') ? (
                      <Menu.Item key={SALES_SIDEBAR_KEYS[1]}>
                        <Link to={'/quotes'} />
                        {t(`${salesItemsPath}quote`)}
                      </Menu.Item>
                    ) : null}
                    {readRole(role, 'order') ? (
                      <Menu.Item key={SALES_SIDEBAR_KEYS[2]}>
                        <Link to={'/salesOrders'} />
                        {t(`${salesItemsPath}order`)}
                      </Menu.Item>
                    ) : null}
                    {company.delivery_note && readRole(role, 'delivery_note') ? (
                      <Menu.Item key={SALES_SIDEBAR_KEYS[3]}>
                        <Link to={'/deliveryNotes'} />
                        {t(`${salesItemsPath}deliveryNote`)}
                      </Menu.Item>
                    ) : null}
                    {company.exit_voucher && readRole(role, 'exit_voucher') ? (
                      <Menu.Item key={SALES_SIDEBAR_KEYS[4]}>
                        <Link to={'/exitVouchers'} />
                        {t(`${salesItemsPath}exitVouchers`)}
                      </Menu.Item>
                    ) : null}
                    {readRole(role, 'invoice') ? (
                      <Menu.Item key={SALES_SIDEBAR_KEYS[5]}>
                        <Link to={'/invoices'} />
                        {t(`${salesItemsPath}invoice`)}
                      </Menu.Item>
                    ) : null}
                    {readRole(role, 'client') ? (
                      <Menu.Item key={SALES_SIDEBAR_KEYS[6]}>
                        <Link to={'/clients'} />
                        {t(`${salesItemsPath}client`)}
                      </Menu.Item>
                    ) : null}
                    {company.accept_sales_return && readRole(role, 'sale_return') ? (
                      <Menu.Item key={SALES_SIDEBAR_KEYS[7]}>
                        <Link to={'/salesReturns'} />
                        {t(`${salesItemsPath}salesReturn`)}
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                )}
                {permissions?.showPurchases && (
                  <SubMenu
                    key={'Purchase'}
                    icon={<img src={purchaseIcon} alt="purchase" style={{ width: 21 }} />}
                    title={t(`${purchasesItemsPath}title`)}
                    disabled={!profile.isProfileCompleted}
                  >
                    {readRole(role, 'vendor') ? (
                      <Menu.Item key={PURCHASES_SIDEBAR_KEYS[0]}>
                        <Link to={'/vendor'} />
                        {t(`${purchasesItemsPath}purchase`)}
                      </Menu.Item>
                    ) : null}
                    {readRole(role, 'supplier') ? (
                      <Menu.Item key={PURCHASES_SIDEBAR_KEYS[1]}>
                        <Link to={'/supplier'} />
                        {t(`${purchasesItemsPath}supplier`)}
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                )}
                <Menu.Item
                  key={'documents'}
                  icon={<img src={documentsIcon} alt="documents" style={{ width: 18 }} />}
                  title={'Documents'}
                  disabled={!profile.isProfileCompleted}
                >
                  <Link to={'/documents'} />
                  {t(basePath + 'documents')}
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup className={'settings'}>
                <Menu.Item key={'bug'} icon={<WarningOutlined />} title={'Report a Bug'}>
                  <Link to={'/settings/bug'} />
                  {t(basePath + 'reportBug')}
                </Menu.Item>
                <Menu.Item
                  key={'settings'}
                  icon={<img src={settingsIcon} alt="settings" style={{ width: 20 }} />}
                  title={'Settings'}
                >
                  <Link to={'/settings'} />
                  {t(basePath + 'settings')}
                </Menu.Item>
              </Menu.ItemGroup>
            </div>
          </Menu>
        </Content>
      </Sider>
    </>
  );
}
