import { Tag } from 'antd';
import useTranslations from '@/hooks/useTranslation';

function Status({ status }) {
  const { t } = useTranslations();
  const path = 'settingsPage.access_control.edit.';
  const statusColors = {
    Active: (
      <Tag color="#C3F0DB" className="user-tag">
        {t(path + 'active')}
      </Tag>
    ),
    Offline: (
      <Tag color="#FFB1B1" className="user-tag">
        {t(path + 'offline')}
      </Tag>
    ),
    Inactive: (
      <Tag color="#F4F8FB" className="user-tag">
        {t(path + 'inactive')}
      </Tag>
    ),
    'Invite Pending': (
      <Tag color="#EBEEFF" className="user-tag">
        {t(path + 'invite_pending')}
      </Tag>
    ),
  };
  return <div className="user-status">{statusColors[status]}</div>;
}

const transformRoles = (roles) => {
  return (
    roles &&
    roles
      .filter((role) => role.name && role.name !== 'OWNER')
      .map((role) => {
        return { label: role.name ? role.name : '', value: role._id };
      })
  );
};

export { Status, transformRoles };
