import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  FontColor,
  FontSize,
  List,
  BlockQuote,
  Heading,
  Link,
  Table,
  TableToolbar,
} from 'ckeditor5';
// @ts-ignore
import frTranslations from 'ckeditor5/translations/fr.js';
// @ts-ignore
import enTranslations from 'ckeditor5/translations/en.js';
import 'ckeditor5/ckeditor5.css';
import useTranslations from '@/hooks/useTranslation';
import { Editor } from '@ckeditor/ckeditor5-core';

type CustomCKEditorProps = {
  initialData?: string;
  onContentChange: (content: string) => void;
};

/**
 * CustomCKEditor component.
 *
 * @param {string} initialData - The initial data for the CKEditor.
 * @param {Function} onContentChange - A function that will be called with the current content of the CKEditor whenever the content changes. This function should update the state of the parent component.
 *
 * @returns {JSX.Element} The rendered CKEditor component.
 *
 * Note: While TypeScript won't throw an error if the `onContentChange` prop is missing when using this component in another JSX file, you must pass it to ensure the parent state is updated correctly when the content of the CKEditor changes.
 */
const CustomCKEditor = ({ initialData, onContentChange }: CustomCKEditorProps) => {
  const { currentLanguage } = useTranslations();

  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            '|',
            'fontSize',
            'fontColor',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'blockQuote',
            'heading',
            '|',
            'link',
            'insertTable',
          ],
        },
        plugins: [
          Bold,
          Essentials,
          Italic,
          Mention,
          Paragraph,
          Undo,
          FontColor,
          FontSize,
          List,
          BlockQuote,
          Heading,
          Link,
          Table,
          TableToolbar,
        ],
        fontSize: {
          options: [10, 12, 14, 16, 18, 24, 30],
        },
        translations: [enTranslations, frTranslations],
        language: currentLanguage,
        initialData: initialData || (currentLanguage === 'fr' ? 'Salut,' : 'Hey there,'),
      }}
      onChange={(_, editor) => {
        onContentChange(editor.data.get());
      }}
    />
  );
};

export default CustomCKEditor;
