import NotificationOption from './NotificationOption';

export default function nEmailNotifications() {
  return (
    <div className="email-notifications">
      <NotificationOption fieldName={'dynamic_categories'} />

      {/*TODO: removed this feature temporarily to fix it. */}
      {/*Bug is causing deletion of warehouses in db.*/}
      <NotificationOption fieldName={'multiple_warehouse'} />
      <NotificationOption fieldName={'delivery_note'} />
      <NotificationOption fieldName={'exit_voucher'} />
      <NotificationOption fieldName={'allow_stock_lower_than_zero'} />
      <NotificationOption fieldName={'accept_sales_return'} />
      <NotificationOption fieldName={'low_inventory_alerts'} />
    </div>
  );
}
