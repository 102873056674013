import React, { useEffect, useState } from 'react';
import { Form, Switch } from 'antd';
import { isEmpty } from 'lodash';

const DEFAULT_SWITCH_VALUE = false;

export const SwitchField = ({ name, form, title, description }) => {
  const [checked, setChecked] = useState(form.getFieldValue(name));

  useEffect(() => {
    if (isEmpty(form.getFieldValue(name))) form.setFieldValue({ [name]: DEFAULT_SWITCH_VALUE });
  }, []);

  return (
    <>
      <div className="notification-option" key={name}>
        <h3 style={{ width: 180 }}>{title}</h3>
        <div className="notification-option-checkbox">
          <Form.Item name={name}>
            <Switch
              checked={checked}
              onClick={() => {
                setChecked(!checked);
                form.setFieldValue({ [name]: !checked });
              }}
            />
          </Form.Item>
        </div>
      </div>
      <p style={{ color: '#999' }}>{description}</p>
    </>
  );
};
