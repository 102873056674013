import { Section } from '@/modules/SettingsModule/Section';
import EmailNotifications from './EmailNotifications';
import useTranslations from '@/hooks/useTranslation';

export default function Notifications() {
  const { t } = useTranslations();
  return (
    <div className={'page'}>
      <Section
        title={t('settingsPage.global_settings.title')}
        description={t('settingsPage.global_settings.description')}
        Body={EmailNotifications}
      />
    </div>
  );
}
