import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const history = useHistory();

  // eslint-disable-next-line no-console
  console.error(error);

  const handleGoBack = () => {
    resetErrorBoundary();
    history.push('/');
  };

  return (
    <div
      role="alert"
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      <h3>Something went wrong..</h3>
      <Button type="primary" onClick={handleGoBack}>
        Go back
      </Button>
    </div>
  );
}
