import React from 'react';
import { Section } from '@/modules/SettingsModule/Section';
import { Divider } from 'antd';
import useTranslations from '@/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { selectCompany } from '@/redux/auth/selectors';
import VatSettings from './VatSettings';
import OtherTaxes from './OtherTaxes';
import Loading from '@/components/Loading';
import { isEmpty } from 'lodash';

const TaxesSettings = () => {
  const { t } = useTranslations('settingsPage.taxes');
  const { taxes, vatOptions } = useSelector(selectCompany);

  return (
    <Loading
      isLoading={
        (isEmpty(taxes) && (taxes || [])?.length !== 0) ||
        (isEmpty(vatOptions) && (vatOptions || [])?.length !== 0)
      }
    >
      <div className={'page'}>
        <div className={'main'}>
          <Section
            title={t('vat.title')}
            description={''}
            Body={() => <VatSettings vatOptions={vatOptions} />}
          />
          <Divider />
          <Section
            title={t('other_taxes.title')}
            description={''}
            Body={() => <OtherTaxes taxes={taxes} />}
          />
        </div>
      </div>
    </Loading>
  );
};

export default TaxesSettings;
