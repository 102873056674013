import { Button, Checkbox, DatePicker, Divider, Input, Slider } from 'antd';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { useEffect, useState } from 'react';
import { request } from '@/request';
import Loading from '@components/Loading';
import _debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import useTranslations from '@/hooks/useTranslation';
import { capitalize, isEmpty } from 'lodash';
import {
  getBeforeFirstDot,
  removeBeforeFirstDot,
  transformFilterData,
  transformPhoneNumberData,
} from '../helpers';

const { RangePicker } = DatePicker;

const Buttons = ({ clearFilters, confirm }) => {
  const { t } = useTranslations('common');
  return (
    <>
      <Divider style={{ margin: 0 }} />
      <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          type="text"
          onClick={clearFilters}
          size={'small'}
          style={{ color: 'grey', border: 'solid 1px', borderColor: '#99999' }}
        >
          {t('reset')}
        </Button>
        <Button
          type={'primary'}
          size={'small'}
          onClick={() => {
            confirm({ closeDropdown: true });
          }}
        >
          OK
        </Button>
      </div>
    </>
  );
};

export const DateFilter = ({ setSelectedKeys, selectedKeys, clearFilters, confirm }) => {
  return (
    <div>
      <RangePicker
        style={{ margin: 5 }}
        onCalendarChange={(value) => {
          if (value[0] !== null && value[1] !== null) {
            setSelectedKeys([value[0].format('DD/MM/YYYY'), value[1].format('DD/MM/YYYY')]);
          }
        }}
      />
      <Buttons clearFilters={clearFilters} confirm={confirm} />
    </div>
  );
};

export const NumberFilter = ({
  setSelectedKeys,
  selectedKeys,
  clearFilters,
  confirm,
  entity,
  open,
  item,
}) => {
  const field = item.dataIndex instanceof Array ? item.dataIndex.join('.') : item.dataIndex;
  const [minMax, setMinMax] = useState();
  const [loading, setLoading] = useState(true);
  const [openForTheFirstTime, setOpenForTheFirstTime] = useState(true);

  const fetchRange = (open = false, field = '') => {
    if (open) {
      if (!openForTheFirstTime) {
        return;
      }
      setOpenForTheFirstTime(false);
      request.rangeField({ entity, field }).then(({ result }) => {
        setMinMax(result);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    fetchRange(open, field);
  }, [open, field]);

  return (
    <div style={{ minWidth: 150 }}>
      <Loading isLoading={loading}>
        <Slider
          range
          max={minMax?.max || 100}
          min={minMax?.min || 0}
          defaultValue={[minMax?.min || 0, minMax?.max || 100]}
          style={{
            paddingTop: '10px',
            margin: '20px 17px',
            display: 'flex',
            justifyContent: 'center',
          }}
          autoFocus={true}
          onChange={(value) => {
            if (value[0] !== null && value[1] !== null) {
              setSelectedKeys([value[0], value[1]]);
            }
          }}
        />
        <Buttons clearFilters={clearFilters} confirm={confirm} />
      </Loading>
    </div>
  );
};

export const StringFilter = ({
  setSelectedKeys,
  selectedKeys,
  clearFilters,
  confirm,
  entity,
  item,
  open,
  otherModelName,
  isEnum,
  fixedOptions,
}) => {
  let field = item.dataIndex instanceof Array ? item.dataIndex.join('.') : item.dataIndex;
  const localField = !isEmpty(otherModelName) ? getBeforeFirstDot(field) : null;
  field = !isEmpty(otherModelName) ? removeBeforeFirstDot(field) : field;
  const { t } = useTranslations('common');
  const [page, setPage] = useState(1);
  const [openForTheFirsTime, setOpenForTheFirsTime] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [options, setOptions] = useState([]);
  const [isFilterLoading, setFilterLoading] = useState(false);

  useEffect(() => {
    if (open) {
      if (!openForTheFirsTime) {
        return;
      }
      if (isEnum) {
        setOptions(fixedOptions);
        setHasMore(false);
        return;
      }
      setOpenForTheFirsTime(false);
      setFilterLoading(true);
      setPage(1);
      request
        .listField({
          entity,
          field,
          limit: 10,
          fieldsToSelect: [field],
          localField,
          otherModelName,
        })
        .then(({ result, pagination }) => {
          setHasMore(pagination.count > 0 && pagination.pages > pagination.page);
          switch (field) {
            case 'color.name':
              setOptions(transformFilterData(result, 'color'));
              break;
            case 'bill.name':
              setOptions(transformFilterData(result, 'bill'));
              break;
            case 'parent.counter':
              setOptions(transformFilterData(result, 'parent', 'counter'));
              break;
            case 'phoneNumber':
              setOptions(transformPhoneNumberData(result));
              break;
            default:
              setOptions(result);
          }
          setFilterLoading(false);
        });
    }
  }, [open]);

  const handleKeywordChange = (query = '') => {
    setKeyword(query);
    setFilterLoading(true);
    setPage(1);
    request
      .listField({
        entity,
        field,
        limit: 10,
        filters: !isEmpty(field) && !isEmpty(query) ? { [field]: query } : '',
        fieldsToSelect: [field],
        localField,
        otherModelName,
      })
      .then(({ result, pagination }) => {
        setHasMore(pagination.count > 0 && pagination.pages > pagination.page);
        switch (field) {
          case 'color.name':
            setOptions(transformFilterData(result, 'color'));
            break;
          case 'bill.name':
            setOptions(transformFilterData(result, 'bill'));
            break;
          case 'parent.counter':
            setOptions(transformFilterData(result, 'parent', 'counter'));
            break;
          case 'phoneNumber':
            setOptions(transformPhoneNumberData(result));
            break;
          default:
            setOptions(result);
        }
        setFilterLoading(false);
      });
  };

  const fetchData = () => {
    request
      .listField({
        entity,
        field,
        page: page + 1,
        limit: 10,
        fieldsToSelect: [field],
        localField,
        otherModelName,
      })
      .then(({ result, pagination }) => {
        switch (field) {
          case 'color.name':
            setOptions([...options, ...transformFilterData(result, 'color')]);
            break;
          case 'bill.name':
            setOptions([...options, ...transformFilterData(result, 'bill')]);
            break;
          case 'parent.counter':
            setOptions([...options, ...transformFilterData(result, 'parent', 'counter')]);
            break;
          case 'phoneNumber':
            setOptions([...options, ...transformPhoneNumberData(result)]);
            break;
          default:
            setOptions([...options, ...result]);
        }
        setPage(pagination.page);
        setHasMore(pagination.count > 0 && pagination.pages > pagination.page);
      });
  };

  const debounceFn = _debounce(handleKeywordChange, 500);

  const Choice = ({ value }) => {
    const displayValue = isEnum ? value?.name : value?.[field] || value?.name || '';
    const actualValue = isEnum ? value?.value : value?.[field] || value?.name || '';

    return (
      <Checkbox
        value={actualValue}
        style={{ marginLeft: 15, maxHeight: 30, padding: '4px 0' }}
        checked={selectedKeys.includes(actualValue)}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedKeys([...selectedKeys, actualValue]);
          } else {
            setSelectedKeys(selectedKeys.filter((item) => item !== actualValue));
          }
        }}
      >
        {capitalize(displayValue || t('emptyValue'))}
      </Checkbox>
    );
  };

  return (
    <div>
      {!isEnum && (
        <Input
          placeholder="input search text"
          prefix={<SearchOutlined />}
          style={{ margin: '10px 15px', width: 150 }}
          onChange={(e) => {
            debounceFn(e.target.value.trim().toLowerCase());
          }}
        />
      )}
      <Divider style={{ margin: 0 }} />
      {!isFilterLoading ? (
        <>
          <div
            id="item-list"
            style={{
              height: 140,
              overflow: 'auto',
            }}
          >
            <InfiniteScroll
              dataLength={options?.length || 0} //This is important field to render the next data
              next={fetchData}
              hasMore={hasMore}
              scrollableTarget="item-list"
              loader={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '4px 0',
                  }}
                >
                  <Loading />
                </div>
              }
            >
              {(options?.length > 0 ? options : [{}]).map((item, index) => (
                <div key={index}>
                  <Choice value={item} />
                </div>
              ))}
            </InfiniteScroll>
          </div>
          <Buttons clearFilters={clearFilters} confirm={confirm} />
        </>
      ) : null}
      {isFilterLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 0',
            height: 121,
          }}
        >
          <Loading />
        </div>
      ) : null}
    </div>
  );
};
