import { useEffect } from 'react';
import { Input, Button, Form, Select } from 'antd';
import { transformRoles } from '@/modules/SettingsModule/tabs/AccessControl/Helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreatedUser } from '@/redux/erp/selectors';
import { erp } from '@/redux/erp/actions';
import Loading from '@/components/Loading';
import useTranslations from '@/hooks/useTranslation';

export default function AddUsers({ roles, isRolesLoading }) {
  const entity = 'employee';
  const dispatch = useDispatch();
  const { isLoading, isSuccess } = useSelector(selectCreatedUser);
  const { t } = useTranslations();
  const path = 'settingsPage.access_control.add_user';
  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      dispatch(erp.resetAction({ actionType: 'createUser' }));
      dispatch(erp.listUsers({ entity }));
    }
  }, [isSuccess]);

  let [form] = Form.useForm();

  const onSubmit = (values) => {
    dispatch(erp.createUser({ entity, jsonData: values }));
  };

  return (
    <Loading isLoading={isLoading}>
      <Form onFinish={onSubmit} form={form}>
        <div className="add-users">
          <div className="add-users-input" style={{ width: '70%' }}>
            <Form.Item
              label={t(path + '.email')}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Please input a valid email address',
                },
                {
                  required: true,
                  message: 'Please input an email address',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t(path + '.role')}
              name="role"
              rules={[
                {
                  required: true,
                  message: 'Please select a role',
                },
              ]}
            >
              <Loading isLoading={isRolesLoading}>
                <Select
                  options={transformRoles(roles)}
                  onChange={(value) => form.setFieldsValue({ role: value })}
                />
              </Loading>
            </Form.Item>
          </div>
          <div className="add-users-button">
            <Button className="add-users-button-submit" onClick={form.submit} type="primary">
              {t(path + '.button')}
            </Button>
          </div>
        </div>
      </Form>
    </Loading>
  );
}
