export class RoleModel {
  _id: string = '';
  name: string = '';
  description: string = '';
  permissions: Array<string> = [];
  bi: boolean = false;
  settings: boolean = false;
  inventory: object = {};
  purchase: object = {};
  sale: object = {};
  role: object = {};
  access_control: object = {};
  createdAt: string = '';
  updatedAt: string = '';
  createdBy: string = '';
  updatedBy: string = '';
  constructor(formData) {
    this.name = formData.name;
    this.description = formData.description;
    this._id = formData._id;
    if (formData.bi) this.bi = formData.bi;
    if (formData.settings) this.settings = formData.settings;
    if (formData.inventory) {
      formData.inventory.forEach((item) => {
        const name = item.name;
        delete item.name;
        this.inventory[name] = item;
      });
    }
    if (formData.purchase) {
      formData.purchase.forEach((item) => {
        const name = item.name;
        delete item.name;
        this.purchase[name] = item;
      });
    }
    if (formData.sale) {
      formData.sale.forEach((item) => {
        const name = item.name;
        delete item.name;
        this.sale[name] = item;
      });
    }
    if (formData.role) {
      const data = formData.role[0];
      delete data.name;
      this.role = data;
    }
    if (formData.access_control) {
      const data = formData.access_control[0];
      delete data.name;
      this.access_control = data;
    }
  }
  static getForm(element) {
    if (element) {
      if (element.bi) element.bi = element.bi;
      if (element.settings) element.settings = element.settings;
      if (element.inventory) {
        element.inventory = Object.keys(element.inventory).map((key) => {
          return { name: key, ...element.inventory[key] };
        });
      }
      if (element.purchase) {
        element.purchase = Object.keys(element.purchase).map((key) => {
          return { name: key, ...element.purchase[key] };
        });
      }
      if (element.sale) {
        element.sale = Object.keys(element.sale).map((key) => {
          return { name: key, ...element.sale[key] };
        });
      }
      if (!element.role.length && element.role) {
        element.role = [{ name: 'role', ...element.role }];
      }
      if (!element.access_control?.length && element.access_control) {
        element.access_control = [{ name: 'access_control', ...element.access_control }];
      }
      return element;
    }
  }
}
