import OnlinePaymentSVG from '@/style/images/online-payment.svg';
import ContactList from '@/components/ContactList/ContactList';
import useTranslations from '@/hooks/useTranslation';

const OnlinePayment = () => {
  const { t } = useTranslations('settingsPage.subscription');

  return (
    <div className="w-full flex-all-center flex-col gap-12">
      <h3>{t('online_payment_soon')} 😊</h3>
      <img
        className=""
        style={{ width: 140, height: 160, fill: '#1B98F5', color: '#1B98F5' }}
        src={OnlinePaymentSVG}
        alt={'Online payment coming soon'}
      />
      <h3>{t('extend_sub')}</h3>
      <ContactList />
    </div>
  );
};

export default OnlinePayment;
