import { Form } from 'antd';
import useTranslations from '@/hooks/useTranslation';
import LanguageSelector from '@/components/LanguageSelector';
import { request } from '@/request';

export const LanguageForm = () => {
  const { t, setLanguage } = useTranslations();

  const handleLanguageChange = async (language: string) => {
    const data = await request.update({
      entity: 'admin',
      id: 'profile',
      jsonData: {
        language,
      },
    });
    if (data.success) setLanguage(language);
  };

  return (
    <>
      <Form.Item
        name={['profile', 'language']}
        label={t('settingsPage.language.language')}
        className="max-form-width"
      >
        <LanguageSelector
          className="settings-language max-form-width"
          defaultBehaviour={false}
          handleSelectFromOutisde={(language: string) => handleLanguageChange(language)}
        />
      </Form.Item>
    </>
  );
};
