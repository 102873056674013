import React, { useCallback, useEffect, useState } from 'react';
import UserCard from './UserCard';
import Loading from '@/components/Loading';

export default function UsersList({ roles, users, isLoading }) {
  return (
    <Loading isLoading={isLoading}>
      <div className="users-list special-scrollbar">
        {users &&
          users.map((item) => {
            return (
              <UserCard
                key={item._id}
                id={item._id}
                email={item.email}
                name={item.name}
                status={
                  item.invitation.invitationStatus === 'accepted' || !item.invitation.isInvited
                    ? 'Active'
                    : 'Invite Pending'
                }
                role={item.role}
                image={item.image}
                roles={roles}
                invitation={item.invitation}
              />
            );
          })}
      </div>
    </Loading>
  );
}
