import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PublicRoute from './PublicRoute';
import PageLoader from '@/components/PageLoader';
import HandleGoogleAuth from '@/pages/HandleGoogleAuth';

const Login = lazy(() => import(/*webpackChunkName:'LoginPage'*/ '@/pages/Login'));
const ForgotPassword = lazy(
  () => import(/*webpackChunkName:'ForgotPasswordPage'*/ '@/pages/ForgotPassword'),
);
const ResetPassword = lazy(
  () => import(/*webpackChunkName:'ResetPasswordPage'*/ '@/pages/ResetPassword'),
);
const AccountActivation = lazy(
  () => import(/*webpackChunkName:'AccountActivationPage'*/ '@/pages/AccountActivation'),
);
const SignUp = lazy(() => import(/*webpackChunkName:'LoginPage'*/ '@pages/SignUp'));
const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/NotFound'));
const AuthFailure = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/AuthFailure'));

export default function AuthRouter() {
  const location = useLocation();
  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <PublicRoute path="/" component={Login} render={() => <Redirect to="/login" />} exact />
          <PublicRoute component={Login} path="/login" exact />
          <PublicRoute component={HandleGoogleAuth} path="/g/v" />
          <PublicRoute component={SignUp} path="/signup" exact />
          <PublicRoute component={AuthFailure} path="/google-auth-failure" exact />
          <PublicRoute component={ForgotPassword} path="/forgot-password" exact />
          <PublicRoute component={ResetPassword} path="/reset-password" exact />
          <PublicRoute component={AccountActivation} path="/account-activation" exact />
          <Route path="*" component={NotFound} render={() => <Redirect to="/notfound" />} />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
