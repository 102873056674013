import * as actionTypes from '../types';

import history from '@/utils/history';
import { request } from '@/request';

export const initializeSubscription = () => async (dispatch) => {
  dispatch({
    type: actionTypes.FAILED_REQUEST,
  });
};

export const login =
  ({ loginData }) =>
  async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_REQUEST,
      payload: { loading: true },
    });
    const data = await request.login({ loginData });

    if (data.success === true) {
      dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        payload: { ...data.result, token: data.result.token },
      });
      history.push('/');
    } else {
      dispatch({
        type:
          data.message === 'subscriptionNotPaid'
            ? actionTypes.FAILED_LOGIN_SUB
            : actionTypes.FAILED_REQUEST,
        payload: data,
      });
    }
  };

export const logout =
  (hard = true) =>
  async (dispatch) => {
    history.push('/');
    if (hard) await request.logout();
    dispatch({
      type: actionTypes.LOGOUT_SUCCESS,
    });
  };

export const current = () => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  const data = await request.current();
  if (data?.expired) {
    dispatch(logout(false));
    return;
  }
  if (data.success) {
    dispatch({
      type: actionTypes.CURRENT_SUCCESS,
      payload: data.result,
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};

export const getAdminPosition = () => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  const data = await request.getAdminPosition();
  if (data.success) {
    dispatch({
      type: actionTypes.GET_ADMIN_POSITION,
      payload: data.result.values,
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};

export const getCompanyIndustry = () => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  const data = await request.getCompanyIndustry();
  if (data.success) {
    dispatch({
      type: actionTypes.GET_COMPANY_INDUSTRY,
      payload: data.result.values,
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};

export const getStockType = () => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  const data = await request.getStockType();
  if (data.success) {
    dispatch({
      type: actionTypes.GET_STOCK_TYPE,
      payload: data.result.values,
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};

export const updateCompany = (name, fieldName, checked) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  const data = await request.check({
    entity: 'companySettings',
    checked,
    field: name,
  });
  if (data.success) {
    dispatch({
      type: actionTypes.UPDATE_COMPANY,
      payload: { key: fieldName, value: checked },
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};
