import useNotifyClient from '@/hooks/useNotifyClient';
import useTranslations from '@/hooks/useTranslation';
import { Form, Input, Modal, Row, Select } from 'antd';
import React, { useCallback, useState } from 'react';
import CustomCKEditor from '../CustomCKEditor/CustomCKEditor';
import { selectCompany } from '@/redux/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_REGEX } from '@/types/constants';
import useGetClients from '@/hooks/useGetClients';
import Loading from '../Loading';
import { fieldRequiredMessage, formatPrice } from '@/utils/helpers';
import { isEmpty } from 'lodash';
import { erp } from '@/redux/erp/actions';

type NotifyClientProps = {
  entity: { value: string; label: string };
  item: {
    _id: string;
    total: number;
    parent: { counter: number };
    bill: { id?: string };
  };
  setIsModalOpen: (state: boolean) => void;
};

const NotifyClient = ({
  setIsModalOpen,
  entity,
  item: {
    _id: itemId,
    total,
    parent: { counter },
    bill: { id: clientId } = { id: undefined },
  },
}: NotifyClientProps) => {
  const { currency } = useSelector(selectCompany);
  const { t } = useTranslations('salesTracking.notify_client');
  const dispatch = useDispatch();
  const { name = '' } = useSelector(selectCompany);
  const { data: client, isLoading: clientLoading } = useGetClients(clientId, true);
  const { isLoading, mutate, isSuccess } = useNotifyClient();
  const [form] = Form.useForm();
  const [html, setHtml] = useState<string>();

  const handleHtmlChange = useCallback((content: string) => {
    setHtml(content);
  }, []);

  const onFinish = async () => {
    const areFieldsValid = await form.validateFields();
    if (!areFieldsValid) return;
    mutate({
      id: itemId,
      entity: entity?.value,
      ...form.getFieldsValue(),
      html: !isEmpty(html)
        ? html
        : t('email_content', false, {
            name: client?.name || '',
            entity: entity?.label,
            counter,
            total: `${formatPrice(total, currency)?.[0]}`,
          }),
    });
  };

  if (!isLoading && isSuccess) {
    dispatch(erp.list({ entity: entity?.value }));
  }

  return (
    <Modal
      destroyOnClose={true}
      width={750}
      zIndex={100000}
      visible={true}
      title={t('title')}
      confirmLoading={isLoading}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      onOk={onFinish}
      okText={t('common.confirm', true)}
      cancelText={t('common.cancel', true)}
    >
      <Form name="email_form" form={form} onFinish={onFinish}>
        <Row className="notify-client-row">
          <Form.Item
            label={t('from')}
            name="from"
            initialValue={name}
            rules={[{ required: true, message: `${t('from_invalid')}` }]}
          >
            <Input />
          </Form.Item>
          <Loading isLoading={clientLoading}>
            {!clientLoading ? (
              <Form.Item
                label={t('to')}
                name="to"
                initialValue={client?.email || client?.firm_email || ''}
                rules={[
                  {
                    required: true,
                    message: `${t('common.email_invalid', true, { field: t('email')?.toString()?.toLowerCase() })}`,
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : (
              <Form.Item label={t('to')} />
            )}
          </Loading>
        </Row>
        <Form.Item
          label="Cc"
          name="cc"
          rules={[
            {
              required: false,
              validator: (_, value: string[]) => {
                if ((value || []).some((email: string) => !EMAIL_REGEX.test(email))) {
                  return Promise.reject(new Error(t('cc_invalid')));
                }
                return Promise.resolve();
              },
            },
          ]}
          validateTrigger="onChange"
        >
          <Select mode="tags" />
        </Form.Item>
        <Form.Item
          label={t('subject')}
          name="subject"
          initialValue={`${entity?.label}-${counter}`}
          rules={[{ required: true, message: `${fieldRequiredMessage(t, 'subject')}` }]}
        >
          <Input />
        </Form.Item>
      </Form>
      <Form.Item label={t('content')} />
      <CustomCKEditor
        onContentChange={handleHtmlChange}
        initialData={t('email_content', false, {
          name: client?.name || '',
          entity: entity?.label,
          counter,
          total: `${formatPrice(total, currency)?.[0]}`,
        })}
      />
    </Modal>
  );
};

export default NotifyClient;
