import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { current } from '@/redux/auth/action/auth';
import PageLoader from '@/components/PageLoader';

function HandleGoogleAuth() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(current());
  }, [dispatch, history]);
  return <PageLoader />;
}

export default HandleGoogleAuth;
