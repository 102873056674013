import useTranslations from '@/hooks/useTranslation';

const ContactList = () => {
  const { t } = useTranslations();
  return (
    <ul className="contact-list">
      <li>
        <a href="mailto:contact@supplyzpro.com">contact@supplyzpro.com</a>
      </li>
      <li>
        <a href="tel:+21658299567">{t('common.phoneNumber', true)}: +216 58 299 567</a>
      </li>
      <li>
        <a rel="noreferrer" href="https://wa.me/21658299567" target="_blank">
          WhatsApp: +216 58 299 567
        </a>
      </li>
    </ul>
  );
};

export default ContactList;
