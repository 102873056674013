import { useEffect, useState } from 'react';
import { Modal, Switch, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { updateCompany } from '@/redux/auth/action/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompany } from '@/redux/auth/selectors';
import useTranslations from '@/hooks/useTranslation';

export default function NotificationOption({ fieldName }) {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [isChecked, setIsChecked] = useState(company[fieldName]);
  const [open, setOpen] = useState(false);
  const description = { ...t('settingsPage.global_settings.settingsInfo.' + fieldName) };
  const modalTitle = company[fieldName] ? description.titleOff : description.titleOn;
  const modalContent = company[fieldName] ? description.bodyOff : description.bodyOn;

  const onSubmit = () => {
    setIsChecked(!isChecked);
    dispatch(updateCompany(description.name, fieldName, !company[fieldName]));
    setOpen(false);
  };

  useEffect(() => {
    setIsChecked(company[fieldName]);
  }, [company]);

  return (
    <div className="notification-option">
      <div className="notification-option-checkbox">
        <Switch checked={isChecked} onChange={() => setOpen(true)} />
      </div>
      <div className="notification-option-text">
        <span className="notification-option-title">{description.title}</span>
        <Tooltip title={description.body}>
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
      <Modal title={modalTitle} visible={open} onOk={onSubmit} onCancel={() => setOpen(false)}>
        <p style={{ whiteSpace: 'pre-line' }}>{modalContent}</p>
      </Modal>
    </div>
  );
}
