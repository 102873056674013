import React, { useEffect, useState } from 'react';
import photo from '@/style/images/Employee/avatar.png';
import { Avatar, Modal } from 'antd';
import { Status, transformRoles } from './Helper';
import { Select } from 'antd';
import bin from '@/style/images/bin.svg';
import { erp } from '@/redux/erp/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectUpdatedUser, selectDeletedUser } from '@/redux/erp/selectors';
import useTranslations, { LanguageEnum } from '@/hooks/useTranslation';

export default function UserCard({ email, name, status, role, id, roles, image, invitation }) {
  const [deleteModal, setDeleteModal] = useState(false);
  const { isSuccess } = useSelector(selectUpdatedUser);
  const { isSuccess: deleteUserSuccess } = useSelector(selectDeletedUser);
  const dispatch = useDispatch();
  const { t, currentLanguage } = useTranslations();
  const path = 'settingsPage.access_control.edit.';
  const ownerRole = currentLanguage === LanguageEnum.FR ? 'Administrateur' : 'Owner';
  const deleteMessage = t(path + 'deleteMessage');
  const modalTitle = t(path + 'modalTitle');
  useEffect(() => {
    if (isSuccess) {
      dispatch(erp.resetAction({ actionType: 'updateUser' }));
      dispatch(erp.listUsers({ entity: 'employee' }));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (deleteUserSuccess) {
      dispatch(erp.resetAction({ actionType: 'deleteUser' }));
      dispatch(erp.listUsers({ entity: 'employee' }));
    }
  }, [deleteUserSuccess]);
  const entity = 'employee';
  const onRoleChange = (value) => {
    const jsonData = {
      employee: id,
      newRole: value,
    };
    dispatch(erp.reassignRole({ entity, jsonData }));
  };
  const handleDelete = () => {
    setDeleteModal(true);
  };
  const handleCancel = () => {
    setDeleteModal(false);
  };
  const handleOk = () => {
    dispatch(erp.deleteUser({ entity, id }));
    setDeleteModal(false);
  };
  return (
    <>
      <Modal title={modalTitle} visible={deleteModal} onOk={handleOk} onCancel={handleCancel}>
        <p style={{ whiteSpace: 'pre-line' }}>{deleteMessage}</p>
      </Modal>
      <div className="user-card">
        <div className="user-info">
          <Avatar className="avatar-border" src={image ? image : photo} size={36} />
          <div className="user-name-email">
            <span className="user-name">{name}</span>
            <span className="user-email">{email}</span>
          </div>
        </div>
        <Status status={status} />
        <div className="user-role">
          <Select
            className="user-role-select"
            defaultValue={!invitation.isInvited ? ownerRole : role}
            style={{
              width: 120,
            }}
            bordered={false}
            options={transformRoles(roles)}
            disabled={!invitation.isInvited}
            onChange={(value) => onRoleChange(value)}
          />
        </div>
        <div className="user-delete">
          {invitation.isInvited ? <img src={bin} alt="bin" onClick={handleDelete} /> : null}
        </div>
      </div>
    </>
  );
}
